import { DatePipe } from '@angular/common';
import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { CursosService } from '@app/shared/services/cursos.service';
import Floor from '@app/shared/types/floor.interface';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-agregar-cursos',
  templateUrl: './agregar-cursos.component.html',
  styleUrls: ['./agregar-cursos.component.css']
})
export class AgregarCursosComponent implements OnInit {
  formGroup: FormGroup;
  current_sigla = "";
  isLoading = false;
  datosEditar:any="";
  IdFrom = "";
  typeForm = "Agregar";
  constructor(private formBuilder: FormBuilder,private router:Router,private cursosService: CursosService,private cdr: ChangeDetectorRef,
    private route: ActivatedRoute) { }

  ngOnInit(): void {
    this.datosEditar = this.cursosService.datosAEditar;
    this.route.params.subscribe(params => {
      this.IdFrom = params['id'];
    });
    this.formGroup = this.formBuilder.group({
      name:['',[Validators.required,Validators.maxLength(100)]],
      acronym:['',[Validators.required,Validators.maxLength(50)]],
      type:['1'],
      floor:['1'],
      monday:[false],
      tuesday:[false],
      wednesday:[false],
      thursday:[false],
      friday:[false],
      saturday:[false],
      sunday:[false],
      starttime:['',[Validators.required]],
      endtime:['',[Validators.required]]
    });
    if(this.IdFrom == "editar"){
      this.typeForm = "Editar";
      if(this.datosEditar != undefined){
        const datePipe = new DatePipe('en-US');
        const fechaFormateada = datePipe.transform(this.datosEditar.interviewdate, 'yyyy-MM-dd');
       this.formGroup.patchValue({
        'name':this.datosEditar.name,
        'acronym':this.datosEditar.acronym,
        'type':this.datosEditar.type,
        'floor':this.datosEditar.floor,
        'monday':this.datosEditar.monday,
        'tuesday':this.datosEditar.tuesday,
        'wednesday':this.datosEditar.wednesday,
        'thursday':this.datosEditar.thursday,
        'friday':this.datosEditar.friday,
        'saturday':this.datosEditar.saturday,
        'sunday':this.datosEditar.sunday,
        'starttime':this.datosEditar.starttime,
        'endtime':this.datosEditar.endtime
       });
      }
    }else{
      this.typeForm = "Agregar";
    }
  }

  get form() {
    return this.formGroup.controls;
  }

  generateAcronym(){
    
    // if(this.form.monday.value){
    //   this.ingresaguion("Lun");
    // }
    // if(this.form.tuesday.value){
    //   this.ingresaguion("Mar");
    // }
    // if(this.form.wednesday.value){
    //   this.ingresaguion("Mié");
    // }
    // if(this.form.thursday.value){
    //   this.ingresaguion("Jue");
    // }
    // if(this.form.friday.value){
    //   this.ingresaguion("Vie");
    // }
    // if(this.form.saturday.value){
    //   this.ingresaguion("Sáb");
    // }
    // if(this.form.sunday.value){
    //   this.ingresaguion("Dom");
    // }
    // this.ingresaguion(this.getPiso(this.form.floor.value).name);
    // if(this.form.starttime.value != '' && this.form.endtime.value != ''){
    //   this.ingresaguion(`${this.form.starttime.value} - ${this.form.endtime.value}`);
    // }
    // this.cdr.detectChanges();

  }
  
  getTypeDescription(type: number): string {
    return this.cursosService.getTypeDescription(type).name;
  }

  getPiso(type: number): Floor {
    return this.cursosService.getPiso(type);
  }

  ingresaguion(str){
    if(this.current_sigla.length > 0){
      this.current_sigla += " / ";
    }
    this.current_sigla += str;
  }

  save(){
    this.isLoading = true;
    this.cdr.detectChanges();
    if(this.IdFrom == "agregar"){
      this.agregar();
    }else{
      this.editar();
    }
  }

  agregar(){
    if(this.formGroup.valid){
      this.cursosService.create(this.formGroup.getRawValue()).subscribe((resp: any) => {
        if(resp.code == 200){
          Swal.fire({
            title: '',
            text: 'Datos insertados correctamente.',
            icon: 'success',
            confirmButtonText: 'OK'
          }).then((result) => {
            if (result.isConfirmed) {
              // Redirigir a la página deseada usando el Router de Angular
              this.router.navigate(['/cursos']);
            }
          });
        }else{
          Swal.fire(
            '',
            'No se pudo insertar los datos, favor comuníquese con el administrador.',
            'error'
            );
        }
        this.isLoading = false;
        this.cdr.detectChanges();

      });
    }else{
      Swal.fire(
        '',
        'Debe completar los campos correctamente.',
        'error'
        );
        this.isLoading = false;
        this.cdr.detectChanges();
    }
  }

  editar(){
    if(this.formGroup.valid){
      this.cursosService.edit(this.formGroup.getRawValue(),this.datosEditar.identity).subscribe((resp: any) => {
        if(resp.code == 200){
          Swal.fire({
            title: '',
            text: 'Datos modificados correctamente.',
            icon: 'success',
            confirmButtonText: 'OK'
          }).then((result) => {
            if (result.isConfirmed) {
              this.router.navigate(['/cursos']);
            }
          });
        }else{
          Swal.fire(
            '',
            'No se pudo insertar los datos, favor comuníquese con el administrador.',
            'error'
            );
        }
        this.isLoading = false;
        this.cdr.detectChanges();

      });
    }else{
      Swal.fire(
        '',
        'Debe completar los campos correctamente.',
        'error'
        );
        this.isLoading = false;
        this.cdr.detectChanges();
    }
  }
}
