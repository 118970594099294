<nav class="navbar navbar-light bg-light mb-3">
    <div class="container-fluid">
        <form class="d-flex w-100">
        <input class="form-control me-2" type="search" placeholder="Buscar alumnos" [(ngModel)]="searchText" name="filtroNombre" aria-label="Search">
       
        </form>
    </div>
</nav>

<section class="demo-section row">
    
    <div class="content-box table-responsive" *ngIf="!displayPagos">
        <table class="table">
            <thead>
                <tr>
                    <th scope="col">Acciones</th>
                    <th scope="col">Rut o Pasaporte</th>
                    <th scope="col">Nombre</th>
                    <th scope="col">Email</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngIf="isLoading">
                    <td colspan="4">
                        <div class="spinner-border spinner-border-sm" role="status">
                            <span class="sr-only">Loading...</span>
                        </div>
                    </td>
                   
                </tr>
                <tr *ngFor="let item of alumnos | searchFilter:searchText | paginate: { itemsPerPage: 10, currentPage: p } ">
                    <td>
                        <a class="btn m-1 btn-sm btn-success" [disabled]="isLoadingCursos" (click)="verpagos(item)">Ver Pagos</a>
                    </td>
                    <td>{{ item.idcard }}</td>
                    <td>{{ item.name }} {{ item.lastname }}</td>
                    <td>{{ item.email }}</td>
                </tr>
            </tbody>
        </table>
        <div class="d-flex justify-content-end">
            <pagination-controls (pageChange)="p = $event" previousLabel="Anterior" maxSize="10" nextLabel="Siguiente"></pagination-controls>
        </div>
    </div>

    <div class="content-box" *ngIf="displayPagos">
        <!-- inicio curso -->
        <div class="row justify-content-end">
            <div class="col-9">
                <label class="form-label col-12"><h4>Datos del alumno/a</h4></label>
                <div class="row">
                    <div class="col-md-6">
                        <label for="name" class="form-label">Rut</label>
                        <input type="text" class="form-control" disabled value="{{ alumnoSelected.idcard }}">
    
                        <label for="name" class="form-label">Nombre Apellido</label>
                        <input type="text" class="form-control" disabled value="{{ alumnoSelected.name }} {{ alumnoSelected.lastname }}">
                        </div>
                        <div class="col-md-6">
                            <label for="name" class="form-label">Teléfono</label>
                            <input type="text" class="form-control" disabled value="{{ alumnoSelected.phone1 }}">
                            <label for="name" class="form-label">Email</label>
                            <input type="text" class="form-control" disabled value="{{ alumnoSelected.email }}">
                        </div>
                </div>
            </div>
            <div class="col-3">
                <button class="btn btn-outline-warning w-100" type="button" (click)="cerrarLista()">Cerrar Lista <i class="feather icon-x-circle"></i></button>
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <h4 class="mt-5 mb-3">Pagos</h4>
                <div class="col-10 p-3"*ngIf="isLoadingPagos">
                    Cargando Pagos
                    <div class="spinner-border spinner-border-sm" role="status">
                        <span class="sr-only">Loading...</span>
                    </div>
                </div>
                <app-formulario-pagos [formGroup]="formGroup" [RutStudent]="RutStudent" [inscripcionList]="inscripcionList" [IdStudent]="IdStudent"></app-formulario-pagos>
            </div>
        </div>
    </div>

</section>