import { Routes } from '@angular/router';

import { AlumnosComponent } from '../views/alumnos/alumnos.component';
import { ListaAlumnosComponent } from '../views/alumnos/lista-alumnos/lista-alumnos.component';
import { AgregarAlumnoComponent } from '../views/alumnos/agregar-alumno/agregar-alumno.component';
import { PagosComponent } from '../views/pagos/pagos.component';
import { AsistenciaComponent } from '../views/asistencia/asistencia.component';
import { GraduacionesComponent } from '../views/graduaciones/graduaciones.component';
import { ListaGraduacionesComponent } from '../views/graduaciones/lista-graduaciones/lista-graduaciones.component';
import { AgregarGraduacionComponent } from '../views/graduaciones/agregar-graduacion/agregar-graduacion.component';
import { MisGraduacionesComponent } from '../views/graduaciones/mis-graduaciones/mis-graduaciones.component';
import { CursosComponent } from '../views/cursos/cursos.component';
import { ListaCursosComponent } from '../views/cursos/lista-cursos/lista-cursos.component';
import { AgregarCursosComponent } from '../views/cursos/agregar-cursos/agregar-cursos.component';
import { MisCursosComponent } from '../views/cursos/mis-cursos/mis-cursos.component';
import { MaterialCursoComponent } from '../views/cursos/material-curso/material-curso.component';
import { EjecutivosComponent } from '../views/ejecutivos/ejecutivos.component';
import { ListaEjecutivosComponent } from '../views/ejecutivos/lista-ejecutivos/lista-ejecutivos.component';
import { AgregarEjecutivosComponent } from '../views/ejecutivos/agregar-ejecutivos/agregar-ejecutivos.component';
import { ProfesoresComponent } from '../views/profesores/profesores.component';
import { ListaProfesoresComponent } from '../views/profesores/lista-profesores/lista-profesores.component';
import { AgregarProfesoresComponent } from '../views/profesores/agregar-profesores/agregar-profesores.component';
import { GastosComponent } from '@app/views/gastos/gastos.component';
import { ListaGastosComponent } from '@app/views/gastos/lista-gastos/lista-gastos.component';
import { AgregarGastosComponent } from '@app/views/gastos/agregar-gastos/agregar-gastos.component';
import { AsignarGraduacionesComponent } from '@app/views/graduaciones/asignar-graduaciones/asignar-graduaciones.component';
import { ListaInvitadosComponent } from '@app/views/graduaciones/lista-invitados/lista-invitados.component';
import { AgregarInvitadoComponent } from '@app/views/graduaciones/invitados/agregar-invitado/agregar-invitado.component';
import { AuthGuard } from '@app/auth.guard';
import { UsuariosComponent } from '@app/views/usuarios/usuarios.component';
import { ListUsuariosComponent } from '@app/views/usuarios/lista-usuarios/list-usuarios.component';
import { FormularioUsuariosComponent } from '@app/views/usuarios/formulario/formulario-usuarios.component';
import { DashboardComponent } from '@app/views/dashboard/dashboard.component';
import { DashboardPageComponent } from '@app/views/dashboard/page/dashboardpage.component';

export const APP_LAYOUT_ROUTES: Routes = [
    //Dashboard
    {
        path: 'dashboard',
        component: DashboardComponent,
        children:[
          {
            path: '', // ruta vacía para mostrar la tabla en el componente padre
            component: DashboardPageComponent,
            canActivate: [AuthGuard], data: { role: 'admin|executive' }
          }
        ]
        //loadChildren: () => import('../views/dashboard/dashboard.module').then(m => m.DashboardModule),
    },
    {
        path: 'alumnos',
        component: AlumnosComponent,
        children: [
            {
              path: '', // ruta vacía para mostrar la tabla en el componente padre
              component: ListaAlumnosComponent,
              canActivate: [AuthGuard], data: { role: 'admin|executive' }
            },
            {
              path: ':filter',
              component: ListaAlumnosComponent,
              canActivate: [AuthGuard], data: { role: 'admin|executive' }

            },
            {
              path: 'admin/:id',
              component: AgregarAlumnoComponent,
              canActivate: [AuthGuard], data: { role: 'admin|executive' }

            }
        ]
    },
    {
        path: 'pagos',
        component: PagosComponent,
        canActivate: [AuthGuard], data: { role: 'admin|executive' }

    },
    {
        path: 'gastos',
        component: GastosComponent,
        children: [
            {
              path: '',
              component: ListaGastosComponent,
              canActivate: [AuthGuard], data: { role: 'admin|executive' }
            },
            {
              path: 'admin/:id',
              component: AgregarGastosComponent,
              canActivate: [AuthGuard], data: { role: 'admin|executive' }

            }
        ]
    },
    {
        path: 'asistencia',
        component: AsistenciaComponent,
        canActivate: [AuthGuard], data: { role: 'admin|teacher|executive' }

    },
    {
        path: 'graduaciones',
        component: GraduacionesComponent,
        children: [
            {
              path: '',
              component: ListaGraduacionesComponent,
              canActivate: [AuthGuard], data: { role: 'admin|executive' }

            },
            {
              path: 'admin/:id',
              component: AgregarGraduacionComponent,
              canActivate: [AuthGuard], data: { role: 'admin|executive' }

            },
            {
              path: 'invitados/:course/:id',
              component: ListaInvitadosComponent,
              canActivate: [AuthGuard], data: { role: 'admin|executive' }

            },
            {
              path: 'invitados/admin/:course/:id/:type',
              component: AgregarInvitadoComponent,
              canActivate: [AuthGuard], data: { role: 'admin|executive' }

            },
            {
              path: 'asignar-graduacion',
              component: AsignarGraduacionesComponent,
              canActivate: [AuthGuard], data: { role: 'admin|executive' }

            },
            {
              path: 'mis-graduaciones',
              component: MisGraduacionesComponent,
              canActivate: [AuthGuard], data: { role: 'admin' }

            }
        ]
    },
    {
        path: 'cursos',
        component: CursosComponent,
        children: [
            {
              path: '',
              component: ListaCursosComponent,
              canActivate: [AuthGuard], data: { role: 'admin|executive' }

            },
            {
              path: 'admin/:id',
              component: AgregarCursosComponent,
              canActivate: [AuthGuard], data: { role: 'admin|executive' }

            },
            {
              path: 'mis-cursos',
              component: MisCursosComponent,
              canActivate: [AuthGuard], data: { role: 'student' }

            },
            {
              path: 'material-cursos',
              component: MaterialCursoComponent,
              canActivate: [AuthGuard], data: { role: 'admin|teacher|executive' }

            }
        ]
    },
    {
        path: 'ejecutivos',
        component: EjecutivosComponent,
        children: [
          {
            path: '',
            component: ListaEjecutivosComponent,
            canActivate: [AuthGuard], data: { role: 'admin|executive' }

          },
          {
            path: 'admin/:id',
            component: AgregarEjecutivosComponent,
            canActivate: [AuthGuard], data: { role: 'admin|executive' }

          },
        ]
    },
    {
        path: 'profesores',
        component: ProfesoresComponent,
        children: [
            {
              path: '',
              component: ListaProfesoresComponent,
              canActivate: [AuthGuard], data: { role: 'admin|executive' }

            },
            {
              path: 'admin/:id',
              component: AgregarProfesoresComponent,
              canActivate: [AuthGuard], data: { role: 'admin|executive' }

            }
        ]
    },
    {
      path: 'usuarios',
      component: UsuariosComponent,
      children: [
          {
            path: '',
            component: ListUsuariosComponent,
            canActivate: [AuthGuard], data: { role: 'admin' }

          },
          {
            path: 'admin/:id',
            component: FormularioUsuariosComponent,
            canActivate: [AuthGuard], data: { role: 'admin' }

          }
      ]
  },
];