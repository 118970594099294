<nav class="navbar navbar-light bg-light mb-3">
    <div class="container-fluid">
        <a class="btn btn-warning">
            <i class="feather icon-arrow-left"></i> Volver a la lista de Graduaciones
        </a>
    </div>
</nav>

<section class="demo-section row">
    <div class="content-box col-12">
        <form class="row g-3">
            <label class="form-label col-12">Asignar Alumnos a Ceremonia de Graduación</label>
        
            <div class="col-md-6">
                <label class="form-label">Seleccionar Graduación</label>
                <select class="form-select">
                    <option>Graduacion Julio 2023</option>
                </select>
            </div>
            <div class="col-md-6">
                <label class="form-label">Seleccionar Alumno</label>
                <select class="form-select">
                    <option>Alumno 01</option>
                </select>
            </div>
            

            <div class="col-md-12">
                <br>
                <label class="form-label col-12">Asignar Invitados a Alumnos</label>
            </div>
            


            <!-- inicio repetidor -->
            <div class="row">
                <div class="col-md-4">
                    <label class="form-label">Nombre Asistente</label>
                    <input type="text" class="form-control">
                </div>
                
                <div class="col-md-2">
                    <label class="form-label">Asientos Disponibles</label>
                    <select class="form-select">
                        <option>01</option>
                        <option>02</option>
                        <option>03</option>
                    </select>
                </div>
                
                <div class="col-md-2">
                    <label  class="form-label">Entrada</label>
                    <select class="form-select">
                        <option>Pagada</option>
                        <option>Pendiente</option>
                    </select>
                </div>
                <div class="col-md-4">
                    
                    <div class="row">
                        <div class="col-12">
                            <label  class="form-label">Acciones</label>
                        </div>
                        <div class="col-6">
                            <a class="btn w-100 m-1 btn-sm btn-danger">Quitar Invitado</a>
                        </div>
                        <div class="col-6">
                            <a class="btn w-100 m-1 btn-sm btn-info">Nuevo Invitado</a>
                        </div>
                    </div>
                </div>
            </div>
            <!-- fin repetidor -->



            <div class="col-12 mt-3">
                <button type="submit" class="btn btn-primary"><i class="feather icon-check-circle"></i> 
                    Asignar Graduación
                </button>
            </div>

        </form>
    </div>
</section>