import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { GastosService } from '@app/shared/services/gastos.service';
import Swal from 'sweetalert2';
import * as XLSX from 'xlsx';

@Component({
  selector: 'app-lista-gastos',
  templateUrl: './lista-gastos.component.html',
  styleUrls: ['./lista-gastos.component.css']
})
export class ListaGastosComponent implements OnInit {
  isLoading = false;
  public listagastos : any[] =[];
  searchText:string;
  p:number=1;

  constructor(private gastosService: GastosService,private cdr: ChangeDetectorRef,
    private router: Router) { }

  ngOnInit(): void {
    this.getList();
  }
    
  exportToExcel() {
    let lstexcel=[];
    this.listagastos.forEach(x => {
      lstexcel.push({
        NombreGasto:x.name,
        Monto:x.amount,
        Fecha:x.paymentday,
        Comentarios:x.comments,
        UsuarioIngresa:x.usermodify,
      });
    });
    const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(lstexcel);
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Gastos');
    XLSX.writeFile(wb, 'gastos.xlsx');
  }

  onSearchTextChanged() {
    this.p = 1;
  }
  
  getList(){
    this.listagastos =[];
    this.isLoading = true;
    this.cdr.detectChanges();

    this.gastosService.getgastos().subscribe((resp: any) => {
      this.isLoading = false;
      this.listagastos = resp.data;
      this.cdr.detectChanges();
    });
  }

  eliminar(item:any){
    Swal.fire({
      title: 'Eliminar Gasto',
      text: '¿Realmente desea eliminar este gasto?.',
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: 'Sí',
      denyButtonText: 'No',
    }).then((result) => {
      if (result.isConfirmed) {
        this.isLoading = true;
        console.log("eliminando item",item.identity);
        this.gastosService.delete(item.identity).subscribe((resp: any) => {
          this.isLoading = false;
          if(resp.code == 200){
            this.getList();

            Swal.fire({
              title: '',
              text: 'Gasto eliminado correctamente.',
              icon: 'success',
              confirmButtonText: 'OK'
            }).then((result2) => {
              //this.router.navigate(['/gastos']);
            });

          }else{
            Swal.fire(
              '',
              'No se pudo eliminar gasto, favor comuníquese con el administrador.',
              'error'
              );
          }
          this.cdr.detectChanges();
  
        });
        this.cdr.detectChanges();
      }
    });
  }
}
