import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { EjecutivosService } from '@app/shared/services/ejecutivos.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-lista-ejecutivos',
  templateUrl: './lista-ejecutivos.component.html',
  styleUrls: ['./lista-ejecutivos.component.css']
})
export class ListaEjecutivosComponent implements OnInit {
  p:number=1;
  searchText:string;
  constructor(private ejecutivosService: EjecutivosService,private cdr: ChangeDetectorRef,
    private router: Router) { }

    public isLoading = true;
    public ejecutivos : any[] =[];
  ngOnInit(): void {
    this.getListEjecutivos();
  }

  onSearchTextChanged() {
    this.p = 1;
  }

  getListEjecutivos(){
    this.ejecutivosService.getexecutives().subscribe((resp: any) => {
      this.isLoading = false;
      this.ejecutivos = resp.data;
      this.cdr.detectChanges();
    });
  }

  editar(item:any){
    this.ejecutivosService.datosAEditar = item;
    this.router.navigate(['/ejecutivos/admin','editar']);
   }

   getTypeDescription(type: number): string {
    return this.ejecutivosService.getTypeDescription(type).name;
  }

   eliminar(item:any){
     Swal.fire({
       title: 'Eliminar ejecutivo',
       text: '¿Realmente desea eliminar este ejecutivo?.',
       icon: 'question',
       showCancelButton: true,
       confirmButtonText: 'Sí',
       denyButtonText: 'No',
     }).then((result) => {
       if (result.isConfirmed) {
         console.log("eliminando item",item.identity);
         this.ejecutivosService.delete(item.identity).subscribe((resp: any) => {
           if(resp.code == 200){
             Swal.fire({
               title: '',
               text: 'Ejecutivo eliminado correctamente.',
               icon: 'success',
               confirmButtonText: 'OK'
             }).then((result2) => {
               this.getListEjecutivos();
             });
           }else{
             Swal.fire(
               '',
               'No se pudo eliminar ejecutivo, favor comuníquese con el administrador.',
               'error'
               );
           }
           this.cdr.detectChanges();
   
         });
         this.cdr.detectChanges();
       }
     });
   }
}
