<div class="header-nav-item-select" >
    
    <ul class="nav d-none d-md-flex">
        <li class="nav-item" *ngIf="dataUsuario?.role == 'student'">
            <a class="nav-link"  href="/cursos/mis-cursos">Mis Cursos</a>
        </li>
        <li class="nav-item">
            <a class="nav-link">{{dataUsuario?.datosusu?.nombre}} | {{dataUsuario?.role}}</a>
        </li>
        <li class="nav-item">
            <a class="nav-link" (click)="cerrarSesion()">Cerrar Sesión</a>
        </li>
    </ul>
   
    <!-- <div class="toggle-wrapper">
        <i class="nav-icon feather icon-settings" (click)="openQuickPanel(template)"></i>
    </div> -->

</div>

<ng-template #template>
    <div class="modal-header">
        <h4 class="modal-title pull-left">Accesos rapido</h4>
        <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div class="card-body mb-3 p-0">
            <h5>Alumnos Ausentes</h5>
            <div class="mt-3">
                <table class="table table-sm">
                    <tbody>
                        <tr>
                            <td>
                                <i class="feather icon-user"></i>
                                <span class="ms-1">Constanza Lohse</span>
                            </td>
                            <td>
                                <div class="d-flex align-items-center my-2">
                                    <div class="ms-3">
                                        <a class="btn btn-success btn-sm">WhatsApp <i class="feather icon-send"></i></a>
                                        <a class="btn btn-secondary btn-sm ms-3">Email <i class="feather icon-mail"></i></a>
                                    </div>
                                </div>
                            </td>
                        </tr>
                        
                        <tr>
                            <td>
                                <i class="feather icon-user"></i>
                                <span class="ms-1">Constanza Lohse</span>
                            </td>
                            
                            <td>
                                <div class="d-flex align-items-center my-2">
                                    <div class="ms-3">
                                        <a class="btn btn-success btn-sm">WhatsApp <i class="feather icon-send"></i></a>
                                        <a class="btn btn-secondary btn-sm ms-3">Email <i class="feather icon-mail"></i></a>
                                    </div>
                                </div>
                            </td>
                        </tr>

                        <tr>
                            <td>
                                <i class="feather icon-user"></i>
                                <span class="ms-1">Constanza Lohse</span>
                            </td>
                            
                            <td>
                                <div class="d-flex align-items-center my-2">
                                    <div class="ms-3">
                                        <a class="btn btn-success btn-sm">WhatsApp <i class="feather icon-send"></i></a>
                                        <a class="btn btn-secondary btn-sm ms-3">Email <i class="feather icon-mail"></i></a>
                                    </div>
                                </div>
                            </td>
                        </tr>

                        <tr>
                            <td>
                                <i class="feather icon-user"></i>
                                <span class="ms-1">Constanza Lohse</span>
                            </td>
                            
                            <td>
                                <div class="d-flex align-items-center my-2">
                                    <div class="ms-3">
                                        <a class="btn btn-success btn-sm">WhatsApp <i class="feather icon-send"></i></a>
                                        <a class="btn btn-secondary btn-sm ms-3">Email <i class="feather icon-mail"></i></a>
                                    </div>
                                </div>
                            </td>
                        </tr>

                    </tbody>
                </table>
            </div>
        </div>



        <div class="card-body mt-4 mb-3 p-0">
            <h5>Alumnos Morosos</h5>
            <div class="mt-3">
                <table class="table table-sm">
                    <tbody>
                        <tr>
                            <td>
                                <i class="feather icon-user"></i>
                                <span class="ms-1">Constanza Lohse</span>
                            </td>
                            <td>
                                <div class="d-flex align-items-center my-2">
                                    <div class="ms-3">
                                        <a class="btn btn-success btn-sm">WhatsApp <i class="feather icon-send"></i></a>
                                        <a class="btn btn-secondary btn-sm ms-3">Email <i class="feather icon-mail"></i></a>
                                    </div>
                                </div>
                            </td>
                        </tr>
                        
                        <tr>
                            <td>
                                <i class="feather icon-user"></i>
                                <span class="ms-1">Constanza Lohse</span>
                            </td>
                            
                            <td>
                                <div class="d-flex align-items-center my-2">
                                    <div class="ms-3">
                                        <a class="btn btn-success btn-sm">WhatsApp <i class="feather icon-send"></i></a>
                                        <a class="btn btn-secondary btn-sm ms-3">Email <i class="feather icon-mail"></i></a>
                                    </div>
                                </div>
                            </td>
                        </tr>

                        <tr>
                            <td>
                                <i class="feather icon-user"></i>
                                <span class="ms-1">Constanza Lohse</span>
                            </td>
                            
                            <td>
                                <div class="d-flex align-items-center my-2">
                                    <div class="ms-3">
                                        <a class="btn btn-success btn-sm">WhatsApp <i class="feather icon-send"></i></a>
                                        <a class="btn btn-secondary btn-sm ms-3">Email <i class="feather icon-mail"></i></a>
                                    </div>
                                </div>
                            </td>
                        </tr>

                        <tr>
                            <td>
                                <i class="feather icon-user"></i>
                                <span class="ms-1">Constanza Lohse</span>
                            </td>
                            
                            <td>
                                <div class="d-flex align-items-center my-2">
                                    <div class="ms-3">
                                        <a class="btn btn-success btn-sm">WhatsApp <i class="feather icon-send"></i></a>
                                        <a class="btn btn-secondary btn-sm ms-3">Email <i class="feather icon-mail"></i></a>
                                    </div>
                                </div>
                            </td>
                        </tr>

                    </tbody>
                </table>
            </div>
        </div>
    </div>
</ng-template>