<nav class="navbar navbar-light bg-light mb-3">
    <div class="container-fluid">
        <a class="btn btn-warning" [routerLink]="['/gastos']">
            <i class="feather icon-arrow-left"></i> Volver a la lista de gastos
        </a>
    </div>
</nav>

<section class="demo-section row">
    <div class="content-box col-12">
        <form class="row g-3" [formGroup]="formGroup" (submit)="agregar()">
            <label class="form-label col-12">Ingresar datos del Gasto</label>
        
            <div class="col-md-4">
                <label class="form-label">Nombre</label>
                <input type="text" class="form-control" formControlName="name">
            </div>
            <div class="col-md-4">
                <label class="form-label">Fecha</label>
                <input type="date" class="form-control" formControlName="paymentday">
            </div>
            <div class="col-md-4">
                <label  class="form-label">Monto</label>
                <input type="number" class="form-control" placeholder="Ingresar Monto" formControlName="amount">
            </div>
            <div class="col-md-12">
                <label class="form-label">Observación</label>
                <textarea class="form-control" formControlName="comments"></textarea>
            </div>

            <div class="col-12 mt-3">
                <button type="submit" class="btn btn-primary"  [disabled]="isLoading"><i class="feather icon-check-circle"></i> 
                    Agregar Gasto
                    <div *ngIf="isLoading">
                        <div class="spinner-border spinner-border-sm" role="status">
                            <span class="sr-only">Loading...</span>
                        </div>
                    </div>
                </button>
            </div>
        </form>
    </div>
</section>