<main class="main">
    <img 
    class="img-fluid logoprint" 
    alt="logo"
    src="/assets/images/logo/logo.png" 
    style="height: 30px"
/>
    <page-header *ngIf="pageHeader"></page-header>
    <router-outlet></router-outlet>
</main>
<app-footer></app-footer>