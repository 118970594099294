import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
const base_url = environment.base_url;
@Injectable({
  providedIn: 'root'
})
export class EjecutivosService {
  datosAEditar:any;

  constructor(private http: HttpClient) {
  }

  public getexecutives(){
    return this.http.get(`${base_url}/api/v1/executives/getexecutives`);
  } 
    
  public getteachers(){
    return this.http.get(`${base_url}/api/v1/executives/getteachers`);
  }

  public create(data:any){
    return this.http.post(`${base_url}/api/v1/executives`,data);

  }
  public edit(data: any,identity:string){
    return this.http.put(`${base_url}/api/v1/executives/`+ identity,data);
  }

  public delete(identity:string){
    return this.http.delete(`${base_url}/api/v1/executives/`+ identity);
  }

  
  public getTypeDescription(type: number): any {
    switch (type) {
      case 1:
        return { id: 1, name: "Administrador"};
      case 2:
        return { id: 2, name: "Usuario"};
      default:
        return { id: 0, name: "------"};
    }
  }

}