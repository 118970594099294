
<nav class="navbar navbar-light bg-light mb-3">
    <div class="container-fluid">
        <a class="btn btn-success" [routerLink]="['/profesores/admin/agregar']">
            <i class="feather icon-user-plus"></i> Agregar Profesor</a>
        <form class="d-flex w-50">
        <input class="form-control me-2" type="search" placeholder="Buscar Profesor" [(ngModel)]="searchText" (input)="onSearchTextChanged()" name="filtroNombre" aria-label="Search">
     
        </form>
    </div>
</nav>
<section class="demo-section row">
    <div class="content-box table-responsive">
        <table class="table">
            <thead>
                <tr>
                    <th scope="col">Nombre Profesor</th>
                    <th scope="col">Teléfono</th>
                    <th scope="col">Email</th>
                    <!-- <th scope="col">Cursos Asignados</th> -->
                    <th scope="col">Fecha Ingreso</th>
                    <th scope="col">Acciones</th>
                </tr>
            </thead>
            <tbody> 
                <tr *ngIf="isLoading">
                    <td colspan="5">
                        <div class="spinner-border spinner-border-sm" role="status">
                            <span class="sr-only">Loading...</span>
                        </div>
                    </td>
                </tr>
                <tr *ngFor="let item of profesores | searchFilter:searchText | paginate: { itemsPerPage: 10, currentPage: p }">
                    
                    <td>{{ item.name }}</td>
                    <td>{{ item.phone }}</td>
                    <td>{{ item.email }}</td>
                    <!-- <td>{{ getTypeDescription(item.profile) }}</td> -->
                    <td>{{ item.created_at | date: 'yyyy-MM-dd'}}</td>
                    <td>
                        <a class="btn m-1 btn-sm btn-info"(click)="editar(item)" >Editar</a>
                        <a class="btn m-1 btn-sm btn-danger" (click)="eliminar(item)">Eliminar</a>
                    </td>
                    
                </tr>
            </tbody>
        </table>
        <div class="d-flex justify-content-end">
            <pagination-controls (pageChange)="p = $event" previousLabel="Anterior" maxSize="10" nextLabel="Siguiente"></pagination-controls>
        </div>
    </div>
</section>