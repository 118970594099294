<nav class="navbar navbar-light bg-light mb-3 no-print">
    <div class="container-fluid">
        <a class="btn btn-success" (click)="agregarinvitado(id)">
            <i class="feather icon-user-plus"></i> Agregar Invitados
        </a>
        <div class="d-flex">
            <button class="btn btn-danger me-2" (click)="imprimir()" [disabled]="isLoading" type="button">
                <i class="feather icon-printer"></i> Imprimir
            </button>
            <a class="btn btn-warning"  [routerLink]="['/graduaciones']">
                <i class="feather icon-arrow-left"></i> Volver
            </a>
        </div>
    </div>
</nav>
<section class="demo-section row">
    <div class="content-box table-responsive">
        <div *ngIf="false">
            <h3>{{ datosGraduacion?.name }} </h3>
            <h4>{{ datosGraduacion?.course_name }}</h4>
            <h5>Fecha {{ datosGraduacion?.date | date: 'yyyy-MM-dd' }}</h5>
            <h5>{{ datosGraduacion?.number_seats - datosGraduacion?.reserved_seats }} de {{ datosGraduacion?.number_seats  }} asientos disponibles</h5>
            <p>{{ datosGraduacion?.description }}</p>
        </div>
        <table class="table">
            <thead>
                <tr>
                    <th scope="col">Nombre Invitado</th>
                    <th scope="col">Asiento</th>
                    <th scope="col">Alumno</th>
                    <th scope="col">Pago</th>
                    <th scope="col" class="no-print">Acciones</th>
                </tr>
            </thead>
            <tbody >
                <tr *ngIf="isLoading">
                    <td colspan="5">
                      <div class="spinner-border spinner-border-sm" role="status">
                        <span class="sr-only">Loading...</span>
                      </div>
                    </td>
                  </tr>
                  <tr
                    *ngFor="
                      let item of lista
                        | paginate : { itemsPerPage: 10, currentPage: p }
                        | searchFilter : searchText
                    "
                  >
                    <td>{{ item.name_attendee }}</td>
                    <td>Asiento N° {{ item.number_seat }}</td>
                    <td>{{ item.student_name }}</td>
                    <td>{{ item.ticket_price  |currency:'CLP':'symbol-narrow':'1.0-0':'es-CL' }} </td>
                    <td class="no-print">
                        <a class="btn m-1 btn-sm btn-info" (click)="editar(item)">Editar</a>
                        <a class="btn m-1 btn-sm btn-danger" (click)="eliminar(item)">Eliminar</a>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</section>