import { DatePipe } from '@angular/common';
import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { CursosService } from '@app/shared/services/cursos.service';
import { GraduacionService } from '@app/shared/services/graduacion.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-agregar-graduacion',
  templateUrl: './agregar-graduacion.component.html',
  styleUrls: ['./agregar-graduacion.component.css']
})
export class AgregarGraduacionComponent implements OnInit {
  formGroup: FormGroup;
  isLoading = false;
  datosEditar:any="";
  IdFrom = "";
  typeForm = "Agregar";
  cursos$: any[];

  constructor(private graduacionService: GraduacionService,
    private cursosService: CursosService,
    private formBuilder: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private cdr: ChangeDetectorRef) { }

  ngOnInit(): void {
    Swal.showLoading();
    this.datosEditar = this.graduacionService.datosAEditar;
    this.route.params.subscribe(params => {
      this.IdFrom = params['id'];
    });
    this.formGroup = this.formBuilder.group({
      course:['',[Validators.required]],
      name:['',[Validators.required]],
      date:['',[Validators.required]],
      number_seats:['',[Validators.required,Validators.min(0),Validators.pattern(/^\d+$/)]],
      ticket_price:['',[Validators.required,Validators.min(0),Validators.pattern(/^\d+$/)]],
      description:['',[Validators.required]],
    });
    if(this.IdFrom == "editar"){
      this.typeForm = "Editar";
      if(this.datosEditar != undefined){
        const datePipe = new DatePipe('en-US');
        const fechaFormateada = datePipe.transform(this.datosEditar.date, 'yyyy-MM-dd');

       this.formGroup.patchValue({
        'course':this.datosEditar.course,
        'name':this.datosEditar.name,
        'date':fechaFormateada,
        'number_seats':this.datosEditar.number_seats,
        'ticket_price':this.datosEditar.ticket_price,
        'description':this.datosEditar.description,
       });
      }
    }else{
      this.typeForm = "Agregar";
    }
    this.getListCurso();
  }

  getListCurso(){
    this.isLoading = true;

    this.cursosService.getall().subscribe((resp: any) => {
      Swal.close();
      this.isLoading = false;
      this.cursos$ = resp.data;
      this.cdr.detectChanges();
    });

  }
  save(){
    this.isLoading = true;
    this.cdr.detectChanges();
    if(this.IdFrom == "agregar"){
      this.agregar();
    }else{
      this.editar();
    }
  }
  agregar(){
    if(this.formGroup.valid){
      Swal.showLoading();
      this.isLoading = false;
      this.graduacionService.create(this.formGroup.getRawValue()).subscribe((resp: any) => {
        Swal.close();
        if(resp.code == 200){
          Swal.fire({
            title: '',
            text: 'Datos insertados correctamente.',
            icon: 'success',
            confirmButtonText: 'OK'
          }).then((result) => {
            if (result.isConfirmed) {
              // Redirigir a la página deseada usando el Router de Angular
              this.router.navigate(['/graduaciones']);
            }
          });
        }else{
          Swal.fire(
            '',
            'No se pudo insertar los datos, favor comuníquese con el administrador.',
            'error'
            );
        }
        this.isLoading = false;
        this.cdr.detectChanges();

      });
    }else{
      Swal.fire(
        '',
        'Debe completar los campos correctamente.',
        'error'
        );
        this.isLoading = false;
        this.cdr.detectChanges();
    }
  }


  editar(){
    if(this.formGroup.valid){
      this.graduacionService.edit(this.formGroup.getRawValue(),this.datosEditar.identity).subscribe((resp: any) => {
        if(resp.code == 200){
          Swal.fire({
            title: '',
            text: 'Datos modificados correctamente.',
            icon: 'success',
            confirmButtonText: 'OK'
          }).then((result) => {
            if (result.isConfirmed) {
              this.router.navigate(['/graduaciones']);
            }
          });
        }else{
          Swal.fire(
            '',
            'No se pudo insertar los datos, favor comuníquese con el administrador.',
            'error'
            );
        }
        this.isLoading = false;
        this.cdr.detectChanges();

      });
    }else{
      Swal.fire(
        '',
        'Debe completar los campos correctamente.',
        'error'
        );
        this.isLoading = false;
        this.cdr.detectChanges();
    }
  }
}
