import { DatePipe } from '@angular/common';
import { ChangeDetectorRef, Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AlumnosService } from '@app/shared/services/alumnos.service';
import { CursosService } from '@app/shared/services/cursos.service';
import { PagosService } from '@app/shared/services/pagos.service';
import Alumno from '@app/shared/types/alumno.interface';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-pagos',
  templateUrl: './pagos.component.html',
  styleUrls: ['./pagos.component.css']
})
export class PagosComponent implements OnInit {
  public isLoading = true;
  public isLoadingPagos = true;
  public displayPagos = false;
  public alumnoSelected:any;
  public alumnos:Alumno[] = [];
  isLoadingInscripcion = false;
  IdStudent:string;
  RutStudent:string;
  disabledall:boolean=false;
  pagos:any[];
  formGroup: FormGroup;
  inscripcionList: any[];
  p:number=1;
  searchText:string;
  constructor(private formBuilder: FormBuilder,private alumnosService: AlumnosService,private pagosService: PagosService,private cdr: ChangeDetectorRef,
    private router: Router,private cursosService: CursosService) { }

  ngOnInit(): void {
    this.getalumnos();
    this.formGroup = this.formBuilder.group({
      payment_days: this.formBuilder.array([])

    });
  }
  
  cerrarLista(){
    this.displayPagos = false;
  }

  getInscripcion(id:string){
    this.isLoadingInscripcion = true;
    this.inscripcionList = [];
    this.cdr.detectChanges();
    this.alumnosService.getinscripcionxalumno(id).subscribe((resp:any)=>{
      this.isLoadingInscripcion = false;
      if(resp.code == 200){
        this.inscripcionList = resp.data;
      }else{
        this.inscripcionList = [];
      }
      this.cdr.detectChanges();

    });
  }

  verpagos(item:any){
    this.displayPagos = true;
    this.alumnoSelected = item;
    this.IdStudent = this.alumnoSelected.identity;
    this.RutStudent = this.alumnoSelected.idcard;
    this.pagos = [];
    this.isLoadingPagos = true;
    this.getInscripcion(item.identity);
    let fechaspago = this.formGroup.get('payment_days') as FormArray;
    fechaspago.clear();
    let datePipe = new DatePipe('en-US');
    this.pagosService.getPagosAlumno(item.identity).subscribe((pagos: any) => {
      this.isLoadingPagos = false;
      if(pagos.code == 200){
        this.pagos = pagos.data;
        pagos.data.forEach(pago => {
          let fechaFormateada = datePipe.transform(new Date(pago.agreedpaymentday), 'yyyy-MM-dd');
          let nuevoObjeto = new FormGroup({
            'identity':  new FormControl(pago.identity),
            'agreedpaymentday': new FormControl(fechaFormateada),
            'comments':new FormControl(pago.comments),
            'coursename': new FormControl(pago.coursename),
            'paymentcompleted': new FormControl(pago.paymentcompleted),
            'paymentcredit': new FormControl( pago.paymentcredit),
            'monthnumber': new FormControl( pago.monthnumber)
          });
          fechaspago.push(nuevoObjeto);
        });
      }
      this.cdr.detectChanges();
    });
  }

  getalumnos(){
    this.alumnosService.getall().subscribe((resp: any) => {
      this.isLoading = false;
      this.alumnos = resp.data;
      this.cdr.detectChanges();
    });
  }
}
