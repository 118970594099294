import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
const base_url = environment.base_url;
@Injectable({
  providedIn: 'root'
})
export class GastosService {
  constructor(private http: HttpClient) {
  }

  public getgastos(){
    return this.http.get(`${base_url}/api/v1/expenses/generallist`);
  } 

  public create(data:any){
    return this.http.post(`${base_url}/api/v1/expenses`,data);

  }

  public delete(identity:string){
    return this.http.delete(`${base_url}/api/v1/expenses/`+ identity);
  }

}