import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { CursosService } from '@app/shared/services/cursos.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-mis-cursos',
  templateUrl: './mis-cursos.component.html',
  styleUrls: ['./mis-cursos.component.css']
})
export class MisCursosComponent implements OnInit {

  constructor(private cursosService: CursosService,private formBuilder: FormBuilder,private cdr: ChangeDetectorRef) { }
  p:number=1;
  cursoSelected :any;
  idCourseSelected:string="";
  isLoading = false;
  isLoadingMaterial = false;

  cursos: any[]=[];
  materialesapoyo: any[]=[];

  ngOnInit(): void {
   this.getListCurso(); 
  }

  getListCurso(){
    this.isLoading = true;
    let data = JSON.parse(localStorage.getItem('datacli'));

    this.cursosService.getbystudentformaterial(data?.datosusu?.id).subscribe((resp: any) => {
      this.isLoading = false;
      this.cursos = resp.data;
      this.cdr.detectChanges();
    });
  }

  seleccionarCurso(item:any){
    this.cursoSelected = item;
    this.idCourseSelected = item.identity;
    
    this.obtenerDoctosCursos();
  }

  obtenerDoctosCursos(){
    this.isLoadingMaterial = true;
    this.materialesapoyo = [];
    this.cdr.detectChanges();

    this.cursosService.studymateriaGetMaterial(this.idCourseSelected).subscribe((resp: any) => {
      this.isLoadingMaterial = false;
      if(resp.code=200){
        this.materialesapoyo = resp.data;
      }
      this.cdr.detectChanges();
    });
  }

  descargarmaterial(filename){
    Swal.showLoading();
    this.cursosService.studymateriaViewDocto(filename).subscribe((resp: any) => {
      const blob = new Blob([resp], { type: 'application/octet-stream' });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = filename;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      window.URL.revokeObjectURL(url);
      Swal.close();

    },error=>{
      Swal.close();

      console.log("ERROR",error);
    });
  }

  cerrarCursoselected(){
    this.cursoSelected= null;
    this.idCourseSelected = '';
    
  }

  getTypeDescription(type: number): string {
    return this.cursosService.getTypeDescription(type).name;
  }

}
