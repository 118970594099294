import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { navConfigurationAdmin, navConfigurationExecutive, navConfigurationStudent, navConfigurationTeacher, navConfigurationUser } from '@app/configs/nav.config';
import { NavMenu } from '@app/shared/types/nav-menu.interface';

const base_url = environment.base_url;
@Injectable({
  providedIn: 'root'
})
export class MenuService {
  menu : NavMenu[] = [];

  constructor(private http: HttpClient) {
  }

  public getmenu(){
    let data = JSON.parse(localStorage.getItem('datacli'));
    switch (data.role) {
      case 'user':
          this.menu = navConfigurationUser;
          break;
      case 'admin':
          this.menu = navConfigurationAdmin;
          break;
      case 'teacher':
          this.menu = navConfigurationTeacher;
          break;
      case 'executive':
          this.menu = navConfigurationExecutive;
          break;
      case 'student':
          this.menu = navConfigurationStudent;
          break;
      default:

          break;
    }
    return this.menu;
  }

}