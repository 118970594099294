import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

const base_url = environment.base_url;
@Injectable({
  providedIn: 'root'
})
export class AsistenciaService {
  datosAEditar:any;

  constructor(private http: HttpClient) {
  }

  public getwithstudents(){
    return this.http.get(`${base_url}/api/v1/courses/getcourseswithstudents`);
  }

  public create(data:any){
    return this.http.post(`${base_url}/api/v1/attendances`,data);

  }

  public delete(data:any){
    return this.http.post(`${base_url}/api/v1/attendances/deleteinfront`,data);
  }

}