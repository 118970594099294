import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'marcaAsistencia'
})
export class MarcaAsistenciaPipe implements PipeTransform {

  transform(fechaconsulta: Date, fechasasistido: any,mesActual:number): boolean {
    let resp = null;
    fechasasistido.forEach(element => {
      let dtasis = new Date(element.date);
      if((dtasis.getMonth() + 1) == mesActual){
        if(fechaconsulta.getFullYear() == dtasis.getFullYear()){
          if(fechaconsulta.getMonth() == dtasis.getMonth()){
            if(fechaconsulta.getDate() == dtasis.getDate()){
              resp = true;
            }
          }
        }
      }
    });
    return resp;
  }

}
