import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'diaSemana'
})
export class DiaSemanaPipe implements PipeTransform {

  transform(valor: number): string {
    switch (valor) {
      case 1:
        return 'lu';
      case 2:
        return 'ma';
      case 3:
        return 'mi';
      case 4:
        return 'ju';
      case 5:
        return 'vi';
      case 6:
        return 'sá';
      case 0:
        return 'do';
      default:
        return valor.toString();
      }
    }
}
