import { NavMenu } from '@app/shared/types/nav-menu.interface';

const dashboard: NavMenu[] = [
    {
        path: '/dashboard',
        title: 'Dashboard',
        translateKey: 'Inicio',
        type: 'item',
        iconType: 'feather',
        icon: 'icon-home',
        key: 'dashboard',
        submenu: []
    },
]
const menuAdmin: NavMenu[] = [
    {
        path: '/alumnos',
        title: 'Alumnos',
        translateKey: 'Alumnos',
        type: 'item',
        iconType: 'feather',
        icon: 'icon-users',
        key: 'Alumnos',
        submenu: [
            {
                path: '/alumnos',
                title: 'Lista de Alumnos',
                translateKey: 'Lista de alumnos',
                type: 'item',
                iconType: 'feather',
                icon: 'icon-user-plus',
                key: 'ListaAlumnos',
                submenu: []
            },
            {
                path: '/alumnos/morosos',
                title: 'Lista de Morosos',
                translateKey: 'Lista de Morosos',
                type: 'item',
                iconType: 'feather',
                icon: 'icon-user-plus',
                key: 'ListaMorosos',
                submenu: []
            },
            {
                path: '/alumnos/congelados',
                title: 'Lista de Congelados',
                translateKey: 'Lista de Congelados',
                type: 'item',
                iconType: 'feather',
                icon: 'icon-user-plus',
                key: 'ListaCongelados',
                submenu: []
            },
            {
                path: '/alumnos/admin/agregar',
                title: 'Agregar nuevo alumno',
                translateKey: 'Agregar nuevo alumno',
                type: 'item',
                iconType: 'feather',
                icon: 'icon-user-plus',
                key: 'AgregarAlumno',
                submenu: []
            }
        ]
    },
    {
        path: '/asistencia',
        title: 'Asistencias',
        translateKey: 'Asistencias',
        type: 'item',
        iconType: 'feather',
        icon: 'icon-list',
        key: 'Asistencias',
        submenu: []
    },  
    {
        path: '/pagos',
        title: 'Pagos',
        translateKey: 'Pagos',
        type: 'item',
        iconType: 'feather',
        icon: 'icon-credit-card',
        key: 'Pagos',
        submenu: []
    },
    {
        path: '/gastos',
        title: 'Gastos',
        translateKey: 'Gastos',
        type: 'item',
        iconType: 'feather',
        icon: 'icon-shopping-bag',
        key: 'Gastos',
        submenu: [
            {
                path: '/gastos',
                title: 'Lista de Gastos',
                translateKey: 'Lista de Gastos',
                type: 'item',
                iconType: 'feather',
                icon: 'icon-shopping-bag',
                key: 'ListaGastos',
                submenu: []
            },
            {
                path: '/gastos/admin/agregar',
                title: 'Agregar Gastos',
                translateKey: 'Agregar Gastos',
                type: 'item',
                iconType: 'feather',
                icon: 'icon-shopping-bag',
                key: 'AgregarGastos',
                submenu: []
            }
        ]
    },
    {
        path: '/cursos',
        title: 'Cursos',
        translateKey: 'Cursos',
        type: 'item',
        iconType: 'feather',
        icon: 'icon-file',
        key: 'Cursos',
        submenu: [
            {
                path: '/cursos',
                title: 'Lista de cursos',
                translateKey: 'Lista de cursos',
                type: 'item',
                iconType: 'feather',
                icon: 'icon-user-plus',
                key: 'Cursos',
                submenu: []
            },
            {
                path: '/cursos/admin/agregar',
                title: 'Agregar nuevo Curso',
                translateKey: 'Agregar nuevo curso',
                type: 'item',
                iconType: 'feather',
                icon: 'icon-user-plus',
                key: 'AgregarCursos',
                submenu: []
            },
            {
                path: '/cursos/material-cursos',
                title: 'Material Curso',
                translateKey: 'Material Curso',
                type: 'item',
                iconType: 'feather',
                icon: 'icon-user-plus',
                key: 'MaterialCurso',
                submenu: []
            }
        ]
    },
    {
        path: '/graduaciones',
        title: 'Graduaciones',
        translateKey: 'Graduaciones',
        type: 'item',
        iconType: 'feather',
        icon: 'icon-award',
        key: 'Graduaciones',
        submenu: [
            {
                path: '/graduaciones',
                title: 'Lista de Graduaciones',
                translateKey: 'Lista de Graduaciones',
                type: 'item',
                iconType: 'feather',
                icon: 'icon-user-plus',
                key: 'ListaGraduaciones',
                submenu: []
            },
            {
                path: '/graduaciones/admin/agregar',
                title: 'Agregar Graduación',
                translateKey: 'Agregar Graduacion',
                type: 'item',
                iconType: 'feather',
                icon: 'icon-user-plus',
                key: 'AgregarGraduacion',
                submenu: []
            },
           
        ]
    },
    {
        path: '/ejecutivos',
        title: 'Ejecutivos',
        translateKey: 'Ejecutivos',
        type: 'item',
        iconType: 'feather',
        icon: 'icon-users',
        key: 'Ejecutivos',
        submenu: [
            {
                path: '/ejecutivos',
                title: 'Lista de Ejecutivos',
                translateKey: 'Lista de Ejecutivos',
                type: 'item',
                iconType: 'feather',
                icon: 'icon-user-plus',
                key: 'ListaEjecutivos',
                submenu: []
            },
            {
                path: '/ejecutivos/admin/agregar',
                title: 'Agregar Ejecutivo',
                translateKey: 'Agregar Ejecutivo',
                type: 'item',
                iconType: 'feather',
                icon: 'icon-user-plus',
                key: 'AgregarEjecutivos',
                submenu: []
            }
        ]
    },
    {
        path: '/profesores',
        title: 'Profesores',
        translateKey: 'Profesores',
        type: 'item',
        iconType: 'feather',
        icon: 'icon-users',
        key: 'Profesores',
        submenu: [
            {
                path: '/profesores',
                title: 'Lista de profesores',
                translateKey: 'Lista de profesores',
                type: 'item',
                iconType: 'feather',
                icon: 'icon-user-plus',
                key: 'ListaProfesores',
                submenu: []
            },
            {
                path: '/profesores/admin/agregar',
                title: 'Agregar Profesor',
                translateKey: 'Agregar Profesor',
                type: 'item',
                iconType: 'feather',
                icon: 'icon-user-plus',
                key: 'AgregarProfesores',
                submenu: []
            }
        ]
    },
    {
        path: '/usuarios',
        title: 'Usuarios',
        translateKey: 'Usuarios',
        type: 'item',
        iconType: 'feather',
        icon: 'icon-users',
        key: 'Usuarios',
        submenu: [
            {
                path: '/usuarios',
                title: 'Lista de usuarios',
                translateKey: 'Lista de usuarios',
                type: 'item',
                iconType: 'feather',
                icon: 'icon-user-plus',
                key: 'ListaUsuarios',
                submenu: []
            }
        ]
    }
]
const menuUser: NavMenu[] = [
    {
        path: '/cursos',
        title: 'Cursos',
        translateKey: 'Cursos',
        type: 'item',
        iconType: 'feather',
        icon: 'icon-file',
        key: 'Cursos',
        submenu: [            
            {
                path: '/cursos/mis-cursos',
                title: 'Mis Cursos',
                translateKey: 'Mis Cursos',
                type: 'item',
                iconType: 'feather',
                icon: 'icon-user-plus',
                key: 'MisCursos',
                submenu: []
            },
        ]
    },
]
const menuTeacher: NavMenu[] = [
    {
        path: '/asistencia',
        title: 'Asistencias',
        translateKey: 'Asistencias',
        type: 'item',
        iconType: 'feather',
        icon: 'icon-list',
        key: 'Asistencias',
        submenu: []
    },
    {
        path: '/cursos',
        title: 'Cursos',
        translateKey: 'Cursos',
        type: 'item',
        iconType: 'feather',
        icon: 'icon-file',
        key: 'Cursos',
        submenu: [
            {
                path: '/cursos/material-cursos',
                title: 'Material Curso',
                translateKey: 'Material Curso',
                type: 'item',
                iconType: 'feather',
                icon: 'icon-user-plus',
                key: 'MaterialCurso',
                submenu: []
            }
        ]
    },
]
const menuExecutive: NavMenu[] =[
    {
        path: '/alumnos',
        title: 'Alumnos',
        translateKey: 'Alumnos',
        type: 'item',
        iconType: 'feather',
        icon: 'icon-users',
        key: 'Alumnos',
        submenu: [
            {
                path: '/alumnos',
                title: 'Lista de Alumnos',
                translateKey: 'Lista de alumnos',
                type: 'item',
                iconType: 'feather',
                icon: 'icon-user-plus',
                key: 'ListaAlumnos',
                submenu: []
            },
            {
                path: '/alumnos/morosos',
                title: 'Lista de Morosos',
                translateKey: 'Lista de Morosos',
                type: 'item',
                iconType: 'feather',
                icon: 'icon-user-plus',
                key: 'ListaMorosos',
                submenu: []
            },
            {
                path: '/alumnos/congelados',
                title: 'Lista de Congelados',
                translateKey: 'Lista de Congelados',
                type: 'item',
                iconType: 'feather',
                icon: 'icon-user-plus',
                key: 'ListaCongelados',
                submenu: []
            },
            {
                path: '/alumnos/admin/agregar',
                title: 'Agregar nuevo alumno',
                translateKey: 'Agregar nuevo alumno',
                type: 'item',
                iconType: 'feather',
                icon: 'icon-user-plus',
                key: 'AgregarAlumno',
                submenu: []
            }
        ]
    },
    {
        path: '/asistencia',
        title: 'Asistencias',
        translateKey: 'Asistencias',
        type: 'item',
        iconType: 'feather',
        icon: 'icon-list',
        key: 'Asistencias',
        submenu: []
    },  
    {
        path: '/pagos',
        title: 'Pagos',
        translateKey: 'Pagos',
        type: 'item',
        iconType: 'feather',
        icon: 'icon-credit-card',
        key: 'Pagos',
        submenu: []
    },
    {
        path: '/gastos',
        title: 'Gastos',
        translateKey: 'Gastos',
        type: 'item',
        iconType: 'feather',
        icon: 'icon-shopping-bag',
        key: 'Gastos',
        submenu: [
            {
                path: '/gastos',
                title: 'Lista de Gastos',
                translateKey: 'Lista de Gastos',
                type: 'item',
                iconType: 'feather',
                icon: 'icon-shopping-bag',
                key: 'ListaGastos',
                submenu: []
            },
            {
                path: '/gastos/admin/agregar',
                title: 'Agregar Gastos',
                translateKey: 'Agregar Gastos',
                type: 'item',
                iconType: 'feather',
                icon: 'icon-shopping-bag',
                key: 'AgregarGastos',
                submenu: []
            }
        ]
    },
    {
        path: '/cursos',
        title: 'Cursos',
        translateKey: 'Cursos',
        type: 'item',
        iconType: 'feather',
        icon: 'icon-file',
        key: 'Cursos',
        submenu: [
            {
                path: '/cursos',
                title: 'Lista de cursos',
                translateKey: 'Lista de cursos',
                type: 'item',
                iconType: 'feather',
                icon: 'icon-user-plus',
                key: 'Cursos',
                submenu: []
            },
            {
                path: '/cursos/admin/agregar',
                title: 'Agregar nuevo Curso',
                translateKey: 'Agregar nuevo curso',
                type: 'item',
                iconType: 'feather',
                icon: 'icon-user-plus',
                key: 'AgregarCursos',
                submenu: []
            },
            {
                path: '/cursos/material-cursos',
                title: 'Material Curso',
                translateKey: 'Material Curso',
                type: 'item',
                iconType: 'feather',
                icon: 'icon-user-plus',
                key: 'MaterialCurso',
                submenu: []
            }
        ]
    },
    {
        path: '/graduaciones',
        title: 'Graduaciones',
        translateKey: 'Graduaciones',
        type: 'item',
        iconType: 'feather',
        icon: 'icon-award',
        key: 'Graduaciones',
        submenu: [
            {
                path: '/graduaciones',
                title: 'Lista de Graduaciones',
                translateKey: 'Lista de Graduaciones',
                type: 'item',
                iconType: 'feather',
                icon: 'icon-user-plus',
                key: 'ListaGraduaciones',
                submenu: []
            },
            {
                path: '/graduaciones/admin/agregar',
                title: 'Agregar Graduación',
                translateKey: 'Agregar Graduacion',
                type: 'item',
                iconType: 'feather',
                icon: 'icon-user-plus',
                key: 'AgregarGraduacion',
                submenu: []
            },
           
        ]
    },
    {
        path: '/ejecutivos',
        title: 'Ejecutivos',
        translateKey: 'Ejecutivos',
        type: 'item',
        iconType: 'feather',
        icon: 'icon-users',
        key: 'Ejecutivos',
        submenu: [
            {
                path: '/ejecutivos',
                title: 'Lista de Ejecutivos',
                translateKey: 'Lista de Ejecutivos',
                type: 'item',
                iconType: 'feather',
                icon: 'icon-user-plus',
                key: 'ListaEjecutivos',
                submenu: []
            },
            {
                path: '/ejecutivos/admin/agregar',
                title: 'Agregar Ejecutivo',
                translateKey: 'Agregar Ejecutivo',
                type: 'item',
                iconType: 'feather',
                icon: 'icon-user-plus',
                key: 'AgregarEjecutivos',
                submenu: []
            }
        ]
    },
    {
        path: '/profesores',
        title: 'Profesores',
        translateKey: 'Profesores',
        type: 'item',
        iconType: 'feather',
        icon: 'icon-users',
        key: 'Profesores',
        submenu: [
            {
                path: '/profesores',
                title: 'Lista de profesores',
                translateKey: 'Lista de profesores',
                type: 'item',
                iconType: 'feather',
                icon: 'icon-user-plus',
                key: 'ListaProfesores',
                submenu: []
            },
            {
                path: '/profesores/admin/agregar',
                title: 'Agregar Profesor',
                translateKey: 'Agregar Profesor',
                type: 'item',
                iconType: 'feather',
                icon: 'icon-user-plus',
                key: 'AgregarProfesores',
                submenu: []
            }
        ]
    }
]
const menuStudent: NavMenu[] = [
    {
        path: '/cursos',
        title: 'Cursos',
        translateKey: 'Cursos',
        type: 'item',
        iconType: 'feather',
        icon: 'icon-file',
        key: 'Cursos',
        submenu: [            
            {
                path: '/cursos/mis-cursos',
                title: 'Mis Cursos',
                translateKey: 'Mis Cursos',
                type: 'item',
                iconType: 'feather',
                icon: 'icon-user-plus',
                key: 'MisCursos',
                submenu: []
            },
        ]
    },
]

export const navConfigurationUser: NavMenu[] = [
    ...dashboard,
    {
        path: '',
        title: 'Menu with Title',
        translateKey: 'NAV.MENU_WITH_TITLE',
        type: 'title',
        iconType: 'feather',
        icon: 'icon-file',
        key: 'menu-with-title',
        submenu: [
            ...menuUser
        ]
    }
]
export const navConfigurationAdmin: NavMenu[] = [
    ...dashboard,
    {
        path: '',
        title: 'Menu with Title',
        translateKey: 'NAV.MENU_WITH_TITLE',
        type: 'title',
        iconType: 'feather',
        icon: 'icon-file',
        key: 'menu-with-title',
        submenu: [
            ...menuAdmin
        ]
    }
]
export const navConfigurationTeacher: NavMenu[] = [
    ...dashboard,
    {
        path: '',
        title: 'Menu with Title',
        translateKey: 'NAV.MENU_WITH_TITLE',
        type: 'title',
        iconType: 'feather',
        icon: 'icon-file',
        key: 'menu-with-title',
        submenu: [
            ...menuTeacher
        ]
    }
]
export const navConfigurationExecutive: NavMenu[] = [
    ...dashboard,
    {
        path: '',
        title: 'Menu with Title',
        translateKey: 'NAV.MENU_WITH_TITLE',
        type: 'title',
        iconType: 'feather',
        icon: 'icon-file',
        key: 'menu-with-title',
        submenu: [
            ...menuExecutive
        ]
    }
]
export const navConfigurationStudent: NavMenu[] = [
    ...dashboard,
    {
        path: '',
        title: 'Menu with Title',
        translateKey: 'NAV.MENU_WITH_TITLE',
        type: 'title',
        iconType: 'feather',
        icon: 'icon-file',
        key: 'menu-with-title',
        submenu: [
            ...menuStudent
        ]
    }
]