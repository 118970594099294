import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { GastosService } from '@app/shared/services/gastos.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-agregar-gastos',
  templateUrl: './agregar-gastos.component.html',
  styleUrls: ['./agregar-gastos.component.css']
})
export class AgregarGastosComponent implements OnInit {
  formGroup: FormGroup;
  isLoading = false;
  constructor(private formBuilder: FormBuilder,private router:Router,private gastosService: GastosService,private cdr: ChangeDetectorRef,
    private route: ActivatedRoute) { }

  ngOnInit(): void {
    this.formGroup = this.formBuilder.group({
      name:['',[Validators.required,Validators.maxLength(500)]],
      amount:[0,[Validators.required,Validators.min(1)]],
      paymentday:['',[Validators.required]],
      comments:['']
    });
  }

  agregar(){
    console.log(this.formGroup);
    if(this.formGroup.valid){
      Swal.showLoading();
      this.isLoading = false;
      this.gastosService.create(this.formGroup.getRawValue()).subscribe((resp: any) => {
        Swal.close();
        if(resp.code == 200){
          Swal.fire({
            title: '',
            text: 'Datos insertados correctamente.',
            icon: 'success',
            confirmButtonText: 'OK'
          }).then((result) => {
            if (result.isConfirmed) {
              // Redirigir a la página deseada usando el Router de Angular
              this.router.navigate(['/gastos']);
            }
          });
        }else{
          Swal.fire(
            '',
            'No se pudo insertar los datos, favor comuníquese con el administrador.',
            'error'
            );
        }
        this.isLoading = false;
        this.cdr.detectChanges();

      });
    }else{
      Swal.fire(
        '',
        'Debe completar los campos correctamente.',
        'error'
        );
        this.isLoading = false;
        this.cdr.detectChanges();
    }
  }

}
