import { Component, OnInit, ChangeDetectionStrategy, TemplateRef } from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { ModalDirection } from '@app/shared/types/modal-direction';
import { AuthenticationService } from '@app/shared/services/authentication.service';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject } from 'rxjs';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';
const base_url = environment.base_url;
interface User {
    id: number;
    username: string;
    password: string;
    token?: string;
}
@Component({
    selector: 'nav-config',
    templateUrl: './nav-config.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    host: {
        '[class.header-nav-item]': 'true'
    }
})

export class NavConfigComponent implements OnInit {
    modalRef: BsModalRef;
    dataUsuario :any;
    constructor(private modalService: BsModalService,private http: HttpClient,private router: Router) {}
    
    ngOnInit () {
        let data = JSON.parse(localStorage.getItem('datacli'));
        this.dataUsuario = data;
    }

    openQuickPanel(template: TemplateRef<any>) {
        this.modalRef = this.modalService.show( template, Object.assign({}, { class: `${ModalDirection.Right}` }));
    }

    cerrarSesion(){
        Swal.showLoading();
        this.http.post(`${base_url}/api/v1/logout`,{}).subscribe((resp: any) => {
            Swal.close();
            localStorage.removeItem('currentUser');
            this.router.navigate(['','login']);
            return resp;        
        });
        
        localStorage.removeItem('expire');
        localStorage.removeItem('mil_auth');

    }
}
