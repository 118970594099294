
<nav class="navbar navbar-light bg-light mb-3">
    <div class="container-fluid">
        <a class="btn btn-success"  [routerLink]="['/graduaciones/admin/agregar']">
            <i class="feather icon-user-plus"></i> Agregar Graduación</a>
            <form class="d-flex w-50">
                <input class="form-control  me-2" type="search" placeholder="Buscar graduación" [(ngModel)]="searchText" (input)="onSearchTextChanged()" name="filtroNombre" aria-label="Search">
            </form>
    </div>
</nav>
<section class="demo-section row">
    <div class="content-box table-responsive">
        <table class="table">
            <thead>
                <tr>
                    <th scope="col">Nombre</th>
                    <th scope="col">Fecha</th>
                    <th scope="col">Curso</th>
                    <th scope="col">Reservas</th>
                    <th scope="col">Asientos Disponibles</th>
                    <th scope="col">Lista de Invitados</th>
                    <th scope="col">Acciones</th>
                </tr>
            </thead>
            <tbody >
                <tr *ngIf="isLoading">
                    <td colspan="5">
                      <div class="spinner-border spinner-border-sm" role="status">
                        <span class="sr-only">Loading...</span>
                      </div>
                    </td>
                  </tr>
                  <tr
                    *ngFor="
                      let item of lista
                        | searchFilter : searchText
                        | paginate : { itemsPerPage: 10, currentPage: p }
                    "
                  >
                    <td>{{ item.name }}</td>
                    <td>{{ item.date | date: 'yyyy-MM-dd'}}</td>
                    <td>{{ item.course_name }}</td>
                    <td>{{ item.reserved_seats }} Reservas</td>
                    <td>{{ item.number_seats - item.reserved_seats  }} / {{ item.number_seats }} Asientos</td>
                    <td>
                        <button type="button" class="btn m-1 btn-sm btn-info" (click)="verinvitados(item)">Lista de invitados</button>
                    </td>
                    <td>
                        <a class="btn m-1 btn-sm btn-info" (click)="editar(item)">Editar</a>
                        <a class="btn m-1 btn-sm btn-danger" (click)="eliminar(item)">Eliminar</a>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</section>