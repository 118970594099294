<nav class="navbar navbar-light bg-light mb-3">
    <div class="container-fluid">
        <a class="btn btn-warning" [routerLink]="['/usuarios']">
            <i class="feather icon-arrow-left"></i> Volver a la lista de usuarios
        </a>
    </div>
</nav>

<section class="demo-section row">
    <div class="content-box col-12">
        <form class="row g-3" [formGroup]="formGroup" (validSubmit)="save()">
            <label class="form-label col-12">Ingresar datos del usuario</label>
            <div class="col-md-4">
                <label class="form-label">Nombre</label>
                <input type="text" class="form-control" formControlName="name">
            </div>
            <div class="col-md-4">
                <label class="form-label">Email</label>
                <input type="email" class="form-control" placeholder="hola@admin.cl" formControlName="email">
            </div>
            <div class="col-md-4">
                <label class="form-label">Rol</label>
                <select class="form-select" formControlName="role">
                    <option value="admin" selected>Administrador</option>
                    <option value="teacher">Profesor</option>
                    <option value="executive">Ejecutivo</option>
                    <option value="student">Alumno</option>
                </select>
            </div>
            <div class="col-md-4">
                <label class="form-label" *ngIf="typeForm == 'Editar'">Definir nueva contraseña, dejar en blanco para conservar contraseña antigua.</label>
                <label class="form-label" *ngIf="typeForm != 'Editar'">Contraseña</label>
                <input type="password"  autocomplete="new-password" class="form-control" placeholder="***********" formControlName="password">
            </div>
            <div class="col-12 mt-3">
                <button type="submit" class="btn btn-primary" [disabled]="isLoading"><i class="feather icon-check-circle"></i> 
                    {{typeForm}} Usuario
                    <div *ngIf="isLoading">
                        <div class="spinner-border spinner-border-sm" role="status">
                            <span class="sr-only">Loading...</span>
                        </div>
                    </div>
                </button>
            </div>
        </form>
    </div>
</section>