import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
const base_url = environment.base_url;
@Injectable({
  providedIn: 'root'
})
export class UsuariosService {
  datosAEditar:any;

  constructor(private http: HttpClient) {
  }

  public getall(){
    return this.http.get(`${base_url}/api/v1/users`);
  }
  public create(data:any){
    return this.http.post(`${base_url}/api/v1/users`,data);

  }
  public edit(data: any,identity:string){
    return this.http.put(`${base_url}/api/v1/users/`+ identity,data);
  }
  public delete(identity:string){
    return this.http.delete(`${base_url}/api/v1/users/`+ identity);
  }

}