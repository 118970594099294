import { ChangeDetectorRef, Component, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { UsuariosService } from '@app/shared/services/usuarios.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-list-usuarios',
  templateUrl: './list-usuarios.component.html',
  styleUrls: ['./list-usuarios.component.css']
})
export class ListUsuariosComponent implements OnInit {
  public isLoading = true;
  public usuarios:any[] = [];
  pagos:any[];
  formGroup: FormGroup;
  p:number=1;
  searchText:string;
  constructor(private formBuilder: FormBuilder,private usuariosService: UsuariosService,private cdr: ChangeDetectorRef,
    private router: Router) { }

  ngOnInit(): void {
    this.getusuarios();
    
  }

  editar(item:any){
    this.usuariosService.datosAEditar = item;
    this.router.navigate(['/usuarios/admin','editar']);
   }

   eliminar(item:any){
     Swal.fire({
       title: 'Eliminar usuario',
       text: '¿Realmente desea eliminar este usuario?.',
       icon: 'question',
       showCancelButton: true,
       confirmButtonText: 'Sí',
       denyButtonText: 'No',
     }).then((result) => {
       if (result.isConfirmed) {
         console.log("eliminando item",item.id);
         this.usuariosService.delete(item.id).subscribe((resp: any) => {
           if(resp.code == 200){
             Swal.fire({
               title: '',
               text: 'usuario eliminado correctamente.',
               icon: 'success',
               confirmButtonText: 'OK'
             }).then((result2) => {
               this.getusuarios();
             });
           }else{
             Swal.fire(
               '',
               'No se pudo eliminar usuario, favor comuníquese con el administrador.',
               'error'
               );
           }
           this.cdr.detectChanges();
   
         });
         this.cdr.detectChanges();
       }
     });
   }
  
  onSearchTextChanged() {
    this.p = 1;
  }

  getusuarios(){
    this.usuariosService.getall().subscribe((resp: any) => {
      this.isLoading = false;
      this.usuarios = resp.data;
      this.cdr.detectChanges();
    });
  }
}
