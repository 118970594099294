
<nav class="navbar navbar-light bg-light mb-3">
    <div class="container-fluid">
        <form class="d-flex w-100">
        <input class="form-control me-2" type="search" placeholder="Buscar cursos" [(ngModel)]="searchText" name="filtroNombre" (input)="onSearchTextChanged()" aria-label="Search">
       
        </form>
    </div>
</nav>
<section class="demo-section row" *ngIf="muestraSeccionCurso">
    <div class="content-box table-responsive">
        <table class="table">
            <thead>
                <tr>
                    <th scope="col">Nombre Curso</th>
                    <th scope="col">Alumnos Inscritos</th>
                    <th scope="col">Pasar Asistencia</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngIf="isLoading">
                    <td colspan="7">
                        <div class="spinner-border spinner-border-sm" role="status">
                            <span class="sr-only">Loading...</span>
                        </div>
                    </td>
                </tr>
                <tr *ngFor="let item of cursos | searchFilter:searchText | paginate: { itemsPerPage: 10, currentPage: p } ">
                    <td>{{ item.course?.acronym }}</td>
                    <td>{{ item.count }}</td>
                    <td>
                        <a class="btn m-1 btn-sm btn-info" (click)="construyeLista(item.course)">Pasar Lista <i class="feather icon-check-square"></i></a>
                    </td>
                </tr>
            </tbody>
        </table>
        <div class="d-flex justify-content-end">
            <pagination-controls (pageChange)="p = $event" previousLabel="Anterior" maxSize="10" nextLabel="Siguiente"></pagination-controls>
        </div>
    </div>
</section>

<section class="demo-section row mt-5" *ngIf="cursoSelected">
    <div class="content-box table-responsiv lista">
        <div class="row">
            <div class="col-12 col-md-7">
                <h4>Lista Curso:<br>{{ cursoSelected?.acronym }}</h4>
            </div>
            <div class="col-12 col-md-5">
                <div class="row">
                    <div class="col-7">
                        <select class="form-select" (change)="selectMes($event)">
                            <option *ngFor="let item of meses" [value]="item.id" >{{ item.name }}</option>
                        </select>
                    </div>
                    <div class="col-5">
                        <button class="btn btn-outline-warning w-100" type="button" (click)="cerrarLista()">Cerrar Lista <i class="feather icon-x-circle"></i></button>
                    </div>
                </div>
            </div>
        </div>
        <div class="container-fluid">
            <form class="d-flex w-100">
            <input class="form-control me-2" type="search" placeholder="Buscar alumno" (input)="onSearchTextChangedAlumno($event)" aria-label="Search">
           
            </form>
        </div>
        <table class="table table-bordered mt-4">
            <thead>
                <tr>
                    <th rowspan="2">Nombre Alumno</th>
                    <th class="text-center text-uppercase" [attr.colspan]="listaDias.length">Asistencia {{ mesSelected }} <i class="feather icon-calendar"></i></th>
                </tr>
                <tr class="dias">
                    <th *ngFor="let item of listaDias">{{ item.getDay() | diaSemana }}<br> {{item.getDate()}}</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngIf="loadingListaAsistencia">
                    <td colspan="7">
                        <div class="spinner-border spinner-border-sm" role="status">
                            <span class="sr-only">Loading...</span>
                        </div>
                    </td>
                </tr>
                <tr *ngFor="let estudiante of estudiantes">
                    <td class="nombreAlumno"><span *ngIf="estudiante.student.type_course == 3"><i class="feather icon-shield"></i></span> {{ estudiante.student.name }} {{ estudiante.student.lastname}}</td>
                    <!-- Aquí puedes repetir la siguiente celda por cada día de la semana -->
                    <td *ngFor="let item of listaDias" [attr.class]="(((item.getDate() == diaActual.getDate()) && item.getMonth() == diaActual.getMonth() )? 'active':'')">
                        <input class="form-check-input" type="checkbox" [attr.checked]="item | marcaAsistencia:estudiante.attendances:mesSelectedNumber" (change)="marcarAsistencia($event,cursoSelected,estudiante.student,item)" >
                    </td>
                   
                </tr>
            </tbody>
        </table>

    </div>
</section>


