import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { AlumnosService } from '@app/shared/services/alumnos.service';
import { AsistenciaService } from '@app/shared/services/asistencia.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-asistencia',
  templateUrl: './asistencia.component.html',
  styleUrls: ['./asistencia.component.css']
})
export class AsistenciaComponent implements OnInit {
  public isLoading = true;
  public muestraSeccionCurso = true;
  public loadingListaAsistencia = false;
  public estudiantes = [];
  public todosestudiantes = [];
  public cursos = [];
  public cursoSelected;
  public meses: { id: string; name: string }[] = [];
  public listaDias = [];
  public mesSelected:string ="";
  public mesSelectedNumber:number =0;
  public dateSelected:Date;
  public diaActual = new Date();
  public restDataStudent:any[]=[];
  p:number=1;
  searchText:string;
  constructor(private asistenciaService: AsistenciaService,private alumnosService: AlumnosService,private cdr: ChangeDetectorRef) { }

  ngOnInit(): void {
    this.getListCurso();
    this.generarMeses();
  
  }

  onSearchTextChanged() {
    this.p = 1;
  }

  onSearchTextChangedAlumno(e: Event){
    let criterio = (e.target as HTMLInputElement).value;
    if(criterio == ""){
      this.estudiantes = this.todosestudiantes;
    }else{
      this.estudiantes = this.todosestudiantes.filter(estudiante => {
        const nombreApellido = estudiante.student.name.toLowerCase() + estudiante.student.lastname.toLowerCase();
        return nombreApellido.includes(criterio.toLowerCase());
      });
    }
  }

  getListCurso(){
    this.asistenciaService.getwithstudents().subscribe((resp: any) => {
      this.isLoading = false;
      this.cursos = resp.data;
      this.cdr.detectChanges();
    });
  }

  cerrarLista(){
    this.cursoSelected = null;
    this.muestraSeccionCurso = true;
  }

  construyeLista(curso:any){
    this.muestraSeccionCurso = false;
    this.loadingListaAsistencia = true;
    this.estudiantes = [];
    this.todosestudiantes = [];
    this.cursoSelected = curso;
    this.alumnosService.getstudentsbycourse(curso.identity).subscribe((resp: any) => {
      if(resp.code == 200){
        this.restDataStudent = resp.data;
        this.filtraDataporMes();
      }
      this.loadingListaAsistencia = false;
      this.cdr.detectChanges();
      
    });
  }

  filtraDataporMes(){
    this.estudiantes = this.restDataStudent;
    this.todosestudiantes = this.restDataStudent;
    /*this.restDataStudent.forEach(item => {
      let fecini = new Date(item.student.startCourse);
      let fechafin = new Date(fecini);
      fechafin.setMonth(fechafin.getMonth() + item.student.duration);
      let fechaconsulta = new Date(this.dateSelected);
      if(fechaconsulta.getMonth() >= fecini.getMonth() && fechaconsulta.getMonth() <= fechafin.getMonth()){
        this.estudiantes.push(item);
      }
    });*/
  }

  generarMeses(): void {
    let dtnow = new Date();
    this.selectMes({"target": { "value" : `${dtnow.getFullYear()}/${dtnow.getMonth() + 1}/01`}});
    for (let index = 12; index > 0; index--) {
      const formattedDate = this.formatDate(dtnow);
      this.meses.push({
        id: `${dtnow.getFullYear()}/${dtnow.getMonth() + 1}/01`,
        name: formattedDate,
      });
      dtnow.setMonth(dtnow.getMonth() - 1);
    }
  }

  formatDate(date: Date): string {
    const monthNames = [
      "enero", "febrero", "marzo",
      "abril", "mayo", "junio",
      "julio", "agosto", "septiembre",
      "octubre", "noviembre", "diciembre"
    ];

    const month = monthNames[date.getMonth()];
    const year = date.getFullYear();

    return `${month} ${year}`;
  }

  selectMes(event:any){
    let dt = new Date(event.target.value);
    this.dateSelected = dt;
    this.mesSelected = this.formatDate(dt);
    this.mesSelectedNumber = dt.getMonth() + 1;
    this.listaDias = this.obtenerListaDiasDelMes(dt.getFullYear(),dt.getMonth() + 1);
    this.filtraDataporMes();
  }

  obtenerListaDiasDelMes(anio: number, mes: number): Date[] {
    const primeraFechaDelMes = new Date(anio, mes - 1, 1);
    const ultimaFechaDelMes = new Date(anio, mes, 0);

    const listadeDias: Date[] = [];

    for (let dia = primeraFechaDelMes.getDate(); dia <= ultimaFechaDelMes.getDate(); dia++) {
      const fecha = new Date(anio, mes - 1, dia);
      listadeDias.push(fecha);
    }

    return listadeDias;
  }

  marcarAsistencia(event:any,cursoSelected:any,estudiante:any,item:Date){
    Swal.showLoading();
    if(event.target.checked){
      let objasistencia = {
        "student":estudiante.identity,
        "course":cursoSelected.identity,
        "floor":cursoSelected.floor,
        "date":`${item.getFullYear()}-${item.getMonth()+1}-${item.getDate()}T${cursoSelected.starttime}`,
        "startDayCount":0,
        "endDayCount":0,
        "attendance":true
      };
      this.asistenciaService.create(objasistencia).subscribe((resp: any) => {
        Swal.close();
        if(resp.code == 200){
        }else{
          event.target.checked = false;
          Swal.fire(
            '',
            `No se pudo marcar esta asistencia a ${estudiante.nombre} ${estudiante.apellido}, intente nuevamente.`,
            'error'
            );
        }
      }, (error) => {
        Swal.close();
        event.target.checked = false;
        Swal.fire(
          '',
          `No se pudo marcar esta asistencia a ${estudiante.nombre} ${estudiante.apellido}, intente nuevamente.`,
          'error'
          );
      });
    }else{
      let objaeliminasistencia = {
        "student":estudiante.identity,
        "course":cursoSelected.identity,
        "date":`${item.getFullYear()}-${item.getMonth()+1}-${item.getDate()}T${cursoSelected.starttime}`,
      };
      this.asistenciaService.delete(objaeliminasistencia).subscribe((resp: any) => {
        Swal.close();
        if(resp.code == 200){

        }else{
          event.target.checked = true;
        }
      }, (error) => {
        event.target.checked = true;
        Swal.close();
        Swal.fire(
          '',
          `No se pudo desmarcar esta asistencia a ${estudiante.nombre} ${estudiante.apellido}, intente nuevamente.`,
          'error'
          );
      });
    }
  }
}
