import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
const base_url = environment.base_url;
@Injectable({
  providedIn: 'root'
})
export class GraduacionService {
  datosAEditar:any;
  datosGraduacionInvitado:any;
  constructor(private http: HttpClient) {
  }

  public getall(){
    return this.http.get(`${base_url}/api/v1/graduation`);
  }

  public getasientosdisponibles(id:string,custom:number){
    return this.http.get(`${base_url}/api/v1/graduationattendee/getAvailableSeats/${id}/${custom}`);
  }

  public getinvitados(id:string){
    return this.http.get(`${base_url}/api/v1/graduationattendee/getByGraduation/${id}`);
  }

  public editinvitados(data: any,identity:string){
    return this.http.put(`${base_url}/api/v1/graduationattendee/`+ identity,data);
  }

  public agregarinvitados(data:any){
    return this.http.post(`${base_url}/api/v1/graduationattendee`,data);

  }
  
  public deleteinvitados(identity:string){
    return this.http.delete(`${base_url}/api/v1/graduationattendee/`+ identity);
  }


  public create(data:any){
    return this.http.post(`${base_url}/api/v1/graduation`,data);

  }
  public edit(data: any,identity:string){
    return this.http.put(`${base_url}/api/v1/graduation/`+ identity,data);
  }

  public delete(identity:string){
    return this.http.delete(`${base_url}/api/v1/graduation/`+ identity);
  }

}