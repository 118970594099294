import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DashboardService } from '@app/shared/services/dashboard.service';
import Alumno from '@app/shared/types/alumno.interface';

@Component({
    selector: 'dashboardpage',
    templateUrl: './dashboardpage.component.html',
})
export class DashboardPageComponent implements OnInit {
    public alumnosmorosos: Alumno[] = [];
    isLoadingMorosos = false;
    p: number = 1;
    searchText: string;
    countStudent:number;
    countExecutive:number;
    countTeacher:number;
    countCourse:number;
    countStudentFreeze:number;
    countDelinquentStudent:number;

    constructor(private dashboardService: DashboardService,private cdr: ChangeDetectorRef,private router:Router) {}

    ngOnInit(): void {
        this.getcountstudents();
        this.getcountexecutive();
        this.getcountcourses();
        this.getcountstudentfreezes();
        this.getdelinquentstudent();

        this.getcountteacher();
    }

    getcountstudents(){
        this.dashboardService.getcountstudents().subscribe((resp: any) => {
          if(resp.code == 200){
            this.countStudent = resp.data;
          }else{

          }
          this.cdr.detectChanges();
        });
      }

      getcountexecutive(){
        this.dashboardService.getcountexecutive().subscribe((resp: any) => {
          if(resp.code == 200){
            this.countExecutive = resp.data;
          }else{

          }
          this.cdr.detectChanges();
        });
      }


      getcountteacher(){
        this.dashboardService.getcountteacher().subscribe((resp: any) => {
          if(resp.code == 200){
            this.countTeacher = resp.data;
          }else{

          }
          this.cdr.detectChanges();
        });
      }

      getcountcourses(){
        this.dashboardService.getcountcourses().subscribe((resp: any) => {
          if(resp.code == 200){
            this.countCourse = resp.data;
          }else{

          }
          this.cdr.detectChanges();
        });
      }

      getcountstudentfreezes(){
        this.dashboardService.getcountstudentfreezes().subscribe((resp: any) => {
          if(resp.code == 200){
            this.countStudentFreeze = resp.data;
          }else{

          }
          this.cdr.detectChanges();
        });
      }

      getdelinquentstudent(){
        this.isLoadingMorosos = true;
        this.dashboardService.getdelinquentstudent().subscribe((resp: any) => {
          this.isLoadingMorosos = false;
          if(resp.code == 200){
            this.alumnosmorosos = resp.data;
          }else{

          }
          this.cdr.detectChanges();
        });
      }
}
