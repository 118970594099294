import {
  Component,
  ChangeDetectionStrategy,
  ViewEncapsulation,
  OnInit,
  Input,
} from '@angular/core';
import { Router } from '@angular/router';
import { NavMenu } from '@app/shared/types/nav-menu.interface';
import { NavMenuColor } from '@app/shared/types/app-config.interface';
import { MenuService } from '@app/shared/services/menu.service';

@Component({
  selector: 'side-nav',
  templateUrl: './side-nav.component.html',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    '[class.side-nav]': 'true',
    '[class.nav-menu-collapse]': 'collapse',
    '[class.nav-menu-quick-expand]': 'quickExpand',
    '[class.nav-menu-light]': "color === 'light'",
    '[class.nav-menu-dark]': "color === 'dark'",
  },
})
export class SideNavComponent implements OnInit {
  constructor(private router: Router,private menuService:MenuService) {}

  menu: NavMenu[] = [];
  @Input() collapse: boolean;
  @Input() quickExpand: boolean;
  @Input() color: NavMenuColor = 'light';

  ngOnInit(): void {
    let data = JSON.parse(localStorage.getItem('datacli'));
    this.menu = this.menuService.getmenu();
  }
}
