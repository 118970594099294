<section class="demo-section row" *ngIf="!cursoSelected">
    <div class="content-box table-responsive">
        <table class="table">
            <thead>
                <tr>
                    <th scope="col">Acciones</th>
                    <th scope="col">Nombre Curso</th>
                    <th scope="col">Sigla</th>
                    <th scope="col">Tipo Curso</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngIf="isLoading">
                    <td colspan="5">
                        <div class="spinner-border spinner-border-sm" role="status">
                            <span class="sr-only">Loading...</span>
                        </div>
                    </td>
                   
                </tr>
                <tr *ngFor="let item of cursos | paginate: { itemsPerPage: 10, currentPage: p }">
                    <td>
                        <a class="btn m-1 btn-sm btn-info" (click)="seleccionarCurso(item)"><i class="feather icon-user-plus"></i> Ver Material</a>
                       
                    </td>
                    <td>{{ item.name }}</td>
                    <td>{{ item.acronym }}</td>
                    <td>{{ getTypeDescription(item.type) }}</td>
                </tr>
            </tbody>
           
        </table>
        <div class="d-flex justify-content-end">
            <pagination-controls (pageChange)="p = $event" previousLabel="Anterior" maxSize="10" nextLabel="Siguiente"></pagination-controls>
        </div>
   
    </div>
</section>

<section class="demo-section row" *ngIf="cursoSelected">
    <div class="row">
        <div class="col-10 mb-3">
            <h4>{{ cursoSelected.name }}</h4>
          
        </div>
        <div class="col-2">
            <button class="btn btn-outline-warning w-100" type="button" (click)="cerrarCursoselected()">Cerrar <i class="feather icon-x-circle"></i></button>
        </div>
    </div>

    <div class="row col-12">
        <div class="d-flex justify-content-center" *ngIf="isLoadingMaterial">
            <div class="spinner-border" role="status">
                <span class="visually-hidden">Loading...</span>
            </div>
        </div>
        <div class="row row-cols-1 row-cols-md-3 g-4">
            <div *ngIf="!isLoadingMaterial" class="col-12 mt-3 mb-3">
                <h4>Material de apoyo cargado</h4>
            </div>
            <div class="col mb-3"  *ngFor="let material of materialesapoyo">
              <div class="card h-100 text-center bg-light">
                <div class="card-body">
                    <div class="col-md-12 offset-md-4">
                    </div>
                    <h5 class="card-title">{{ material.document_name }}</h5>
                    <p class="card-text">{{ material.document_description }}</p>
                    <a class="btn btn-primary" (click)="descargarmaterial(material.document_url)">Descargar</a>
                </div>
                <div class="card-footer">
                  <small class="text-muted">cargado por {{ material.usermodify }}</small>
                </div>
              </div>
            </div>

        </div>
    </div>

</section>