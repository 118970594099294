import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CursosService } from '@app/shared/services/cursos.service';
import Curso from '@app/shared/types/cursos.interface';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-lista-cursos',
  templateUrl: './lista-cursos.component.html',
  styleUrls: ['./lista-cursos.component.css']
})
export class ListaCursosComponent implements OnInit {
  p:number=1;
  constructor(private cursosService: CursosService,private cdr: ChangeDetectorRef,
    private router: Router) { 

  }
  public isLoading = true;
  public curso : Curso[] =[];
  searchText:string;
  ngOnInit(): void {
    this.getListCurso();
  }

  getListCurso(){
    this.cursosService.getall().subscribe((resp: any) => {
      this.isLoading = false;
      this.curso = resp.data;
      this.cdr.detectChanges();
    });
  }

  getTypeDescription(type: number): string {
    return this.cursosService.getTypeDescription(type).name;
  }

  onSearchTextChanged() {
    this.p = 1;
  }

  editar(item:any){
    if(item.teacher == null){
      item.teacher = "";
    }
    this.cursosService.datosAEditar = item;
    this.router.navigate(['/cursos/admin','editar']);
   }
 
   eliminar(item:any){
     Swal.fire({
       title: 'Eliminar Curso',
       text: '¿Realmente desea eliminar este curso?.',
       icon: 'question',
       showCancelButton: true,
       confirmButtonText: 'Sí',
       denyButtonText: 'No',
     }).then((result) => {
       if (result.isConfirmed) {
         console.log("eliminando item",item.identity);
         this.cursosService.delete(item.identity).subscribe((resp: any) => {
           if(resp.code == 200){
             Swal.fire({
               title: '',
               text: 'Curso eliminado correctamente.',
               icon: 'success',
               confirmButtonText: 'OK'
             }).then((result2) => {
               this.getListCurso();
             });
           }else{
             Swal.fire(
               '',
               'No se pudo eliminar curso, favor comuníquese con el administrador.',
               'error'
               );
           }
           this.cdr.detectChanges();
   
         });
         this.cdr.detectChanges();
       }
     });
   }
}
