<nav class="navbar navbar-light bg-light mb-3">
  <div class="container-fluid">
    <a class="btn btn-success" [routerLink]="['admin','agregar']">
        <i class="feather icon-user-plus"></i> Agregar Gastos</a>
    <form class="d-flex w-50">
      <input class="form-control  me-2" type="search" placeholder="Buscar gasto" [(ngModel)]="searchText" name="filtroNombre" (input)="onSearchTextChanged()" aria-label="Search">
   
    </form>
</div>
</nav>
<section class="demo-section row">
  <div class="col-9">

  </div>
  <div class="col-3">
      <button class="btn btn-success" (click)="exportToExcel()">Exportar a Excel</button>
  </div>
  <div class="content-box table-responsive col-12">
    <table class="table">
      <thead>
        <tr>
          <th scope="col">Acciones</th>
          <th scope="col">Nombre</th>
          <th scope="col">Fecha</th>
          <th scope="col">Monto</th>
          <th scope="col">Observación</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngIf="isLoading">
          <td colspan="5">
            <div class="spinner-border spinner-border-sm" role="status">
              <span class="sr-only">Loading...</span>
            </div>
          </td>
        </tr>
        <tr
          *ngFor="
            let item of listagastos
            | searchFilter : searchText
            | paginate : { itemsPerPage: 10, currentPage: p }
          "
        >
          <td>
            <a class="btn m-1 btn-sm btn-danger" (click)="eliminar(item)">Eliminar</a>
          </td>
          <td>{{ item.name }}</td>
          <td>{{ item.paymentday }}</td>
          <td>{{ item.amount |currency:'CLP':'symbol-narrow':'1.0-0':'es-CL'}}</td>
          <td>{{ item.comments }}</td>
        </tr>
      </tbody>
    </table>
    <!-- <div class="d-flex justify-content-end">
            <pagination-controls (pageChange)="p = $event" previousLabel="Anterior" maxSize="10" nextLabel="Siguiente"></pagination-controls>
        </div> -->
  </div>
</section>
