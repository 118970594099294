<form [formGroup]="formGroupInscripcion">
    <div formArrayName="inscripciones" class="row justify-content-center">
        <div class="col-12" *ngFor="let item of getInscripciones().controls; let i = index" 
        [formGroupName]="i" >
            <div class="row">
                <hr class="mb-3">
                <div class="col-12 col-sm-6"> 
                    <h5>Inscripción {{ getInscripciones().controls[i].value?.paymentday | date: 'dd/MM/yyyy'}}</h5>
                    <div class="row">
                        <div class="col-md-6">
                            <label class="form-label">Editar Fecha</label>
                            <input type="date" class="form-control" formControlName="paymentday" />
                        </div>
                    </div>
                    <p>Curso: {{ getInscripciones().controls[i].value?.name }}</p>
                    <p><input class="form-control" type="number" formControlName="amount" placeholder="75.000"></p>
                </div>
                <div class="col-12 col-sm-4"> 
                    <h5>Obs</h5>
                    <textarea class="form-control" rows="4" formControlName="comments" ></textarea>
                </div>
                <div class="col-12 col-sm-2">
                    <h5>Acciones</h5>
                    <a class="btn btn-warning w-100" (click)="savePagoInscripcion(i)" >Guardar</a>
                </div>
            </div>
        </div>
    </div>
</form>
<form [formGroup]="formGroup" > 
    <div formArrayName="payment_days" class="row justify-content-center">
        <div class="col-12" *ngFor="let item of getPaymentDays().controls; let i = index" 
        [formGroupName]="i" >
            <div class="row">
                <hr class="mb-3">
                <div class="col-12 col-sm-6"> 
                    <h5>{{ getPaymentDays().controls[i].value?.monthnumber }}º Mes - {{ getPaymentDays().controls[i].value?.agreedpaymentday | date: 'dd/MM/yyyy'}}</h5>
                    <div class="row">
                        <div class="col-md-6">
                            <label class="form-label">Editar Fecha</label>
                            <input type="date" class="form-control" formControlName="agreedpaymentday" />
                        </div>
                       
                    </div>
                    
                    <p>Curso: {{ getPaymentDays().controls[i].value?.coursename }}</p>
                    <p><input class="form-control" type="number" formControlName="paymentcredit" placeholder="40.000"></p>
                    <p>Pago completado:  <input class="form-check-input" type="checkbox" (change)="changePagoCompletado(i)" formControlName="paymentcompleted"></p>
                </div>
                <div class="col-12 col-sm-4"> 
                    <h5>Obs</h5>
                    <textarea class="form-control" rows="4" formControlName="comments" ></textarea>
                </div>
                <div class="col-12 col-sm-2">
                    <h5>Acciones</h5>
                    <a class="btn btn-warning w-100" (click)="savePago(i)">Guardar</a>
                </div>
            </div>
        </div>
    </div>

</form>