import { DatePipe } from '@angular/common';
import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AlumnosService } from '@app/shared/services/alumnos.service';
import { CursosService } from '@app/shared/services/cursos.service';
import { GraduacionService } from '@app/shared/services/graduacion.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-agregar-invitado',
  templateUrl: './agregar-invitado.component.html',
  styleUrls: ['./agregar-invitado.component.css']
})
export class AgregarInvitadoComponent implements OnInit {
  formGroup: FormGroup;
  isLoading = false;
  datosEditar:any="";
  datosGraduacion:any="";
  id = "";
  course = "";
  IdFrom = "";
  typeForm = "Agregar";
  alumnos$: any[];
  asientodisponible$: any[];
  constructor(private graduacionService: GraduacionService,
    private alumnoService: AlumnosService,
    private formBuilder: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private cdr: ChangeDetectorRef) { }

  ngOnInit(): void {
    Swal.showLoading();
    this.route.params.subscribe(params => {
      this.id = params['id'];
      this.course = params['course'];
      this.IdFrom = params['type'];
      console.log(this.id,this.course,this.IdFrom);
    });
    this.formGroup = this.formBuilder.group({
      graduation:[this.id,[Validators.required]],
      student:['',[Validators.required]],
      name_attendee:['',[Validators.required]],
      number_seat:['',[Validators.required,Validators.min(0),Validators.pattern(/^\d+$/)]],
      ticket_price:['',[Validators.required,Validators.min(0),Validators.pattern(/^\d+$/)]],
      state:[1,[Validators.required]],
    });
    this.datosEditar = this.graduacionService.datosAEditar;
    this.getAlumnos();

    if(this.IdFrom == "editar"){
      this.typeForm = "Editar";
      this.getAsientosDisponibles(this.datosEditar.number_seat);
      if(this.datosEditar != undefined){
       this.formGroup.patchValue({
        'graduation':this.datosEditar.graduation,
        'student':this.datosEditar.student,
        'name_attendee':this.datosEditar.name_attendee,
        'number_seat':this.datosEditar.number_seat,
        'ticket_price':this.datosEditar.ticket_price,
        'state':this.datosEditar.state,
       });
      }
    }else{
      this.getAsientosDisponibles(0);

      this.typeForm = "Agregar";
    }
    
  }

  getAlumnosold(){
    this.isLoading = true;
    this.alumnoService.getstudentsbycourse(this.course).subscribe((resp: any) => {
      Swal.close();
      let arr =[];
      resp.data.forEach(element => {
        element.student.name = element.student.name + " " + element.student.lastname;
        arr.push(element.student);
      });
      this.alumnos$ = arr;
      this.isLoading = false;
      this.cdr.detectChanges();
    });

  }

  getAlumnos() {
    this.isLoading = true;
    this.cdr.detectChanges();
    this.alumnoService.getall().subscribe((resp: any) => {
      Swal.close();
      console.log(resp);
      this.isLoading = false;
      let arr =[];
      resp.data.forEach(element => {
        element.name = element.name + " " + element.lastname;
        arr.push(element);
      });
      this.alumnos$ = arr;
      this.cdr.detectChanges();
    });
  }

  getAsientosDisponibles(custom:number){
    this.isLoading = true;
    this.graduacionService.getasientosdisponibles(this.id,custom).subscribe((resp: any) => {
      Swal.close();
      let arr =[];
      resp.data.forEach(element => {
        arr.push({id:element, name:element});
      });
      this.asientodisponible$ = arr;
      this.isLoading = false;
      this.cdr.detectChanges();
    });

  }

  save(){
    this.isLoading = true;
    this.cdr.detectChanges();
    if(this.IdFrom == "agregar"){
      this.agregar();
    }else{
      this.editar();
    }
  }

  agregar(){
    if(this.formGroup.valid){
      Swal.showLoading();
      this.isLoading = false;
      this.graduacionService.agregarinvitados(this.formGroup.getRawValue()).subscribe((resp: any) => {
        Swal.close();
        if(resp.code == 200){
          Swal.fire({
            title: '',
            text: 'Datos insertados correctamente.',
            icon: 'success',
            confirmButtonText: 'OK'
          }).then((result) => {
            if (result.isConfirmed) {
              this.router.navigate([`/graduaciones/invitados/${this.course}/${this.id}`]);
            }
          });
        }else{
          Swal.fire(
            '',
            'No se pudo insertar los datos, favor comuníquese con el administrador.',
            'error'
            );
        }
        this.isLoading = false;
        this.cdr.detectChanges();

      });
    }else{
      Swal.fire(
        '',
        'Debe completar los campos correctamente.',
        'error'
        );
        this.isLoading = false;
        this.cdr.detectChanges();
    }
  }

  editar(){
    if(this.formGroup.valid){
      this.graduacionService.editinvitados(this.formGroup.getRawValue(),this.datosEditar.identity).subscribe((resp: any) => {
        if(resp.code == 200){
          Swal.fire({
            title: '',
            text: 'Datos modificados correctamente.',
            icon: 'success',
            confirmButtonText: 'OK'
          }).then((result) => {
            if (result.isConfirmed) {
              this.router.navigate([`/graduaciones/invitados/${this.course}/${this.id}`]);
            }
          });
        }else{
          Swal.fire(
            '',
            'No se pudo insertar los datos, favor comuníquese con el administrador.',
            'error'
            );
        }
        this.isLoading = false;
        this.cdr.detectChanges();

      });
    }else{
      Swal.fire(
        '',
        'Debe completar los campos correctamente.',
        'error'
        );
        this.isLoading = false;
        this.cdr.detectChanges();
    }
  }
}
