import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-graduaciones',
  templateUrl: './graduaciones.component.html',
  styleUrls: ['./graduaciones.component.css']
})
export class GraduacionesComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
