<header-nav 
    layoutType="horizontal"
    [color]="headerNavColor"
    [isMobile]="isMobile"
    class="no-print"
></header-nav>
<header-navbar *ngIf="!isMobile" [color]="navMenuColor"  class="no-print"></header-navbar>
<mobile-nav *ngIf="isMobile" [isOpen]="isMobileNavOpen" [color]="navMenuColor"  class="no-print"></mobile-nav>
<content layoutType="horizontal"  class="no-print"></content>

