<div class="nav-logo no-print">
    <logo 
        [logoType]="!collapse || quickExpand ? 'logo' : 'fold' "
        [height]="!collapse || quickExpand ? 50 : 30"
        [white]= "color === 'dark'"
        class="w-100"
    ></logo>
</div>
<perfect-scrollbar class="side-nav-content no-print">
    <vertical-menu-content></vertical-menu-content>
</perfect-scrollbar>
