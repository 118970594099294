<nav class="navbar navbar-light bg-light mb-3">
  <div class="container-fluid">
    <a class="btn btn-warning" [routerLink]="['/graduaciones/invitados/'+ course + '/' + id]">
      <i class="feather icon-arrow-left"></i> Volver
    </a>
  </div>
</nav>

<section class="demo-section row">
  <div class="content-box col-12">
    <form class="row g-3" [formGroup]="formGroup" (submit)="save()">
      <label class="form-label col-12"></label>
      <input type="hidden" formControlName="graduation" />

      <div class="col-md-4">
        <label class="form-label">Nombre</label>
        <input type="text" class="form-control" formControlName="name_attendee" />
      </div>
      <div class="col-md-4">
        <label class="form-label">Seleccionar Alumno </label>
        <ng-select
          bindLabel="name"
          [searchable]="true"
          placeholder="Seleccione alumno"
          bindValue="identity"
          formControlName="student"
        >
          <ng-option *ngFor="let item of alumnos$" [value]="item.identity">{{
            item.name
          }}</ng-option>
        </ng-select>
      </div>
      <div class="col-md-4">
        <label class="form-label">Asientos disponibles</label>
        <ng-select
        bindLabel="name"
        [searchable]="true"
        placeholder="Seleccione número asiento"
        bindValue="id"
        formControlName="number_seat"
      >
        <ng-option *ngFor="let item of asientodisponible$" [value]="item.id">{{
          item.id
        }}</ng-option>
      </ng-select>
      </div>
      <div class="col-md-4">
        <label class="form-label">Abono</label>
        <input
          type="number"
          class="form-control"
          formControlName="ticket_price"
        />
      </div>
      <div class="col-12 mt-3">
        <button type="submit" class="btn btn-success" [disabled]="isLoading">
          <i class="feather icon-user-plus"></i>
          <div *ngIf="isLoading; then cargandobtn; else btntext"></div>
        </button>
      </div>
    </form>
  </div>
</section>

<ng-template #btntext> {{ typeForm }} Invitado </ng-template>
<ng-template #cargandobtn>
  Cargando
  <div class="spinner-border spinner-border-sm" role="status">
    <span class="sr-only">Loading...</span>
  </div>
</ng-template>
