import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AlumnosService } from '@app/shared/services/alumnos.service';
import Alumno from '@app/shared/types/alumno.interface';
import Swal from 'sweetalert2';
import * as XLSX from 'xlsx';

@Component({
  selector: 'app-lista-alumnos',
  templateUrl: './lista-alumnos.component.html',
  styleUrls: ['./lista-alumnos.component.css'],
})
export class ListaAlumnosComponent implements OnInit {
  public isLoading = true;
  public alumnos: Alumno[] = [];
  public datosAEditar: any;
  public type = "";
  p: number = 1;
  searchText: string;
  constructor(
    private alumnosService: AlumnosService,
    private cdr: ChangeDetectorRef,
    private router: Router,
    private route: ActivatedRoute
  ) {
    
  }

  ngOnInit(): void {
    this.route.params.subscribe(params => {
      this.type = params['filter'];
      this.aplicarfiltroalumnos();
    });
  }
  
  exportToExcel() {
    let listaalumnosExcel=[];
    this.alumnos.forEach(x => {
      listaalumnosExcel.push({
        Rut : x.idcard,
        Pasaporte: x.passport,
        Nombre: x.name,
        Apellido: x.lastname,
        Edad: x.age,
        Telefono: x.phone1,
        Correo: x.email,
        Direccion:x.address,
        Ciudad:x.city,
        Comuna: x.locality,
        Apoderado: x.representativefullname,
        FonoApoderado:x.representativephone,
        CorreoApoderado:x.representativeemail,
        Moroso: x.delinquent == false? 'No':'Si',
        Congelado: x.freezes == false? 'No':'Si',

      });
    });
    const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(listaalumnosExcel);
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Alumnos');
    XLSX.writeFile(wb, 'alumnos.xlsx');
  }

  onSearchTextChanged() {
    this.p = 1;
  }
  aplicarfiltroalumnos(){
    if(this.type == null){
      this.getalumnos();
    }else if(this.type == "morosos"){
      this.getmorosos();
    }else if(this.type == "congelados"){
      this.getcongelados();
    }
  }

  getalumnos() {
    this.isLoading = true;
    this.cdr.detectChanges();

    this.alumnosService.getall().subscribe((resp: any) => {
      this.isLoading = false;
      this.alumnos = resp.data;
      this.cdr.detectChanges();
    });
  }

  getmorosos() {
    this.isLoading = true;
    this.cdr.detectChanges();

    this.alumnosService.getallMorosos().subscribe((resp: any) => {
      this.isLoading = false;
      this.alumnos = resp.data;
      this.cdr.detectChanges();
    });
  }

  getcongelados() {
    this.isLoading = true;
    this.cdr.detectChanges();

    this.alumnosService.getallCongelados().subscribe((resp: any) => {
      this.isLoading = false;
      this.alumnos = resp.data;
      this.cdr.detectChanges();
    });
  }

  editar(formatedRut: any) {
    //this.alumnosService.datosAEditar = item;
    this.router.navigate(['/alumnos/admin/editar'], {
      queryParams: { formatedRut },
    });
  }

  eliminar(item: any) {
    Swal.fire({
      title: 'Eliminar alumno',
      text: '¿Realmente desea eliminar este alumno?.',
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: 'Sí',
      denyButtonText: 'No',
    }).then((result) => {
      if (result.isConfirmed) {
        this.isLoading = true;
        Swal.showLoading();
        this.alumnosService.delete(item.identity).subscribe((resp: any) => {
          Swal.close();
          if (resp.code == 200) {
            Swal.fire({
              title: '',
              text: 'Alumno eliminado correctamente.',
              icon: 'success',
              confirmButtonText: 'OK',
            }).then((result2) => {
              if(this.type == null){
                this.aplicarfiltroalumnos();
              }else if(this.type == "morosos"){
                this.getmorosos();
              }else if(this.type == "congelados"){
                this.getcongelados();
              }
            });
          } else {
            Swal.fire(
              '',
              'No se pudo eliminar alumno, favor comuníquese con el administrador.',
              'error'
            );
          }
          this.cdr.detectChanges();
        });
        this.cdr.detectChanges();
      }
    });
  }
}
