
<nav class="navbar navbar-light bg-light mb-3">
    <div class="container-fluid">
        <a class="btn btn-success" [routerLink]="['admin','agregar']" *ngIf="type != 'morosos'">
            <i class="feather icon-user-plus"></i> Agregar Alumno</a>
        <form class="d-flex w-50">
        <input class="form-control  me-2" type="search" placeholder="Buscar alumnos" [(ngModel)]="searchText" (input)="onSearchTextChanged()" name="filtroNombre" aria-label="Search">
       
        </form>
    </div>
</nav>
<section class="demo-section row">
    <div class="col-9">

    </div>
    <div class="col-3">
        <button class="btn btn-success" (click)="exportToExcel()">Exportar a Excel</button>
    </div>
    <div class="content-box table-responsive col-12">
        <table class="table">
            <thead>
                <tr>
                    <th scope="col">Acciones</th>
                    <th scope="col">Nombre</th>
                    <th scope="col">Rut o Pasaporte</th>
                    <th scope="col">Email</th>
                    <th scope="col">Status</th>
                    <!-- <th scope="col">Apoderado</th>
                    <th scope="col">Fecha Entrevista</th> -->
                </tr>
            </thead>
            <tbody>
                <tr *ngIf="isLoading">
                    <td colspan="7">
                        <div class="spinner-border spinner-border-sm" role="status">
                            <span class="sr-only">Loading...</span>
                        </div>
                    </td>
                   
                </tr>
                <tr *ngFor="let item of alumnos | searchFilter:searchText | paginate: { itemsPerPage: 10, currentPage: p } ">
                    <td>
                        <a class="btn m-1 btn-sm btn-info" (click)="editar(item.idcard)">Editar</a>
                        <a class="btn m-1 btn-sm btn-danger" (click)="eliminar(item)">Eliminar</a>
                        <a *ngIf="false" class="btn m-1 btn-sm btn-success" (click)="pagos(item.identity)">Pagos</a>
                        <a *ngIf="false" class="btn m-1 btn-sm btn-warning" (click)="clases(item.identity)">Clases</a>
                    </td>

                    <td>{{ item.name }} {{ item.lastname }}</td>
                    <td>{{ item.idcard }}</td>
                    <td>{{ item.email }}</td>
                    <td>
                        <div class="btn-group" role="group" aria-label="Basic checkbox toggle button group">
                            <input type="checkbox" class="btn-check" id="congelado" disabled autocomplete="off" [checked]="item.freezes == 1? true:null">
                            <label class="btn btn-outline-primary" for="congelado">Congelado</label>
                        
                            <input type="checkbox" class="btn-check" id="moroso" disabled autocomplete="off" [checked]="item.delinquent == 1? true:null">
                            <label class="btn btn-outline-primary" for="moroso" >Moroso</label>
                      </div>
                    </td>
                    <!-- <td>{{ item.representativefullname }}</td>
                    <td>{{ item.interviewdate }} </td> -->
                   
                </tr>
            </tbody>
        </table>
        <div class="d-flex justify-content-end">
            <pagination-controls (pageChange)="p = $event" previousLabel="Anterior" maxSize="10" nextLabel="Siguiente"></pagination-controls>
        </div>
    </div>
</section>