import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'habilitaAsistencia'
})
export class HabilitaAsistenciaPipe implements PipeTransform {

  transform(fechaconsulta: Date, fechainiciocurso: string, duracion: number,curso:any): boolean {
    let fecini = new Date(fechainiciocurso);
    let fechafin = new Date(fecini);
    fechafin.setMonth(fechafin.getMonth() + duracion);
    if(fechaconsulta >= fecini && fechaconsulta <= fechafin){
      switch (fechaconsulta.getDay()) {
        case 0: //domingo
          return curso.sunday? null:true;
        break;
        case 1:
          return curso.monday? null:true;
        break;
        case 2:
          return curso.tuesday? null:true;
        break;
        case 3:
          return curso.wednesday? null:true;
        break;
        case 4:
          return curso.thursday? null:true;
        break;
        case 5:
          return curso.friday? null:true;
        break;
        case 6:
          return curso.saturday? null:true;
        break;
        default:
          break;
      }
    }
    return true;
  }
}
