<nav class="navbar navbar-light bg-light mb-3">
  <div class="container-fluid">
    <a class="btn btn-warning" [routerLink]="['/alumnos']">
      <i class="feather icon-arrow-left"></i> Volver a la lista de alumnos
    </a>
  </div>
</nav>

<section class="demo-section row">
  <div class="content-box col-12">
    <form class="row g-3" [formGroup]="formGroup" (validSubmit)="saveAlumno()">
      <label class="form-label col-12"><h4>Datos del alumno/a</h4></label>
      <div class="col-md-1" *ngIf="typeForm != 'Editar'">
        <label class="form-label">¿Es pasaporte?</label>
        <input type="checkbox" formControlName="ispassport" >
      </div>
      <div class="col-md-3">
        <label for="idcard" class="form-label">Rut o Pasaporte</label>
        <input
          type="text"
          class="form-control"
          formControlName="idcard"
          (focusout)="validarRut(form.idcard.value)"
        />
      </div>
     
      <div class="col-md-3">
        <label for="name" class="form-label">Nombre</label>
        <input type="text" class="form-control" formControlName="name" />
      </div>

      <div class="col-md-3">
        <label for="lastname" class="form-label">Apellido</label>
        <input type="text" class="form-control" formControlName="lastname" />
      </div>
      <div class="col-md-3">
        <label for="promissorynotenumber" class="form-label">Número de pagaré</label>
        <input type="promissorynotenumber" class="form-control" formControlName="promissorynotenumber" />
      </div>


      <div class="col-md-3">
        <label for="age" class="form-label">Edad</label>
        <input type="number" class="form-control" formControlName="age" />
      </div>

      <div class="col-md-3">
        <label for="passport" class="form-label">Pasaporte</label>
        <input type="text" class="form-control" formControlName="passport" />
      </div>

      <div class="col-md-3">
        <label class="form-label">Sexo</label>
        <select class="form-select" formControlName="gender">
          <option value="1" selected>Femenino</option>
          <option value="2">Masculino</option>
          <option value="3">Otro</option>
        </select>
      </div>

      <div class="col-md-3">
        <label class="form-label">Ejecutivo</label>
        <select class="form-select" formControlName="executive">
          <option *ngFor="let item of ejecutivos" [value]="item.identity">
            {{ item.name }}
          </option>
        </select>
      </div>

      <div class="col-md-4">
        <label class="form-label">Teléfono</label>
        <input type="text" class="form-control" formControlName="phone1" />
      </div>

      <!-- <div class="col-md-3">
                <label class="form-label">Teléfono 2</label>
                <input type="text" class="form-control" formControlName="phone2">
            </div> -->

      <div class="col-md-4">
        <label class="form-label">Email</label>
        <input type="email" class="form-control" formControlName="email" (focusout)="validarEmail(form.email.value)"/>
      </div>

      <div class="col-md-4">
        <label class="form-label">Fecha Entrevista</label>
        <input
          type="date"
          class="form-control"
          formControlName="interviewdate"
        />
      </div>

      <div class="col-4">
        <label class="form-label">Dirección</label>
        <input
          type="text"
          class="form-control"
          placeholder="1234 Main St"
          formControlName="address"
        />
      </div>

      <div class="col-md-4">
        <label class="form-label">Ciudad</label>
        <input type="text" class="form-control" formControlName="city" />
      </div>

      <div class="col-md-4">
        <label class="form-label">Comuna</label>
        <input type="text" class="form-control" formControlName="locality" />
      </div>
      <div class="col-md-4">
        <label class="form-label">Estado Alumno</label><br />
        <div class="btn-group" role="group">
          <input
            type="checkbox"
            class="btn-check"
            id="congelado"
            formControlName="freezes"
            autocomplete="off"
          />
          <label class="btn btn-outline-primary" for="congelado"
            >Congelado</label
          >
          <input
            type="checkbox"
            class="btn-check"
            id="moroso"
            formControlName="delinquent"
            autocomplete="off"
          />
          <label class="btn btn-outline-primary" for="moroso">Moroso</label>
        </div>
      </div>
      <div class="col-md-4" *ngIf="typeForm == 'Editar'">
        <label class="form-label">Comentarios</label><br />
        <button
          type="button"
          class="btn btn-primary w-100"
          (click)="openModalComentarios(templateComentario)"
        >
          Comentarios
        </button>
      </div>
      <div class="col-md-4" *ngIf="typeForm == 'Editar'">
        <label class="form-label">Gastos extras</label><br />
        <button
          type="button"
          class="btn btn-primary w-100"
          (click)="openModalGastos(templateGastos)"
        >
          Gastos
        </button>
      </div>
      <hr class="col-12 mt-5" />
      <label class="col-12 form-label"><h4>Datos del apoderado/a</h4></label>

      <div class="col-6">
        <label class="form-label">Nombre completo</label>
        <input
          type="text"
          class="form-control"
          formControlName="representativefullname"
        />
      </div>

      <div class="col-3">
        <label class="form-label">Teléfono</label>
        <input
          type="text"
          class="form-control"
          formControlName="representativephone"
        />
      </div>

      <div class="col-3">
        <label class="form-label">Email</label>
        <input
          type="email"
          class="form-control"
          formControlName="representativeemail"
        />
      </div>

      <hr class="col-12 mt-5" />
      <div class="row">
        <label class="col-md-12 form-label"><h4>Inscribir Cursos:</h4></label>

        <div class="col-12 col-md-3">
          <label class="form-label">Tipo:</label>
          <select
            class="form-select"
            formControlName="type_course"
            (change)="changeTipoCurso($event.target.value)"
          >
            <option value="1">Principiante</option>
            <option value="2">Avanzado</option>
            <option value="3">Intensivo</option>
            <option value="4">Personalizado</option>
          </select>
        </div>
        <div class="col-12 col-md-3">
          <label class="form-label">Inicio de clases</label>
          <input
            type="date"
            class="form-control"
            formControlName="startCourse"
            (change)="changeStartCourse()"
          />
        </div>

        <div class="col-12 col-md-2">
          <label class="form-label">Meses de duración</label>
          <input
            type="number"
            class="form-control"
            formControlName="duration"
            (change)="changeMesDuracion()"
          />
          <small *ngIf="isLoadingCursos">*Cargando cursos por favor espere.</small>
          <small *ngIf="!isLoadingCursos && mesesMinimosPago > 0">*{{ mesesMinimosPago }} meses existentes.</small>

        </div>
        <div class="col-12 col-md-4">
          <form [formGroup]="formGroupCursos">
            <label class="form-label" *ngIf="typeForm != 'Editar'"
              >Lista de cursos</label
            >
            <label class="form-label" *ngIf="typeForm == 'Editar'"
              >Cursos seleccionados</label
            >

            <ng-select
              [multiple]="true"
              bindLabel="name"
              [searchable]="true"
              placeholder="Seleccione curso"
              bindValue="id"
              formControlName="courses"
              (change)="selectcurso()"
            >
              <ng-option *ngFor="let item of cursos$" [value]="item.identity">{{
                item.name
              }}</ng-option>
            </ng-select>
            <div *ngIf="typeForm == 'Editar'">
              <p *ngFor="let curso of datosCursosEditar">{{ curso.name }}</p>
            </div>
          </form>
        </div>
      </div>

      <div *ngIf="typeForm != 'Editar'">
        <hr class="col-12 mt-5" />
        <label class="col-12 form-label"><h4>Días de pago</h4></label>
        <!-- inicio curso -->
        <div class="col-12 col-md p-3">
          <div class="row mb-3">
            <div class="col-6">
              <label class="form-label">Inscripción</label>
            </div>
            <div class="col-6">
              <input
                type="date"
                class="form-control"
                disabled
                [value]="formGroup.controls.startCourse.value"
              />
            </div>
          </div>
          <div formArrayName="payment_days">
            <div
              class="row mb-3"
              *ngFor="
                let ctrlpayment of getPaymentDays().controls;
                let i = index
              "
              [formGroupName]="i"
            >
              <div class="col-6">
                <label class="form-label">{{ i + 1 }} º Mes</label>
                <p>
                  {{ getPaymentDays().controls[i].controls.coursename?.value }}
                </p>
              </div>
              <div class="col-6">
                <input
                  type="date"
                  class="form-control"
                  formControlName="date"
                />
              </div>
            </div>
          </div>
        </div>
        <!-- Fin del curso -->
      </div>
      <div class="col-12">
        <button type="submit" class="btn btn-success" [disabled]="isLoading">
          <i class="feather icon-user-plus"></i>
          <div *ngIf="isLoading; then cargandobtn; else btntext"></div>
        </button>
      </div>
      <hr />
      <div class="row" *ngIf="typeForm == 'Editar'">
        <div class="col-12">
          
          <div formArrayName="payment_days_new" class="row justify-content-center">
            <h2>Nuevos meses</h2>
            <div
              class="row mb-3"
              *ngFor="
                let ctrlpayment of getPaymentDaysNew().controls;
                let i = index
              "
              [formGroupName]="i"
            >
              <div class="col-6">
                <label class="form-label">Nuevo {{ i + 1 }} º Mes</label>
                <p>
                  {{ getPaymentDaysNew().controls[i].controls.coursename?.value }}
                </p>
              </div>
              <div class="col-6">
                <input
                  type="date"
                  class="form-control"
                  formControlName="date"
                />
              </div>
            </div>
          </div>
          <h4 class="mt-5 mb-3">Pagos</h4>
          <div class="col-10 p-3" *ngIf="isLoadingPagos">
            Cargando Pagos
            <div class="spinner-border spinner-border-sm" role="status">
              <span class="sr-only">Loading...</span>
            </div>
          </div>
          <app-formulario-pagos [formGroup]="formGroup" [inscripcionList]="inscripcionList" [IdStudent]="datosEditar.identity" [RutStudent]="datosEditar.idcard"></app-formulario-pagos>
        
        </div>
      </div>
    </form>
  </div>
</section>
<ng-template #btntext> {{ typeForm }} Alumno </ng-template>
<ng-template #cargandobtn>
  Cargando
  <div class="spinner-border spinner-border-sm" role="status">
    <span class="sr-only">Loading...</span>
  </div>
</ng-template>
<ng-template #templateComentario>
  <div class="modal-header">
    <h4 class="modal-title pull-left">Comentarios</h4>
    <button
      type="button"
      class="close pull-right"
      aria-label="Close"
      (click)="modalRefComentarios.hide()"
    >
      <span aria-hidden="true">×</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="row">
      <form [formGroup]="formComentario" (validSubmit)="agregarcomentario()">
        
        <div class="col-12">
          <label class="form-label">
            Agregar comentario
          </label>
          <input
            type="text"
            class="form-control"
            formControlName="comment"
            placeholder="Ingrese un comentario"
          />
        </div>
        <div class="col-12">
          <button type="submit" class="btn btn-success mt-5" [disabled]="isLoadingComentarios">
            <i class="feather icon-user-plus"></i>
            Agregar nuevo comentario
          </button>
        </div>
      </form>
    </div>
    <div class="row">
      <div class="col-10 p-3" *ngIf="isLoadingComentarios">
        Cargando comentarios
        <div class="spinner-border spinner-border-sm" role="status">
          <span class="sr-only">Loading...</span>
        </div>
      </div>
      <div *ngFor="let item of comentariosList">
        <br>
        <hr>
        <p>
          {{ item.comment}}
         </p><small>{{ item.date | date: 'yyyy-MM-dd'  }} {{item.usermodify}}</small>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-danger" (click)="modalRefComentarios.hide()">
      Cerrar
    </button>
  </div>
</ng-template>

<ng-template #templateGastos>
  <div class="modal-header">
    <h4 class="modal-title pull-left">Gastos</h4>
    <button
      type="button"
      class="close pull-right"
      aria-label="Close"
      (click)="modalRefGastos.hide()"
    >
      <span aria-hidden="true">×</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="row">
      <form [formGroup]="formGastos" (validSubmit)="agregargasto()">
        
        <div class="col-12">
          <label class="form-label">
            Tipo de gasto
          </label>
          <ng-select
              [multiple]="false"
              [searchable]="false"
              [clearable]="false"
              placeholder="Seleccione tipo gasto"
              formControlName="tipogasto"
              bindValue="id"
              bindLabel="name"
              (change)="changeTipoGasto($event)"

            >
              <ng-option *ngFor="let item of tipoGasto$" [value]="item.id">{{
                item.name
              }}</ng-option>
            </ng-select>
          <label class="form-label">
            Nombre
          </label>
          <input
            type="text"
            class="form-control"
            formControlName="name"
          />
          <label class="form-label">
            Monto
          </label>
          <input
            type="number"
            class="form-control"
            formControlName="amount"
          />
          <label class="form-label">
            Comentario
          </label>
          <input
            type="text"
            class="form-control"
            formControlName="comments"
            placeholder="Ingrese un comentario"
          />
        </div>
        
        <div class="col-12">
          <button type="submit" class="btn btn-success mt-5" [disabled]="isLoadingGastos">
            <i class="feather icon-user-plus"></i>
            Agregar gasto
          </button>
        </div>
      </form>
    </div>
    <div class="row">
      <div class="col-10 p-3" *ngIf="isLoadingGastos">
        Cargando Gastos
        <div class="spinner-border spinner-border-sm" role="status">
          <span class="sr-only">Loading...</span>
        </div>
      </div>
      <form [formGroup]="formGastosList" > 
        <div formArrayName="gastos" class="row justify-content-center">
            <div class="col-12" *ngFor="let item of getGastosForm().controls; let i = index" 
            [formGroupName]="i" >
                <div class="row">
                    <hr class="mb-3">
                    <div class="col-12 col-sm-6"> 
                        <h5>{{ getGastosForm().controls[i].value?.name }}</h5>
                        <p>Monto <input class="form-control" type="number" formControlName="amount"></p>
                        <p>Fecha <input class="form-control" type="date" formControlName="paymentday"></p>
                    </div>
                    <div class="col-12 col-sm-4"> 
                        <h5>Obs</h5>
                        <textarea class="form-control" rows="4" formControlName="comments" ></textarea>
                    </div>
                    <div class="col-12 col-sm-2">
                        <h5>Acciones</h5>
                        <a class="btn btn-warning w-100" (click)="saveGasto(i)">Guardar</a>
                        <a class="btn btn-danger w-100 mt-2" (click)="eliminarGasto(getGastosForm().controls[i].value?.identity)">Eliminar</a>
                    </div>
                </div>
            </div>
        </div>
    
      </form>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-danger" (click)="modalRefGastos.hide()">
      Cerrar
    </button>
  </div>
</ng-template>
