import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import Alumno from '../types/alumno.interface';
const base_url = environment.base_url;
@Injectable({
  providedIn: 'root'
})
export class AlumnosService {
  datosAEditar:any;

  constructor(private http: HttpClient) {
  }

  public getall(){
    return this.http.get(`${base_url}/api/v1/students`);
  }
  
  public addPaymentDays(data:any,id:string){
    return this.http.post(`${base_url}/api/v1/students/addpaymentdays/${id}`,data);
  }

  public getcommentsbystudent(id:string){
    return this.http.get(`${base_url}/api/v1/studentcomment/getcommentsbystudent/${id}`);
  }
  public getgastosbystudent(id:string){
    return this.http.get(`${base_url}/api/v1/studentcomment/getcommentsbystudent/${id}`);
  }
  public addcommentsbystudent(data:any){
    return this.http.post(`${base_url}/api/v1/studentcomment`,data);
  }

  public agregargastoxalumno(data:any){
    return this.http.post(`${base_url}/api/v1/expenses`,data);
  }

  public getGastosxalumno(id:string){
    return this.http.get(`${base_url}/api/v1/expenses/studentlist/${id}`);
  }

  public getinscripcionxalumno(id:string){
    return this.http.get(`${base_url}/api/v1/expenses/studentinscription/${id}`);
  }
  
  public editGasto(data: any,identity:string){
    return this.http.put(`${base_url}/api/v1/expenses/`+ identity,data);
  }

  public deleteGasto(id:string){
    return this.http.delete(`${base_url}/api/v1/expenses/${id}`);
  }
  public getallMorosos(){
    return this.http.get(`${base_url}/api/v1/dashboard/getdelinquentstudent`);
  }

  public getallCongelados(){
    return this.http.get(`${base_url}/api/v1/dashboard/getfreezesstudent`);
  }

  public checkRut(rut:string){
    return this.http.get(`${base_url}/api/v1/students/checkStudentByRut/${rut}`);
  }

  public checkEmail(email:string){
    return this.http.post(`${base_url}/api/v1/students/checkEmail/${email}`,{});
  }

  public getstudentbyrut(rut:string){
    return this.http.get(`${base_url}/api/v1/students/showByRut/${rut}`);
  }

  public getstudentsbycourse(course:string){
    return this.http.get(`${base_url}/api/v1/students/getstudentsbycourseid/${course}`);
  }

  public create(data:any){
    return this.http.post(`${base_url}/api/v1/students`,data);

  }
  public edit(data: any,identity:string){
    return this.http.put(`${base_url}/api/v1/students/`+ identity,data);
  }

  public delete(identity:string){
    return this.http.delete(`${base_url}/api/v1/students/`+ identity);
  }

}