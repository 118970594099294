<nav class="navbar navbar-light bg-light mb-3">
    <div class="container-fluid">
        <a class="btn btn-warning" [routerLink]="['/profesores']">
            <i class="feather icon-arrow-left"></i> Volver a la lista de profesores
        </a>
    </div>
</nav>

<section class="demo-section row">
    <div class="content-box col-12">
        <form class="row g-3" [formGroup]="formGroup" (validSubmit)="save()">
            <label class="form-label col-12">Ingresar datos del profesor</label>
            <div class="col-md-4">
                <label class="form-label">Nombre del profesor</label>
                <input type="text" class="form-control" formControlName="name">
            </div>
            <div class="col-md-4">
                <label class="form-label">Teléfono</label>
                <input type="text" class="form-control" placeholder="+569 8888888" formControlName="phone">
            </div>
            <div class="col-md-4">
                <label class="form-label">Email</label>
                <input type="email" class="form-control" placeholder="hola@profesor.cl" formControlName="email">
            </div>
            <!-- <div class="col-md-4">
                <label class="form-label">Perfil</label>
                <select class="form-select" formControlName="profile">
                    <option value="1">Administrador</option>
                    <option value="2">Usuario</option>
                </select>
            </div> -->
            <input type="hidden" class="form-control" formControlName="type">
            <div class="col-12 mt-3">
                <button type="submit" class="btn btn-primary" [disabled]="isLoading"><i class="feather icon-check-circle"></i> 
                    {{typeForm}} Profesor
                    <div *ngIf="isLoading">
                        <div class="spinner-border spinner-border-sm" role="status">
                            <span class="sr-only">Loading...</span>
                        </div>
                    </div>
                </button>
            </div>
        </form>
    </div>
</section>