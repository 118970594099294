import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CursosService } from '@app/shared/services/cursos.service';
import { NgxFileDropEntry, FileSystemFileEntry, FileSystemDirectoryEntry } from 'ngx-file-drop';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-material-curso',
  templateUrl: './material-curso.component.html',
  styleUrls: ['./material-curso.component.css']
})
export class MaterialCursoComponent implements OnInit {

  constructor(private cursosService: CursosService,private formBuilder: FormBuilder,private cdr: ChangeDetectorRef) { }
  formGroup: FormGroup;
  p:number=1;
  searchText: string;
  cursoSelected :any;
  idCourseSelected:string="";
  isLoading = false;
  isLoadingMaterial = false;

  cursos: any[]=[];
  materialesapoyo: any[]=[];

  ngOnInit(): void {
    this.formGroup = this.formBuilder.group({
      name:['',[Validators.required,Validators.maxLength(100)]],
      description:['',[Validators.required]],
    });
   this.getListCurso(); 
  }
  public files: NgxFileDropEntry[] = [];
  
  onSearchTextChanged() {
    this.p = 1;
  }
  getListCurso(){
    this.isLoading = true;
    this.cursosService.getall().subscribe((resp: any) => {
      this.isLoading = false;
      this.cursos = resp.data;
      this.cdr.detectChanges();
    });
  }

  seleccionarCurso(item:any){
    this.cursoSelected = item;
    this.idCourseSelected = item.identity;
    
    this.obtenerDoctosCursos();
  }

  obtenerDoctosCursos(){
    this.isLoadingMaterial = true;
    this.materialesapoyo = [];
    this.cdr.detectChanges();

    this.cursosService.studymateriaGetMaterial(this.idCourseSelected).subscribe((resp: any) => {
      this.isLoadingMaterial = false;
      if(resp.code=200){
        this.materialesapoyo = resp.data;
      }
      this.cdr.detectChanges();
    });
  }
  descargarmaterial(filename){
    Swal.showLoading();
    this.cursosService.studymateriaViewDocto(filename).subscribe((resp: any) => {
      const blob = new Blob([resp], { type: 'application/octet-stream' });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = filename;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      window.URL.revokeObjectURL(url);
      Swal.close();

    },error=>{
      Swal.close();

      console.log("ERROR",error);
    });
  }
  cerrarCursoselected(){
    this.cursoSelected= null;
    this.idCourseSelected = '';
    
  }

  eliminarMaterial(material:string){
    Swal.fire({
      title: 'Eliminar Material',
      text: '¿Realmente desea eliminar este documento?.',
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: 'Sí',
      denyButtonText: 'No',
    }).then((result) => {
      Swal.showLoading();

      if (result.isConfirmed) {
        console.log("eliminando item",material);
        this.cursosService.deleteMaterial(material).subscribe((resp: any) => {
          if(resp.code == 200){
            Swal.fire({
              title: '',
              text: 'Documento eliminado correctamente.',
              icon: 'success',
              confirmButtonText: 'OK'
            }).then((result2) => {
              this.obtenerDoctosCursos();
            });
          }else{
            Swal.fire(
              '',
              'No se pudo eliminar documento, favor comuníquese con el administrador.',
              'error'
              );
          }
          this.cdr.detectChanges();
  
        });
        this.cdr.detectChanges();
      }
    });
  }

  getTypeDescription(type: number): string {
    return this.cursosService.getTypeDescription(type).name;
  }

  public dropped(files: NgxFileDropEntry[]) {
    if(this.formGroup.valid){
      this.files = files;
      for (const droppedFile of files) {
        // Is it a file?
        if (droppedFile.fileEntry.isFile) {
          const fileEntry = droppedFile.fileEntry as FileSystemFileEntry;
          fileEntry.file((file: File) => {
            Swal.showLoading();
            const fileExtension = file.name.split('.').pop();  
            const formData = new FormData();
            formData.append('file', file, droppedFile.relativePath);
            this.cursosService.uploadmaterial(formData).subscribe((resp: any) => {
              if(resp.code == 200){
                //resp.data
                let objnew = {
                  'course':this.idCourseSelected,
                  'type':1,
                  'document_name':this.formGroup.controls.name.value,
                  'document_description':this.formGroup.controls.description.value,
                  'document_url': resp.data,
                  'document_filename': droppedFile.relativePath,
                  'document_extension': fileExtension
                };
                this.cursosService.studymateriaCreate(objnew).subscribe((resp2: any) => {
                  Swal.close();
                  if(resp2.code == 200){
                    Swal.fire({
                      title: '',
                      text: `Material ${droppedFile.relativePath} cargado correctamente.`,
                      icon: 'success',
                      confirmButtonText: 'OK'
                    })
                    this.obtenerDoctosCursos();
                  }else{
                    Swal.fire(
                      '',
                      'No se pudo añadir este archivo al curso: ' + droppedFile.relativePath,
                      'error'
                      );
                      this.cdr.detectChanges();
                  }
                });
                  
              }else{
                Swal.fire(
                  '',
                  'No se pudo subir este archivo, intente nuevamente.',
                  'error'
                  );
                  this.cdr.detectChanges();
              }
            },error => {
              Swal.fire(
                '',
                'ERROR: No se pudo subir este archivo, intente nuevamente.',
                'error'
                );
                this.cdr.detectChanges();  
            });
  
          });
        } else {
          // It was a directory (empty directories are added, otherwise only files)
          const fileEntry = droppedFile.fileEntry as FileSystemDirectoryEntry;
          console.log(droppedFile.relativePath, fileEntry);
        }
      }
    }else{
      Swal.fire(
        '',
        'Debe escribir un nombre y una descripción para cargar estos documentos.',
        'error'
        );
        this.cdr.detectChanges();
      
    }
   
  }

  public fileOver(event){
    console.log(event);
  }

  public fileLeave(event){
    console.log(event);
  }
}
