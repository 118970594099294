import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { PathLocationStrategy, LocationStrategy } from '@angular/common';

import { AppRoutingModule } from './app-routing.module';
import { LayoutModule } from './layout/layout.module';
import { SharedModule } from './shared/shared.module';
import { NgxsModule } from '@ngxs/store';
import { NgxsReduxDevtoolsPluginModule } from '@ngxs/devtools-plugin';
import { NgxsLoggerPluginModule } from '@ngxs/logger-plugin';
import { AppConfigState } from './store/app-config/app-config.state';
import { TranslateModule } from '@ngx-translate/core';
import { AppComponent } from './app.component';
import { PagosComponent } from './views/pagos/pagos.component';
import { AlumnosComponent } from './views/alumnos/alumnos.component';
import { AsistenciaComponent } from './views/asistencia/asistencia.component';
import { GraduacionesComponent } from './views/graduaciones/graduaciones.component';
import { CursosComponent } from './views/cursos/cursos.component';
import { EjecutivosComponent } from './views/ejecutivos/ejecutivos.component';
import { AgregarAlumnoComponent } from './views/alumnos/agregar-alumno/agregar-alumno.component';
import { ListaAlumnosComponent } from './views/alumnos/lista-alumnos/lista-alumnos.component';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { JwtInterceptor } from './shared/interceptor/token.interceptor';
import { NgBootstrapFormValidationModule } from 'ng-bootstrap-form-validation';
import { MisGraduacionesComponent } from './views/graduaciones/mis-graduaciones/mis-graduaciones.component';
import { ListaCursosComponent } from './views/cursos/lista-cursos/lista-cursos.component';
import { AgregarGraduacionComponent } from './views/graduaciones/agregar-graduacion/agregar-graduacion.component';
import { MisCursosComponent } from './views/cursos/mis-cursos/mis-cursos.component';
import { ProfesoresComponent } from './views/profesores/profesores.component';
import { ListaProfesoresComponent } from './views/profesores/lista-profesores/lista-profesores.component';
import { AgregarProfesoresComponent } from './views/profesores/agregar-profesores/agregar-profesores.component';
import { ListaEjecutivosComponent } from './views/ejecutivos/lista-ejecutivos/lista-ejecutivos.component';
import { AgregarEjecutivosComponent } from './views/ejecutivos/agregar-ejecutivos/agregar-ejecutivos.component';
import { ListaGraduacionesComponent } from './views/graduaciones/lista-graduaciones/lista-graduaciones.component';
import { AgregarCursosComponent } from './views/cursos/agregar-cursos/agregar-cursos.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { DiaSemanaPipe } from './dia-semana.pipe';
import { HabilitaAsistenciaPipe } from './habilita-asistencia.pipe';
import { MarcaAsistenciaPipe } from './marca-asistencia.pipe';
import localeEs from '@angular/common/locales/es-CL';
import { registerLocaleData } from '@angular/common';

// import mockServer from './mock-data/app.mock';
import { NgxPaginationModule } from 'ngx-pagination';
import { MaterialCursoComponent } from './views/cursos/material-curso/material-curso.component';
import { NgxFileDropModule } from 'ngx-file-drop';
import { GastosComponent } from './views/gastos/gastos.component';
import { ListaGastosComponent } from './views/gastos/lista-gastos/lista-gastos.component';
import { AgregarGastosComponent } from './views/gastos/agregar-gastos/agregar-gastos.component';
import { SearchFilterPipe } from './search-filter.pipe';
import { AsignarGraduacionesComponent } from './views/graduaciones/asignar-graduaciones/asignar-graduaciones.component';
import { FormularioPagosComponent } from './views/pagos/formulario/formulario-pagos.component';
import { ListaInvitadosComponent } from './views/graduaciones/lista-invitados/lista-invitados.component';
import { AgregarInvitadoComponent } from './views/graduaciones/invitados/agregar-invitado/agregar-invitado.component';
import { UsuariosComponent } from './views/usuarios/usuarios.component';
import { ListUsuariosComponent } from './views/usuarios/lista-usuarios/list-usuarios.component';
import { FormularioUsuariosComponent } from './views/usuarios/formulario/formulario-usuarios.component';
import { DashboardComponent } from './views/dashboard/dashboard.component';
import { DashboardPageComponent } from './views/dashboard/page/dashboardpage.component';
import { ModalModule, BsModalService } from 'ngx-bootstrap/modal';

// mockServer();
registerLocaleData(localeEs);

@NgModule({
    declarations: [
        AppComponent,
        PagosComponent,
        FormularioPagosComponent,
        AlumnosComponent,
        AsistenciaComponent,
        GraduacionesComponent,
        CursosComponent,
        EjecutivosComponent,
        AgregarAlumnoComponent,
        ListaAlumnosComponent,
        MisGraduacionesComponent,
        ListaCursosComponent,
        AgregarGraduacionComponent,
        AgregarInvitadoComponent,
        UsuariosComponent,
        FormularioUsuariosComponent,
        ListUsuariosComponent,
        MisCursosComponent,
        ProfesoresComponent,
        ListaProfesoresComponent,
        AgregarProfesoresComponent,
        ListaEjecutivosComponent,
        AgregarEjecutivosComponent,
        ListaGraduacionesComponent,
        ListaInvitadosComponent,
        AgregarCursosComponent,
        DiaSemanaPipe,
        HabilitaAsistenciaPipe,
        MarcaAsistenciaPipe,
        MaterialCursoComponent,
        GastosComponent,
        ListaGastosComponent,
        AgregarGastosComponent,
        SearchFilterPipe,
        AsignarGraduacionesComponent,
        DashboardComponent,
        DashboardPageComponent,
    ],
    imports: [
        NgSelectModule,
        ModalModule,
        BrowserModule,
        BrowserAnimationsModule,
        AppRoutingModule,
        SharedModule,
        TranslateModule.forRoot(),
        LayoutModule,
        NgxPaginationModule,
        NgxsModule.forRoot([
            AppConfigState
        ]),
        NgxsReduxDevtoolsPluginModule.forRoot(),
        NgxsLoggerPluginModule.forRoot(),
        NgBootstrapFormValidationModule.forRoot(),
        NgxFileDropModule,
    ],
    providers: [
        BsModalService,
        {
            provide: HTTP_INTERCEPTORS, 
            useClass: JwtInterceptor,
            multi: true
        }
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }
