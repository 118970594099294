import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree,Router } from '@angular/router';
import { Observable } from 'rxjs';
import { HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  private readonly TOKEN_NAME = 'mil_auth';
  private headers: HttpHeaders;
  constructor(private router: Router) {
    this.headers = new HttpHeaders()
    .set('Content-Type', 'application/json')
    .set('Access-Control-Allow-Origin', '*');
    setInterval(function(){ 
      let data = JSON.parse(localStorage.getItem('datacli'));
      let pasa = false;
      const expiret = localStorage.getItem('expire');
      if(data != null && expiret != null){
        let expirein = new Date(expiret);
        pasa = (expirein > new Date());
        if(!pasa){
          window.location.href = "/login";
        }
      }
     }, 5000);
  }

  redirect(flag:boolean):any{
    if(!flag){
      this.router.navigate(['','login']);
    }
  }

  // canActivate(
  //   route: ActivatedRouteSnapshot,
  //   state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
  //     return this.evaluaLogin();
  // }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    let isAuthorized = true;
    let data = JSON.parse(localStorage.getItem('datacli'));
    if(route.data.role != null){
      isAuthorized = route.data.role.includes(data?.role);
      if (!isAuthorized) {
        // redirect
        // display a message
        window.alert('No autorizado');
        this.router.navigate(['','login']);

      }
    }
    
    return isAuthorized;    
}

  evaluaLogin(){
    let pasa = false;
    let data = JSON.parse(localStorage.getItem(this.TOKEN_NAME));
    const expiret = localStorage.getItem('expire');
    if(data != null && expiret != null){
      let expirein = new Date(expiret);
      pasa = (expirein > new Date());
    }
    this.redirect(pasa);
    return pasa;
  }
}