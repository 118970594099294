import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { GraduacionService } from '@app/shared/services/graduacion.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-lista-invitados',
  templateUrl: './lista-invitados.component.html',
  styleUrls: ['./lista-invitados.component.css'],
})
export class ListaInvitadosComponent implements OnInit {
  isLoading = false;
  public lista: any[] = [];
  searchText: string;
  id:string;
  course:string;
  p: number = 1;
  datosGraduacion:any="";

  constructor(
    private graduacionService: GraduacionService,
    private cdr: ChangeDetectorRef,
    private router: Router,
    private route: ActivatedRoute,

  ) {}

  ngOnInit(): void {
    this.route.params.subscribe(params => {
      this.id = params['id'];
      this.course = params['course'];
      this.getList(this.id);
    });
    this.datosGraduacion = this.graduacionService.datosGraduacionInvitado;
  }

  getList(id:string) {
    this.lista = [];
    this.isLoading = true;
    this.cdr.detectChanges();
    this.graduacionService.getinvitados(id).subscribe((resp: any) => {
      this.isLoading = false;
      this.lista = resp.data;
      this.cdr.detectChanges();
    });
  }

  agregarinvitado(item:any){
    this.router.navigate([`/graduaciones/invitados/admin/${this.course}/${item}/agregar`]);
  }

  editar(item: any) {
    this.graduacionService.datosAEditar = item;
    this.router.navigate([`/graduaciones/invitados/admin/${this.course}/${this.id}/editar`]);
  }

 
  eliminar(item: any) {
    Swal.fire({
      title: 'Eliminar invitado',
      text: '¿Realmente desea eliminar este invitado?.',
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: 'Sí',
      denyButtonText: 'No',
    }).then((result) => {
      if (result.isConfirmed) {
        this.graduacionService.deleteinvitados(item.identity).subscribe((resp: any) => {
          if (resp.code == 200) {
            Swal.fire({
              title: '',
              text: 'Invitado eliminada correctamente.',
              icon: 'success',
              confirmButtonText: 'OK',
            }).then((result2) => {
              this.getList(this.id);
            });
          } else {
            Swal.fire(
              '',
              'No se pudo eliminar invitado, favor comuníquese con el administrador.',
              'error'
            );
          }
          this.cdr.detectChanges();
        });
        this.cdr.detectChanges();
      }
    });
  }

  imprimir() {
    window.print();
  }
}
