import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Subject } from 'rxjs';

const base_url = environment.base_url;
@Injectable({
  providedIn: 'root'
})
export class PagosService {
  datosAEditar:any;
  constructor(private http: HttpClient) {
  }

  public getPagosAlumno(id:any){
    return this.http.get(`${base_url}/api/v1/studentspayments/getpaymentsbystudent/${id}`);
  }

  public editPago(request:any){
    return this.http.put(`${base_url}/api/v1/studentspayments/${request.identity}`,request);
  }

}