import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { GraduacionService } from '@app/shared/services/graduacion.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-lista-graduaciones',
  templateUrl: './lista-graduaciones.component.html',
  styleUrls: ['./lista-graduaciones.component.css']
})
export class ListaGraduacionesComponent implements OnInit {
  isLoading = false;
  public lista : any[] =[];
  searchText:string;

  p:number=1;
  constructor(private graduacionService: GraduacionService,private cdr: ChangeDetectorRef,
    private router: Router) { }

  ngOnInit(): void {
    this.getList();
  }
  
  onSearchTextChanged() {
    this.p = 1;
  }
  
  getList(){
    this.lista =[];
    this.isLoading = true;
    this.cdr.detectChanges();

    this.graduacionService.getall().subscribe((resp: any) => {
      this.isLoading = false;
      this.lista = resp.data;
      this.cdr.detectChanges();
    });
  }

  verinvitados(item: any) {
    this.graduacionService.datosGraduacionInvitado = item;
    this.router.navigate([`/graduaciones/invitados/${item.course}/${item.identity}`]);
  }
  
  editar(item:any){
    this.graduacionService.datosAEditar = item;
    this.router.navigate(['/graduaciones/admin','editar']);
   }

  eliminar(item:any){
    Swal.fire({
      title: 'Eliminar Graduación',
      text: '¿Realmente desea eliminar esta graduación?.',
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: 'Sí',
      denyButtonText: 'No',
    }).then((result) => {
      if (result.isConfirmed) {
        console.log("eliminando item",item.identity);
        this.graduacionService.delete(item.identity).subscribe((resp: any) => {
          if(resp.code == 200){
            Swal.fire({
              title: '',
              text: 'Graduación eliminada correctamente.',
              icon: 'success',
              confirmButtonText: 'OK'
            }).then((result2) => {
              this.getList();
            });
          }else{
            Swal.fire(
              '',
              'No se pudo eliminar graduación, favor comuníquese con el administrador.',
              'error'
              );
          }
          this.cdr.detectChanges();
  
        });
        this.cdr.detectChanges();
      }
    });
  }
}
