import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import Curso from '../types/cursos.interface';
import Floor from '../types/floor.interface';
import TypeCourse from '../types/typecourse.interface';
const base_url = environment.base_url;
@Injectable({
  providedIn: 'root'
})
export class CursosService {
  datosAEditar:any;
  constructor(private http: HttpClient) {
  }

  public getTypeDescription(type: number): TypeCourse {
    switch (type) {
      case 1:
        return { id: 1, code:"PRN", name: "Normal"};
      case 2:
        return { id: 2, code:"PRS", name:"Personalizado"};
      default:
        return { id: 0, code:"DSC", name:"Desconocido"};
    }
  }

  
  public getPiso(type: number): Floor {
    switch (type) {
      case 1:
        return { id:1, name:"Piso 9", code:"P9" };
      case 2:
        return { id:2,  name:"Piso 11",code:"P11" };
      default:
        return { id:0,  name:"desconocido", code:"" };
    }
  }

  public getall(){
    return this.http.get(`${base_url}/api/v1/courses`);
  }

  public getbystudent(id){
    return this.http.get(`${base_url}/api/v1/courses/getbystudent/${id}`);
  }
  
  public getbystudentformaterial(id){
    return this.http.get(`${base_url}/api/v1/courses/getbystudentformaterial/${id}`);
  }

  public studymateriaGetMaterial(id: any){
    return this.http.get(`${base_url}/api/v1/studymaterial/getmaterialbycourse/${id}`);
  }

  public studymateriaCreate(obj: any){
    return this.http.post(`${base_url}/api/v1/studymaterial`,obj);
  }

  public uploadmaterial(obj: any){
    //return this.http.post(`${base_url}/api/v1/studymaterial/uploadmaterial`,obj,{responseType:'blob'});
    return this.http.post(`${base_url}/api/v1/studymaterial/uploadmaterial`,obj);

  }

  public studymateriaViewDocto(id: any){
    return this.http.get(`${base_url}/api/v1/studymaterial/view/${id}`,{responseType:'blob'});
  }

  
  public deleteMaterial(identity:string){
    return this.http.delete(`${base_url}/api/v1/studymaterial/`+ identity);
  }


  public create(obj: Curso){
    return this.http.post(`${base_url}/api/v1/courses`,obj);

  }

  public edit(obj: Curso,identity:string){
    return this.http.put(`${base_url}/api/v1/courses/`+ identity,obj);
  }

  public delete(identity:string){
    return this.http.delete(`${base_url}/api/v1/courses/`+ identity);
  }


}