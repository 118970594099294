import { DatePipe } from '@angular/common';
import { ChangeDetectorRef,TemplateRef, Component, OnInit } from '@angular/core';
import { AbstractControlOptions, FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AlumnosService } from '@app/shared/services/alumnos.service';
import { CursosService } from '@app/shared/services/cursos.service';
import { EjecutivosService } from '@app/shared/services/ejecutivos.service';
import { PagosService } from '@app/shared/services/pagos.service';
import Curso from '@app/shared/types/cursos.interface';
import Swal from 'sweetalert2';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-agregar-alumno',
  templateUrl: './agregar-alumno.component.html',
  styleUrls: ['./agregar-alumno.component.css']
})
export class AgregarAlumnoComponent implements OnInit {
  isLoadingPagos = false;
  isLoadingComentarios = false;
  isLoadingGastos = false;
  isLoadingCursos = false;
  isLoadingInscripcion = false;
  desactivaNameGasto = true;
  fechainiciocurso:Date = new Date();
  formGroupPagos: FormGroup;
  formNewGroupPagos: FormGroup;
  cursos$: any[];
  tipoGasto$: any[]=[
    { id:"1", name:"Inscripción" },
    { id:"2", name:"Book Fotográfico Milano" },
    { id:"3", name:"Certamen" },
    { id:"4", name:"Otros" }
  ];
  multiple_curso:boolean = false;
  selectedCursos = [];
  formGroup: FormGroup;
  formGroupCursos: FormGroup;
  formAgregarMeses:FormGroup;
  formComentario:FormGroup;
  formGastos:FormGroup;
  formGastosList:FormGroup;

  comentariosList:any[];
  gastosList:any[];
  inscripcionList:any[];

  cursosFiltrados:any[];
  isLoading = true;
  datosEditar:any="";
  datosCursosEditar:any=[];
  IdFrom = "";
  filterRut = "";
  identityAlumno = "";
  mesesMinimosPago = 0;
  typeForm = "Agregar";
  curso:any;
  public ejecutivos : any[] =[];
  modalRef: BsModalRef;
  modalRefComentarios: BsModalRef;
  modalRefGastos: BsModalRef;

  constructor(private formBuilder: FormBuilder,private router:Router,private alumnosService: AlumnosService,private cdr: ChangeDetectorRef,
    private route: ActivatedRoute,private cursosService: CursosService,private ejecutivosService: EjecutivosService,private pagosService: PagosService,private modalService: BsModalService) { }

  ngOnInit(): void {
    this.route.params.subscribe(params => {
      this.IdFrom = params['id'];
    });
    this.route.queryParams.subscribe(params => {
      this.filterRut = params['formatedRut'] || '';
    });
    this.formGroup = this.formBuilder.group({
      ispassport:[''],
      name:['',[Validators.required,Validators.maxLength(500)]],
      lastname:['',[Validators.required,Validators.maxLength(500)]],
      promissorynotenumber:['',[Validators.required,Validators.maxLength(500)]],
      age:[''],
      idcard:['',[Validators.required,Validators.maxLength(50)]],
      passport:['',[Validators.maxLength(500)]],
      gender:['1',Validators.required],
      executive:[''],
      phone1:['',[Validators.required,Validators.maxLength(50)]],
      email:['',[Validators.required,Validators.maxLength(100),Validators.email]],
      interviewdate:['',[Validators.required, Validators.pattern(/^\d{4}\-(0[1-9]|1[012])\-(0[1-9]|[12][0-9]|3[01])$/)]],
      address:['',[Validators.maxLength(255)]],
      city:['',[Validators.maxLength(255)]],
      locality:['',[Validators.maxLength(255)]],
      representativefullname:['',[Validators.maxLength(500)]],
      representativephone:['',[Validators.maxLength(50)]],
      representativeemail:['',[Validators.maxLength(100),Validators.email]],
      state:1,
      startCourse: ['',[Validators.required, Validators.pattern(/^\d{4}\-(0[1-9]|1[012])\-(0[1-9]|[12][0-9]|3[01])$/)]],
      type_course:1,
      duration: [{value:1,disabled:true},Validators.min(1)],
      delinquent:[false],
      freezes:[false],
      payment_days: this.formBuilder.array([]),
      payment_days_new: this.formBuilder.array([])

    });
    this.formComentario = this.formBuilder.group({
      comment:['',[Validators.required]],
    });

    this.formGastos = this.formBuilder.group({
      name:[{value:'Inscripción',disabled:this.desactivaNameGasto},[Validators.required]],
      amount:['',[Validators.required]],
      tipogasto:['1',[Validators.required]],
      comments:['',[Validators.required]],
    });
    this.formGastosList = this.formBuilder.group({
      gastos: this.formBuilder.array([])      
    });
    this.formGroupCursos = this.formBuilder.group({
      courses:['',[Validators.required]],
    });
    this.formGroupPagos = this.formBuilder.group({
      payment_days: this.formBuilder.array([])

    });
    this.formNewGroupPagos = this.formBuilder.group({
      courses:['',[Validators.required]],
      duration:['1',[Validators.required]],
      payment_days: this.formBuilder.array([])
    });
    if(this.IdFrom == "editar"){
      this.typeForm = "Editar";
      if(this.filterRut != ''){
        Swal.showLoading();
        this.alumnosService.getstudentbyrut(this.filterRut).subscribe((resp: any) => {
          if(resp.code == 200){
            this.datosEditar = resp.data.student;
            this.datosCursosEditar = resp.data.courses;
            let arrselectd:string[] = [];
            this.datosCursosEditar.forEach(x=>{
              arrselectd.push(x.identity);
            });
            this.formGroupCursos.patchValue({
              'courses':arrselectd
            });
            this.getComentarios(this.datosEditar.identity);
            this.getPagos(this.datosEditar.identity);
            this.getInscripcion(this.datosEditar.identity);
            this.getGastos(this.datosEditar.identity);
            const datePipe = new DatePipe('en-US');
            const fechaFormateada = datePipe.transform(this.datosEditar.interviewdate, 'yyyy-MM-dd');
            const fechaFormateadastartCourse = datePipe.transform(this.datosEditar.startCourse, 'yyyy-MM-dd');
            this.formGroup.controls['idcard'].disable();

           this.formGroup.patchValue({
            'name':this.datosEditar.name,
            'lastname':this.datosEditar.lastname,
            'promissorynotenumber':this.datosEditar.promissorynotenumber,
            'age':this.datosEditar.age,
            'idcard':this.datosEditar.idcard,
            'passport':this.datosEditar.passport,
            'gender':this.datosEditar.gender,
            'executive':this.datosEditar.executive,
            'phone1':this.datosEditar.phone1,
            'phone2':this.datosEditar.phone2,
            'email':this.datosEditar.email,
            'interviewdate':fechaFormateada,
            'address':this.datosEditar.address,
            'city':this.datosEditar.city,
            'locality':this.datosEditar.locality,
            'representativefullname':this.datosEditar.representativefullname,
            'representativephone':this.datosEditar.representativephone,
            'representativeemail':this.datosEditar.representativeemail,
            'type_course': this.datosEditar.type_course,
            'startCourse': fechaFormateadastartCourse,
            'duration': this.datosEditar.duration,
            'delinquent':this.datosEditar.delinquent,
            'freezes':this.datosEditar.freezes,
           });
           this.mesesMinimosPago = this.datosEditar.duration;
           this.changeTipoCurso(this.datosEditar.type_course);
          }
          this.cdr.detectChanges();
          Swal.close();
        },error =>{
          console.log("error",error);
          Swal.close();
        });
  
      }
    }else{
      this.typeForm = "Agregar";
    }
    this.getListEjecutivos();
    this.getListCurso();
  }

  agregarMesesPago(){
    
    // this.alumnosService.addPaymentDays(fechaspago,this.datosEditar.identity).subscribe((resp: any) => {
    //   console.log(resp);
    // });
    
  }
  changeTipoGasto(e:any){
    this.desactivaNameGasto = true;
    const durationcontrol = this.formGastos.get('name');
    durationcontrol.disable();
    switch (e) {
      case '1':
        this.formGastos.patchValue({
          'name':'Inscripción'
        });
        break;
      case '2':
        this.formGastos.patchValue({
          'name':'Book Fotográfico Milano'
        });
      break;
      case '3':
        this.formGastos.patchValue({
          'name':'Certamen'
        });
      break;
      case '4':
        durationcontrol.enable();

        this.formGastos.patchValue({
          'name':''
        });
        this.desactivaNameGasto = false;
      break;
    
      default:
        break;
    }
  }
  agregarcomentario(){
    this.isLoadingComentarios = true;
    let datePipe = new DatePipe('en-US');
    let comentario = {
      "student": this.datosEditar.identity,
      "comment": this.formComentario.controls.comment.value,
      "date": datePipe.transform(new Date(), 'yyyy-MM-dd')
    };
    this.alumnosService.addcommentsbystudent(comentario).subscribe((resp: any) => {
      this.isLoadingComentarios = false;
      if(resp.code == 200){
        Swal.fire({
          title: '',
          text: 'Comentario insertado correctamente.',
          icon: 'success',
          confirmButtonText: 'OK'
        }).then((result) => {
          this.getComentarios(this.datosEditar.identity);
        });
      }else{
        Swal.fire(
          '',
          `No se pudo agregar comentario, intente nuevamente.`,
          'error'
          );
      }
    }, (error) => {
      Swal.close();
      Swal.fire(
        '',
        `No se pudo agregar comentario, intente nuevamente.`,
        'error'
        );
    });
  }

  
  agregargasto(){
    this.isLoadingGastos = true;
    let datePipe = new DatePipe('en-US');
    let gasto = {
      "student": this.datosEditar.identity,
      "comments": this.formGastos.controls.comments.value,
      "name": this.formGastos.controls.name.value,
      "amount": this.formGastos.controls.amount.value,
      "paymentday": datePipe.transform(new Date(), 'yyyy-MM-dd')
    };
    this.alumnosService.agregargastoxalumno(gasto).subscribe((resp: any) => {
      this.isLoadingGastos = false;
      if(resp.code == 200){
        this.formGastos.reset();
        Swal.fire({
          title: '',
          text: 'Gasto insertado correctamente.',
          icon: 'success',
          confirmButtonText: 'OK'
        }).then((result) => {
          this.getGastos(this.datosEditar.identity);
        });
      }else{
        Swal.fire(
          '',
          `No se pudo agregar gasto, intente nuevamente.`,
          'error'
          );
      }
    }, (error) => {
      Swal.close();
      Swal.fire(
        '',
        `No se pudo agregar gasto, intente nuevamente.`,
        'error'
        );
    });
  }

  saveGasto(i:number){
    Swal.showLoading();
    let obj = this.formGastosList.getRawValue().gastos[i];    
    this.alumnosService.editGasto(obj,obj.identity).subscribe((resp: any) => {
      Swal.close(); 
      if(resp.code == 200){
        Swal.fire({
          title: '',
          text: 'Gasto actualizado con éxito.',
          icon: 'success',
        });
      }else{
        Swal.fire(
          '',
          `No se pudo guardar gasto, intente nuevamente.`,
          'error'
          );
      }
    }, (error) => {
      Swal.close();
      Swal.fire(
        '',
        `No se pudo guardar gasto, intente nuevamente.`,
        'error'
        );
    });
  }

  getComentarios(id:string){
    this.isLoadingComentarios=true;
    this.comentariosList = [];

    this.cdr.detectChanges();
    this.alumnosService.getcommentsbystudent(id).subscribe((resp:any)=>{
      this.isLoadingComentarios = false;
      if(resp.code == 200){
        this.comentariosList = resp.data;
      }else{
        this.comentariosList = [];
      }
      this.cdr.detectChanges();

    });
  }

  getInscripcion(id:string){
    this.isLoadingInscripcion=true;
    this.inscripcionList = [];
    this.cdr.detectChanges();
    this.alumnosService.getinscripcionxalumno(id).subscribe((resp:any)=>{
      this.isLoadingInscripcion = false;
      if(resp.code == 200){
        this.inscripcionList = resp.data;
      }else{
        this.inscripcionList = [];
      }
      this.cdr.detectChanges();

    });
  }

  getGastos(id:string){
    this.isLoadingGastos=true;
    this.gastosList = [];
    this.cdr.detectChanges();
    this.alumnosService.getGastosxalumno(id).subscribe((resp:any)=>{
      this.isLoadingGastos = false;
      if(resp.code == 200){
        
        this.gastosList = resp.data;
        let fechasgastos = this.formGastosList.get('gastos') as FormArray;
        fechasgastos.clear();
        let datePipe = new DatePipe('en-US');

        this.gastosList.forEach(x => {
          let fechaFormateada = datePipe.transform(new Date(x.paymentday), 'yyyy-MM-dd');

          let nuevoObjeto = new FormGroup({
            'identity':  new FormControl(x.identity),
            'amount': new FormControl(x.amount),
            'comments':new FormControl(x.comments),
            'name': new FormControl(x.name),
            'paymentday': new FormControl(fechaFormateada),
          });
          fechasgastos.push(nuevoObjeto);
        });
      }else{
        this.gastosList = [];
      }
      this.cdr.detectChanges();

    });
  }


  eliminarGasto(identity: any) {
    Swal.fire({
      title: 'Eliminar gasto',
      text: '¿Realmente desea eliminar este gasto?.',
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: 'Sí',
      denyButtonText: 'No',
    }).then((result) => {
      if (result.isConfirmed) {
        this.isLoading = true;
        Swal.showLoading();
        this.alumnosService.deleteGasto(identity).subscribe((resp: any) => {
          Swal.close();
          if (resp.code == 200) {
            Swal.fire({
              title: '',
              text: 'Gasto eliminado correctamente.',
              icon: 'success',
              confirmButtonText: 'OK',
            }).then((result2) => {
             this.getGastos(this.datosEditar.identity);
            });
          } else {
            Swal.fire(
              '',
              'No se pudo eliminar gasto, favor comuníquese con el administrador.',
              'error'
            );
          }
          this.cdr.detectChanges();
        });
        this.cdr.detectChanges();
      }
    });
  }

  getPagos(identity:string){
    this.isLoadingPagos = true;

    this.pagosService.getPagosAlumno(identity).subscribe((pagos: any) => {
      this.isLoadingPagos = false;
      let datePipe = new DatePipe('en-US');
      if(pagos.code == 200){
        let fechaspago = this.formGroup.get('payment_days') as FormArray;
        fechaspago.clear();

        pagos.data.forEach(pago => {
          let fechaFormateada = datePipe.transform(new Date(pago.agreedpaymentday), 'yyyy-MM-dd');
          let nuevoObjeto = new FormGroup({
            'identity':  new FormControl(pago.identity),
            'agreedpaymentday': new FormControl(fechaFormateada),
            'comments':new FormControl(pago.comments),
            'coursename': new FormControl(pago.coursename),
            'paymentcompleted': new FormControl(pago.paymentcompleted),
            'paymentcredit': new FormControl( pago.paymentcredit),
            'monthnumber': new FormControl( pago.monthnumber)
          });
          fechaspago.push(nuevoObjeto);
        });
      }
      this.cdr.detectChanges();
    });
  }

  getListEjecutivos(){
    this.ejecutivosService.getexecutives().subscribe((resp: any) => {
      this.isLoading = false;
      this.ejecutivos = resp.data;
      this.cdr.detectChanges();
    });
  }

  changeMesDuracion(){
    this.updatePaymentDays();
  }

  changeStartCourse(){
    this.updatePaymentDays();
  }

  validarRut(rut:string){
    if(rut == ''){
      Swal.fire(
        '',
        'Ingrese un Rut o pasaporte válido',
        'error'
      );
      return;
    }
    if(!this.formGroup.controls.ispassport.value){
      let formatedRut = this.formatRut(rut);
      if(formatedRut == ''){
        Swal.fire(
          '',
          'Ingrese un Rut o pasaporte válido',
          'error'
        );
      }else{
        Swal.showLoading();
        this.alumnosService.checkRut(formatedRut).subscribe((resp: any) => {
          Swal.close();
          if(resp.code == 200){
            //this.router.navigate(['/alumnos/admin/editar'], { queryParams: { formatedRut } });
            Swal.fire(
              '',
              'Rut o pasaporte ya existe en el sistema, debe ingresar uno nuevo',
              'error'
            );  
            this.formGroup.patchValue({
              'idcard':''
            });
          }
        },error =>{
          console.log("error",error);
          Swal.close();
        });
  
      }
      this.formGroup.patchValue({
        'idcard':formatedRut
      });
    }else{
      this.formGroup.patchValue({
        'idcard':rut.toLowerCase(),
        'passport':rut.toLowerCase()
      });
      Swal.showLoading();
      this.alumnosService.checkRut(rut.toLowerCase()).subscribe((resp: any) => {
        Swal.close();
        if(resp.code == 200){
          //this.router.navigate(['/alumnos/admin/editar'], { queryParams: { formatedRut } });
          Swal.fire(
            '',
            'Rut o pasaporte ya existe en el sistema, debe ingresar uno nuevo',
            'error'
          );  
          this.formGroup.patchValue({
            'idcard':'',
            'passport':''
          });
        }
      },error =>{
        console.log("error",error);
        Swal.close();
      });
    }
    
  }

  validarEmail(email:string){
    if(this.typeForm == 'Agregar'){
      if(email == ''){
        Swal.fire(
          '',
          'Ingrese un correo válido',
          'error'
        );
        return;
      }
      Swal.showLoading();
      this.alumnosService.checkEmail(email).subscribe((resp: any) => {
        Swal.close();
        if(resp.code == 200){
          Swal.fire(
            '',
            'Email ya existe en el sistema, por favor ingresar otro.',
            'error'
          );  
          this.formGroup.patchValue({
            'email':''
          });
        }else{

        }
      },error =>{
        console.log("error",error);
        Swal.close();
      });
    }

  }

 openModalComentarios(template: TemplateRef<any>) {
  this.modalRefComentarios = this.modalService.show(template);
}

openModalGastos(template: TemplateRef<any>) {
  this.modalRefGastos = this.modalService.show(template,{ class: 'modal-lg' });
}
  formatRut(rut:string){
    if(rut){
      let rutsinptoguion = rut.replace(/[.-]/g, '');
      rutsinptoguion = rutsinptoguion.toLocaleLowerCase();
      if(this.validaRut(rutsinptoguion)){
        let numeroFormateado = rutsinptoguion.replace(/^(\d{1,2})(\d{3})(\d{3})(\d{1})$/, '$1.$2.$3-$4');
        return numeroFormateado;
      }else{
        return '';
      }
    }
    return '';
  }

  validaRut(rutLimpio:string){
    if (rutLimpio.length < 7) {
      return false;
    }
     const cuerpo = rutLimpio.slice(0, -1);
     const digitoVerificador = rutLimpio.slice(-1).toUpperCase(); 
     const suma = cuerpo
       .split('')
       .reverse()
       .reduce((acc, digit, index) => acc + parseInt(digit) * (index % 6 + 2), 0);
 
     const digitoEsperado = (11 - (suma % 11)) % 11;
     const digitoEsperadoStr = digitoEsperado === 10 ? 'K' : digitoEsperado.toString();
     return digitoVerificador === digitoEsperadoStr;
  }

  updatePaymentDays(){
    let datePipe = new DatePipe('en-US');
    let mesduracion = this.formGroup.controls.duration.value;
    this.fechainiciocurso = new Date(this.formGroup.controls.startCourse.value);
    if(this.typeForm == "Agregar"){
      let fechaspago = this.formGroup.get('payment_days') as FormArray;
      fechaspago.clear();
      if(Array.isArray(this.cursosFiltrados)){
        this.cursosFiltrados.forEach(curso => {
          for (let index = 0; index < mesduracion; index++) {
            this.fechainiciocurso.setMonth(this.fechainiciocurso.getMonth() + 1);
            let fechaFormateada = datePipe.transform(new Date(this.fechainiciocurso), 'yyyy-MM-dd');
            let nuevoObjeto = new FormGroup({
              'coursename':  new FormControl(curso.name),
              'course': new FormControl(curso.identity),
              'date': new FormControl( fechaFormateada,Validators.required),
              'month': new FormControl(index +1,Validators.required),
            });
            fechaspago.push(nuevoObjeto);
          } 
          
        });
      }
    }else{
      if(mesduracion >= this.mesesMinimosPago){
        let fechaspago = this.formGroup.get('payment_days_new') as FormArray;
        fechaspago.clear();
        let cursosselected = this.formGroupCursos.getRawValue().courses;
        if(this.cursosFiltrados.length == 0){
          this.cursosFiltrados = this.cursos$.filter(curso => cursosselected.includes(curso.identity));

        }
        if(Array.isArray(this.cursosFiltrados)){
          this.cursosFiltrados.forEach(curso => {
            for (let index = 0; index < mesduracion; index++) {
              if(index >= this.mesesMinimosPago){
                this.fechainiciocurso.setMonth(this.fechainiciocurso.getMonth() + 1);
                let fechaFormateada = datePipe.transform(new Date(this.fechainiciocurso), 'yyyy-MM-dd');
                let nuevoObjeto = new FormGroup({
                  'coursename':  new FormControl(curso.name),
                  'course': new FormControl(curso.identity),
                  'date': new FormControl( fechaFormateada,Validators.required),
                  'month': new FormControl(index +1,Validators.required)
                });
                fechaspago.push(nuevoObjeto);
              }
            }
          });
          this.cdr.detectChanges();
        }
      }else{
        this.formGroup.patchValue({
          'duration': this.mesesMinimosPago
         });
        Swal.fire(
          '',
          'Meses no puede ser menor al actual.',
          'error'
          );
      }
    }
    this.cdr.detectChanges();
  }

  getPaymentDays():FormArray{
    return this.formGroup.get('payment_days') as FormArray;
  }

  getGastosForm():FormArray{
    return this.formGastosList.get('gastos') as FormArray;
  }

  getPaymentDaysNew():FormArray{
    return this.formGroup.get('payment_days_new') as FormArray;
  }

  get form(){
    return this.formGroup.controls;
  }

  selectcurso(){
    let cursosselected = this.formGroupCursos.getRawValue().courses;
    cursosselected = Array.isArray(cursosselected)? cursosselected:[cursosselected];
    this.cursosFiltrados = this.cursos$.filter(curso => cursosselected.includes(curso.identity));
    this.cdr.detectChanges();
    this.updatePaymentDays();
  }

  saveAlumno(){
    this.isLoading = true;
    Swal.showLoading();
    this.cdr.detectChanges();
    if(this.IdFrom == "agregar"){
      this.validarRut(this.formGroup.controls.idcard.value);
      if(this.formGroup.valid){
        if(this.formGroupCursos.valid){
          let cursosselected = this.formGroupCursos.getRawValue().courses;
          let objaddalumno = {
            "student":this.formGroup.getRawValue(),
            "courses":Array.isArray(cursosselected)? cursosselected:[cursosselected]
          };
          this.alumnosService.create(objaddalumno).subscribe((resp: any) => {
            Swal.close();
            if(resp.code == 200){
              Swal.fire({
                title: '',
                text: 'Datos insertados correctamente.',
                icon: 'success',
                confirmButtonText: 'OK'
              }).then((result) => {
                if (result.isConfirmed) {
                  // Redirigir a la página deseada usando el Router de Angular
                  this.router.navigate(['/alumnos/admin/editar'], { queryParams: { formatedRut : objaddalumno.student.idcard } }).then(() =>{
                    window.location.reload();
                  });
                }
              });
            }else{
              Swal.fire(
                '',
                'No se pudo insertar los datos, favor comuníquese con el administrador.',
                'error'
                );
            }
            this.isLoading = false;
            this.cdr.detectChanges();
    
          });
        }else{
          Swal.close();
          Swal.fire(
            '',
            'Debe seleccionar un Curso.',
            'error'
            );
            this.isLoading = false;
            this.cdr.detectChanges();
        }
      }else{
        Swal.close();
        Swal.fire(
          '',
          'Debe completar los campos correctamente.',
          'error'
          );
          this.isLoading = false;
          this.cdr.detectChanges();
      }
    }else{
      if(this.formGroup.valid){
        if(this.formGroupCursos.valid){
          let cursosselected = this.formGroupCursos.getRawValue().courses;
          let objaddalumno = {
            "student":this.formGroup.getRawValue(),
            "courses":Array.isArray(cursosselected)? cursosselected:[cursosselected]
          };
          this.alumnosService.edit(objaddalumno,this.datosEditar.identity).subscribe((resp: any) => {
            Swal.close();
            if(resp.code == 200){
              Swal.fire({
                title: '',
                text: 'Datos modificados correctamente.',
                icon: 'success',
                confirmButtonText: 'OK'
              }).then((result) => {
                // if (result.isConfirmed) {
                //   this.router.navigate(['/alumnos']);
                // }
              });
            }else{
              Swal.fire(
                '',
                'No se pudo insertar los datos, favor comuníquese con el administrador.',
                'error'
                );
            }
            this.isLoading = false;
            this.cdr.detectChanges();
    
          });
        }else{
          Swal.close();
          Swal.fire(
            '',
            'Debe seleccionar un Curso.',
            'error'
            );
            this.isLoading = false;
            this.cdr.detectChanges();
        }
      
      }else{
        Swal.close();
        Swal.fire(
          '',
          'Debe completar los campos correctamente.',
          'error'
          );
          this.isLoading = false;
          this.cdr.detectChanges();
      }
    }
    
  }

  getListCurso(){
    this.isLoadingCursos = true;
    this.cdr.detectChanges();
    this.cursosService.getall().subscribe((resp: any) => {
      const durationcontrol = this.formGroup.get('duration');
      durationcontrol.enable();
      this.isLoadingCursos = false;
      this.isLoading = false;
      this.cursos$ = resp.data;
      this.selectcurso();
      this.cdr.detectChanges();
    });

  }

  changeTipoCurso(id){
    if(id == 3){
      this.multiple_curso = true;
    }else{
      this.multiple_curso = false;
    }
  }
}
