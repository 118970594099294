import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { UsuariosService } from '@app/shared/services/usuarios.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-formulario-usuarios',
  templateUrl: './formulario-usuarios.component.html',
  styleUrls: ['./formulario-usuarios.component.css']
})

export class FormularioUsuariosComponent implements OnInit {
  formGroup: FormGroup;
  isLoading = false;
  datosEditar:any="";
  IdFrom = "";
  typeForm = "Agregar";
  constructor(private formBuilder: FormBuilder,private router:Router,private usuariosService: UsuariosService,private cdr: ChangeDetectorRef,
    private route: ActivatedRoute) { }


  ngOnInit(): void {
    this.datosEditar = this.usuariosService.datosAEditar;
    this.route.params.subscribe(params => {
      this.IdFrom = params['id'];
    });
    this.formGroup = this.formBuilder.group({
      name:['',[Validators.required,Validators.maxLength(255)]],
      email:['',[Validators.required,Validators.maxLength(255),Validators.email]],
      role:[''],
      password:['']
    });
    if(this.IdFrom == "editar"){
      this.typeForm = "Editar";
      if(this.datosEditar != undefined){
        
       this.formGroup.patchValue({
        'name':this.datosEditar.name,
        'email':this.datosEditar.email,
        'role':this.datosEditar.role,
        'password':this.datosEditar.password,
       });
      }
    }else{
      this.typeForm = "Agregar";
    }
  }

  save(){
    this.isLoading = true;
    this.cdr.detectChanges();
    if(this.IdFrom == "agregar"){
      this.agregar();
    }else{
      this.editar();
    }
  }

  agregar(){
    if(this.formGroup.valid){
      this.usuariosService.create(this.formGroup.getRawValue()).subscribe((resp: any) => {
        if(resp.code == 200){
          Swal.fire({
            title: '',
            text: 'Datos insertados correctamente.',
            icon: 'success',
            confirmButtonText: 'OK'
          }).then((result) => {
            if (result.isConfirmed) {
              // Redirigir a la página deseada usando el Router de Angular
              this.router.navigate(['/usuarios']);
            }
          });
        }else{
          Swal.fire(
            '',
            'No se pudo insertar los datos, favor comuníquese con el administrador.',
            'error'
            );
        }
        this.isLoading = false;
        this.cdr.detectChanges();

      });
    }else{
      Swal.fire(
        '',
        'Debe completar los campos correctamente.',
        'error'
        );
        this.isLoading = false;
        this.cdr.detectChanges();
    }
  }

  editar(){
    if(this.formGroup.valid){
      this.usuariosService.edit(this.formGroup.getRawValue(),this.datosEditar.id).subscribe((resp: any) => {
        if(resp.code == 200){
          Swal.fire({
            title: '',
            text: 'Datos modificados correctamente.',
            icon: 'success',
            confirmButtonText: 'OK'
          }).then((result) => {
            if (result.isConfirmed) {
              this.router.navigate(['/usuarios']);
            }
          });
        }else{
          Swal.fire(
            '',
            'No se pudo insertar los datos, favor comuníquese con el administrador.',
            'error'
            );
        }
        this.isLoading = false;
        this.cdr.detectChanges();

      });
    }else{
      Swal.fire(
        '',
        'Debe completar los campos correctamente.',
        'error'
        );
        this.isLoading = false;
        this.cdr.detectChanges();
    }
  }
}