<div class="row">
    <div class="col-12 mb-4 mt-3">
        <h1>Milano Models: Sistema de Administración</h1>
        <hr>
        <p>Bienvenido al corazón operativo de Milano Models.
        <br>Hemos diseñado una plataforma única que se adapta a nuestras necesidades dinámicas, permitiendo que cada talento brille con luz propia.</p>
    </div>
    <div class="col-lg-6">
         <div class="row">
            
            <div class="col-lg-6">
                <div class="card text-center bg-white mb-3">
                    <div class="card-header"><h3>Alumnos</h3></div>
                    <div class="card-body">
                      <h1 class="card-title"> <i class="feather icon-user"></i> 
                        {{countStudent}}
                    </h1>
                    <div class="spinner-border spinner-border-sm" role="status" *ngIf="!countStudent">
                        <span class="sr-only">Loading...</span>
                    </div>
                      <a href="/alumnos" class="btn btn-primary btn-sm w-100">Ver todos</a>
                    </div>
                </div>
            </div>

            <div class="col-lg-6">
                <div class="card text-center bg-white mb-3">
                    <div class="card-header"><h3>Cursos</h3></div>
                    <div class="card-body">
                      <h1 class="card-title"><i class="feather icon-briefcase"></i> {{countCourse}}</h1>
                      <div class="spinner-border spinner-border-sm" role="status" *ngIf="!countCourse">
                        <span class="sr-only">Loading...</span>
                    </div>
                      <a href="/cursos" class="btn btn-primary btn-sm w-100">Ver todos</a>
                    </div>
                </div>
            </div>

            <div class="col-lg-6">
                <div class="card text-center bg-white mb-3">
                    <div class="card-header"><h3>Ejecutivos</h3></div>
                    <div class="card-body">
                        <h1 class="card-title"><i class="feather icon-user"></i> {{countExecutive}}</h1>
                        <div class="spinner-border spinner-border-sm" role="status" *ngIf="!countExecutive">
                            <span class="sr-only">Loading...</span>
                        </div>
                      <a href="/ejecutivos" class="btn btn-primary btn-sm w-100">Ver todos</a>
                    </div>
                </div>
            </div>

            <div class="col-lg-6">
                <div class="card text-center bg-white mb-3">
                    <div class="card-header"><h3>Profesores</h3></div>
                    <div class="card-body">
                      <h1 class="card-title text-center"><i class="feather icon-user"></i> {{ countTeacher }}</h1>
                      <div class="spinner-border spinner-border-sm" role="status" *ngIf="!countTeacher">
                        <span class="sr-only">Loading...</span>
                    </div>
                      <a href="/profesores" class="btn btn-primary btn-sm w-100">Ver todos</a>
                    </div>
                </div>
            </div>

            <div class="col-12">
                <div class="card text-center">
                    <div class="card-header">
                        Alumnos Congelados
                    </div>
                    <div class="card-body">
                      <h5 class="card-title">{{countStudentFreeze}} Alumno(s)</h5>
                      <div class="spinner-border spinner-border-sm" role="status" *ngIf="!countStudentFreeze">
                        <span class="sr-only">Loading...</span>
                    </div>

                    </div>
                    <a href="/alumnos/congelados" class="btn btn-primary btn-sm w-100">Ver todos</a>

                  </div>
            </div>
         </div>
    </div>
    <div class="col-lg-6">
        <div class="card-body bg-white mb-3">
            <h4>Alumnos Morosos </h4>
            <div class="mt-3">
                <table class="table table-sm">
                    <tbody>
                        <tr *ngIf="isLoadingMorosos">
                            <td colspan="5">
                                <div class="spinner-border spinner-border-sm" role="status">
                                    <span class="sr-only">Loading...</span>
                                </div>
                            </td>
                           
                        </tr>
                        <tr *ngFor="let item of alumnosmorosos | searchFilter:searchText | paginate: { itemsPerPage: 10, currentPage: p }">
                            <td>
                                <i class="feather icon-user"></i>
                                <span class="ms-1">{{ item.idcard }}</span>
                            </td>
                            <td>{{ item.name }} {{ item.lastname }}</td>
                            <td>
                                <div class="d-flex align-items-center my-2">
                                    <div class="ms-3">
                                        <a class="btn btn-success btn-sm" target="_blank" href="https://api.whatsapp.com/send?l=en&phone=+56{{item.phone1}}&text=Hola soy ejecutiva de Milano Models">WhatsApp <i class="feather icon-send"></i></a>
                                        <a class="btn btn-secondary btn-sm ms-3" href="mailto:{{item.email}}">Email <i class="feather icon-mail"></i></a>
                                    </div>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</div>
