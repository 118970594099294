<nav class="navbar navbar-light bg-light mb-3">
    <div class="container-fluid">
        <a class="btn btn-warning" [routerLink]="['/cursos']">
            <i class="feather icon-arrow-left"></i> Volver a la lista de cursos
        </a>
    </div>
</nav>

<section class="demo-section row">
    <div class="content-box col-12">
        <form class="row g-3"  [formGroup]="formGroup" (validSubmit)="save()">
            <label class="form-label col-12"><h4>Ingresar datos del curso:</h4></label>

            <div class="col-md-4">
                <label class="form-label">Tipo:</label>
                <select class="form-select" formControlName="type">
                    <option value="1">Normal</option>
                    <option value="2">Personalizado</option>
                </select>
            </div>

            <div class="col-md-4">
                <label class="form-label">Sigla</label>
                <input type="text" formControlName="acronym" class="form-control" value="{{current_sigla}}">
            </div>
            <div class="col-md-4">
                <label class="form-label">Nombre del curso</label>
                <input type="text" formControlName="name" class="form-control">
            </div>
            
            <label class="mt-5 col-12 form-label"><h4>Asignar Horarios al curso:</h4></label>
            <div id="horarios-container col-12">
                <div class="horario row">
                   
                   <div class="col-md-2">
                       <label class="form-label">Seleccionar Piso</label>
                       <select class="form-select" formControlName="floor" (change)="generateAcronym()">
                           <option value="1" selected>Piso 9</option>
                           <option value="2">Piso 12</option>
                       </select>
                   </div>
                   <div class="col-md-6">
                       <label class="form-label w-100">Días de la semana</label>
                       <div class="form-check">
                           <input type="checkbox" value="1" formControlName="monday" class="form-check-input" (click)="generateAcronym()">
                           <label class="form-check-label" for="lunes">Lunes</label>
                       </div>
                       <div class="form-check">
                           <input type="checkbox" value="2" formControlName="tuesday" class="form-check-input" (click)="generateAcronym()">
                           <label class="form-check-label" for="martes">Martes</label>
                       </div>
                       <div class="form-check">
                           <input type="checkbox" value="3" formControlName="wednesday" class="form-check-input" (click)="generateAcronym()" >
                           <label class="form-check-label" for="miercoles">Miércoles</label>
                       </div>
                       <div class="form-check">
                           <input type="checkbox" value="4" formControlName="thursday" class="form-check-input" (click)="generateAcronym()">
                           <label class="form-check-label" for="jueves">Jueves</label>
                       </div>
                       <div class="form-check">
                           <input type="checkbox" value="5" formControlName="friday" class="form-check-input" (click)="generateAcronym()">
                           <label class="form-check-label" for="viernes">Viernes</label>
                       </div>
                       <div class="form-check">
                           <input type="checkbox" value="6" formControlName="saturday" class="form-check-input" (click)="generateAcronym()">
                           <label class="form-check-label" for="sabado">Sábado</label>
                       </div>
                       <div class="form-check">
                           <input type="checkbox" value="7" formControlName="sunday" class="form-check-input" (click)="generateAcronym()">
                           <label class="form-check-label" for="domingo">Domingo</label>
                       </div>
                   </div>
                   <div class="col-md-2">
                       <label class="form-label">Hora Inicio</label>
                       <input type="time" class="form-control" placeholder="5:30" formControlName="starttime" (change)="generateAcronym()">
                   </div>
                   <div class="col-md-2">
                       <label class="form-label">Hora Termino</label>
                       <input type="time" class="form-control" placeholder="6:30" formControlName="endtime" (change)="generateAcronym()">
                   </div>
                   <hr>
               </div>
            </div>
            <div class="col-12 mt-3">
                <button type="submit" class="btn btn-success" [disabled]="isLoading"><i class="feather icon-user-plus"></i>
                    {{typeForm}} Curso 
                   <div *ngIf="isLoading">
                       <div class="spinner-border spinner-border-sm" role="status">
                           <span class="sr-only">Loading...</span>
                       </div>
                   </div>
               </button>
            </div>
        </form>
    </div>
</section>