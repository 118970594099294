import { DatePipe } from '@angular/common';
import { ChangeDetectorRef,TemplateRef, Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AlumnosService } from '@app/shared/services/alumnos.service';
import { CursosService } from '@app/shared/services/cursos.service';
import { PagosService } from '@app/shared/services/pagos.service';
import Alumno from '@app/shared/types/alumno.interface';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-formulario-pagos',
  templateUrl: './formulario-pagos.component.html',
  styleUrls: ['./formulario-pagos.component.css']
})
export class FormularioPagosComponent implements OnChanges {
  @Input() formGroup: FormGroup;
  @Input() IdStudent:string;
  @Input() RutStudent:string;
  @Input() inscripcionList: any[];
  
  formGroupInscripcion:FormGroup;
  constructor(private formBuilder: FormBuilder,private alumnosService: AlumnosService,private pagosService: PagosService,private cdr: ChangeDetectorRef,
    private router: Router,private cursosService: CursosService) { }

  ngOnChanges(changes: SimpleChanges) {
    this.formGroupInscripcion = this.formBuilder.group({
      inscripciones: this.formBuilder.array([]),
    });
    let inscripcionesForm = this.formGroupInscripcion.get('inscripciones') as FormArray;
    if(this.inscripcionList != null){
      inscripcionesForm.clear();
      let datePipe = new DatePipe('en-US');
      if(this.inscripcionList.length > 0){
        this.inscripcionList.forEach(x => {
          let fechaFormateada = datePipe.transform(new Date(x.paymentday), 'yyyy-MM-dd');
          let nuevoObjeto = new FormGroup({
            'identity':  new FormControl(x.identity),
            'paymentday': new FormControl(fechaFormateada),   
            'amount':  new FormControl(x.amount),
            'comments':  new FormControl(x.comments),
            'name':  new FormControl(x.name)
          });
          inscripcionesForm.push(nuevoObjeto);
        });
      }else{
        let nuevoObjeto = new FormGroup({
          'paymentday': new FormControl('',[Validators.required]),   
          'amount':  new FormControl('0',[Validators.required]),
          'comments':  new FormControl('',[Validators.required]),
          'name':  new FormControl('Inscripción',[Validators.required]),
          'student': new FormControl(this.IdStudent)
        });
        inscripcionesForm.push(nuevoObjeto);

      }
    }
   
  }
  changePagoCompletado(i:number){
  }

  verificarAnioMayor1990(fecha: string): boolean {
    if(fecha.trim() == ""){
      return false;
    }
    const primerosCuatroDigitos = parseInt(fecha.substring(0, 4));
    return primerosCuatroDigitos > 1990;
  }

  savePago(i:number){
    Swal.showLoading();
    let obj = this.formGroup.getRawValue().payment_days[i];
    if(obj.paymentcredit == null){
      Swal.fire(
        '',
        `Debe ingresar un valor para guardar.`,
        'error'
        );
    }else{
      if(this.verificarAnioMayor1990(obj.agreedpaymentday)){
        this.pagosService.editPago(obj).subscribe((resp: any) => {
          Swal.close(); 
          if(resp.code == 200){
            Swal.fire({
              title: '',
              text: 'Pago actualizado con éxito.',
              icon: 'success',
            });
          }else{
            Swal.fire(
              '',
              `No se pudo guardar pago ${obj.agreedpaymentday} ${obj.coursename}, intente nuevamente.`,
              'error'
              );
          }
        }, (error) => {
          Swal.close();
          Swal.fire(
            '',
            `No se pudo guardar pago, intente nuevamente.`,
            'error'
            );
        });
      }else{
        Swal.close();
        Swal.fire(
          '',
          `La fecha debe ser mayor a 1990.`,
          'error'
          );
      }
    }
  }

  savePagoInscripcion(i:number){
    Swal.showLoading();
      let obj = this.formGroupInscripcion.getRawValue().inscripciones[i];
      if(obj.paymentday == null){
        Swal.fire(
          '',
          `Debe ingresar un valor para guardar.`,
          'error'
          );
      }else{
        if(this.verificarAnioMayor1990(obj.paymentday)){
          if(obj.identity == null){
            this.alumnosService.agregargastoxalumno(obj).subscribe((resp: any) => {
              if(resp.code == 200){
                Swal.fire({
                  title: '',
                  text: 'Inscripción agregada correctamente.',
                  icon: 'success',
                  confirmButtonText: 'OK'
                }).then((result) => {
                  //this.getInscripcionList(this.IdStudent);
                  this.router.navigate(['/alumnos/admin/editar'], { queryParams: { formatedRut : this.RutStudent } }).then(() =>{
                    window.location.reload();
                  });
                });
              }else{
                Swal.fire(
                  '',
                  `No se pudo agregar inscripción, intente nuevamente.`,
                  'error'
                  );
              }
            }, (error) => {
              Swal.close();
              Swal.fire(
                '',
                `No se pudo agregar inscripción, intente nuevamente.`,
                'error'
                );
            });
          }else{
            this.alumnosService.editGasto(obj,obj.identity).subscribe((resp: any) => {
              Swal.close(); 
              if(resp.code == 200){
                Swal.fire({
                  title: '',
                  text: 'Inscripción actualizada con éxito.',
                  icon: 'success',
                });
              }else{
                Swal.fire(
                  '',
                  `No se pudo actualizar inscripción, intente nuevamente.`,
                  'error'
                  );
              }
            }, (error) => {
              Swal.close();
              Swal.fire(
                '',
                `No se pudo actualizar inscripción, intente nuevamente.`,
                'error'
                );
            });
          }
        }else{
          Swal.close();
          Swal.fire(
            '',
            `La fecha debe ser mayor a 1990.`,
            'error'
            );
        }
      }
  }

  getPaymentDays():FormArray{
    return this.formGroup.get('payment_days') as FormArray;
  }

  getInscripciones():FormArray{
    return this.formGroupInscripcion.get('inscripciones') as FormArray;
  }

  getInscripcionList(id:string){
    this.alumnosService.getinscripcionxalumno(id).subscribe((resp:any)=>{
      if(resp.code == 200){
        this.inscripcionList = resp.data;
      }else{
        this.inscripcionList = [];
      }
      this.cdr.detectChanges();

    });
  }
}
