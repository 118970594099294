import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { EjecutivosService } from '@app/shared/services/ejecutivos.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-agregar-ejecutivos',
  templateUrl: './agregar-ejecutivos.component.html',
  styleUrls: ['./agregar-ejecutivos.component.css']
})
export class AgregarEjecutivosComponent implements OnInit {
  formGroup: FormGroup;
  isLoading = false;
  datosEditar:any="";
  IdFrom = "";
  typeForm = "Agregar";

  constructor(private formBuilder: FormBuilder,private router:Router,private ejecutivoService: EjecutivosService,private cdr: ChangeDetectorRef,
    private route: ActivatedRoute) {


     }

  ngOnInit(): void {
    this.datosEditar = this.ejecutivoService.datosAEditar;
    this.route.params.subscribe(params => {
      this.IdFrom = params['id'];
    });
    this.formGroup = this.formBuilder.group({
      name:['',[Validators.required,Validators.maxLength(500)]],
      phone:['',[Validators.required,Validators.maxLength(50)]],
      email:['',[Validators.required,Validators.maxLength(100),Validators.email]],
      type:['1'],
      profile:['1']
    });
    if(this.IdFrom == "editar"){
      this.typeForm = "Editar";
      if(this.datosEditar != undefined){
        
       this.formGroup.patchValue({
        'name':this.datosEditar.name,
        'phone':this.datosEditar.phone,
        'email':this.datosEditar.email,
        'type':this.datosEditar.type,
        'profile':this.datosEditar.profile,
       });
      }
    }else{
      this.typeForm = "Agregar";
    }
  }

  save(){
    this.isLoading = true;
    this.cdr.detectChanges();
    if(this.IdFrom == "agregar"){
      this.agregar();
    }else{
      this.editar();
    }
  }

  agregar(){
    if(this.formGroup.valid){
      this.ejecutivoService.create(this.formGroup.getRawValue()).subscribe((resp: any) => {
        if(resp.code == 200){
          Swal.fire({
            title: '',
            text: 'Datos insertados correctamente.',
            icon: 'success',
            confirmButtonText: 'OK'
          }).then((result) => {
            if (result.isConfirmed) {
              // Redirigir a la página deseada usando el Router de Angular
              this.router.navigate(['/ejecutivos']);
            }
          });
        }else{
          Swal.fire(
            '',
            resp.msg,
            'error'
            );
        }
        this.isLoading = false;
        this.cdr.detectChanges();

      });
    }else{
      Swal.fire(
        '',
        'Debe completar los campos correctamente.',
        'error'
        );
        this.isLoading = false;
        this.cdr.detectChanges();
    }
  }

  editar(){
    if(this.formGroup.valid){
      this.ejecutivoService.edit(this.formGroup.getRawValue(),this.datosEditar.identity).subscribe((resp: any) => {
        if(resp.code == 200){
          Swal.fire({
            title: '',
            text: 'Datos modificados correctamente.',
            icon: 'success',
            confirmButtonText: 'OK'
          }).then((result) => {
            if (result.isConfirmed) {
              this.router.navigate(['/ejecutivos']);
            }
          });
        }else{
          Swal.fire(
            '',
            'No se pudo insertar los datos, favor comuníquese con el administrador.',
            'error'
            );
        }
        this.isLoading = false;
        this.cdr.detectChanges();

      });
    }else{
      Swal.fire(
        '',
        'Debe completar los campos correctamente.',
        'error'
        );
        this.isLoading = false;
        this.cdr.detectChanges();
    }
  }
}
