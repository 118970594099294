import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DashboardService } from '@app/shared/services/dashboard.service';
import Alumno from '@app/shared/types/alumno.interface';

@Component({
    selector: 'app-dashboard',
    templateUrl: './dashboard.component.html',
})
export class DashboardComponent implements OnInit {
    constructor() {}

    ngOnInit(): void {
        
    }

}
