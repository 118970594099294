<nav class="navbar navbar-light bg-light mb-3">
  <div class="container-fluid">
    <a class="btn btn-warning" [routerLink]="['/graduaciones']">
      <i class="feather icon-arrow-left"></i> Volver a la lista de Graduaciones
    </a>
  </div>
</nav>

<section class="demo-section row">
  <div class="content-box col-12">
    <form class="row g-3" [formGroup]="formGroup" (submit)="save()">
      <label class="form-label col-12">Crear Ceremonia de Graduación</label>

      <div class="col-md-4">
        <label class="form-label">Nombre</label>
        <input type="text" class="form-control" formControlName="name" />
      </div>
      <div class="col-md-4">
        <label class="form-label">Seleccionar Curso</label>
        <ng-select
          bindLabel="name"
          [searchable]="true"
          placeholder="Seleccione curso"
          bindValue="id"
          formControlName="course"
        >
          <ng-option *ngFor="let item of cursos$" [value]="item.identity">{{
            item.name
          }}</ng-option>
        </ng-select>
      </div>
      <div class="col-md-4">
        <label class="form-label">Fecha</label>
        <input type="date" class="form-control" formControlName="date" />
      </div>
      <div class="col-md-4">
        <label class="form-label">Cantidad de Asientos</label>
        <input
          type="number"
          class="form-control"
          formControlName="number_seats"
        />
      </div>
      <div class="col-md-4">
        <label class="form-label">Valor Entrada</label>
        <input
          type="text"
          class="form-control"
          placeholder="Ingresar Monto"
          formControlName="ticket_price"
        />
      </div>
      <div class="col-md-12">
        <label class="form-label">Descripción / anotación</label>
        <textarea class="form-control" formControlName="description"></textarea>
      </div>

      <div class="col-12 mt-3">
        <button type="submit" class="btn btn-success" [disabled]="isLoading">
          <i class="feather icon-user-plus"></i>
          <div *ngIf="isLoading; then cargandobtn; else btntext"></div>
        </button>
      </div>
    </form>
  </div>
</section>

<ng-template #btntext> {{ typeForm }} Graduación </ng-template>
<ng-template #cargandobtn>
  Cargando
  <div class="spinner-border spinner-border-sm" role="status">
    <span class="sr-only">Loading...</span>
  </div>
</ng-template>
