<li class="nav-menu-item-group">
    <div 
        class="nav-menu-item-group-title" 
        [style]="paddingStyle()" 
        #groupTitle
    >
        <ng-container *ngIf="!titleTmp">
            {{title}}
        </ng-container>
        <ng-container *ngIf="titleTmp">
            <ng-template [ngTemplateOutlet]="titleTmp"></ng-template>
        </ng-container>
    </div>
    <ul>
        <ng-content></ng-content>
    </ul>
</li>